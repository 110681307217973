import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";
import { resetpassword, clearMessage } from "../actions/userActions.js";
import { PasswordHide, PasswordShow } from "./icons.component";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Logo from "../assets/images/Logo.svg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const schema = yup.object().shape({
  password: yup
    .string()
    .required("Password is required !!!")
    .min(6, "Password should be 6 characters"),
});

const Resetpassword = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);

  let { error, msg } = userLogin;

  const [passwordShown, setPasswordShown] = useState(false);

  const { register, handleSubmit, errors } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  // console.log("Reset Password UserLogin is ", userLogin);

  useEffect(() => {
    if (msg !== "") {
      toast.success(msg);
      dispatch(clearMessage(userLogin));
      history.push("/");
    } else if (error !== "") {
      toast.error(error);
      dispatch(clearMessage(userLogin));
    }
  }, [error, msg]);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const { resettoken } = useParams();

  const submitHandler = (data) => {
    //console.log("Reset Data in Submit Handler is ", data);

    const password = data.password;
    const resetparams = {
      resettoken,
      password,
    };

    dispatch(resetpassword(resetparams));
  };

  return (
    <>
      <div className="container RegisterWrap d-flex">
        <div className="registerLHS">
          <img src={Logo} alt="Logo" className="logo" />
          <div className="description">
            Copyright &copy; Megdap Innovation Labs |{" "}
            <Link
              to="https://www.megdap.com/page-contacts.html"
              target="_blank">
              Contact Us
            </Link>
          </div>
        </div>
        <div className="registerRHS">
          <div className="registrationForm loginForm">
            <h2 className="loginTitle">Reset Password</h2>

            <form className="form" onSubmit={handleSubmit(submitHandler)}>
              <div className="registerFormWrap">
                <div
                  className={`inputWrap passwordField ${errors.password ? "error" : ""
                    }`}>
                  <input
                    ref={register}
                    type={passwordShown ? "text" : "password"}
                    placeholder="Password"
                    name="password"
                  />
                  <div className="passwordEye">
                    <i onClick={togglePasswordVisiblity}>
                      {passwordShown ? <PasswordShow /> : <PasswordHide />}
                    </i>{" "}
                    {/* <PasswordHide /> */}
                  </div>
                  {errors.password && (
                    <span className="inputErrorMsg">
                      {errors.password?.message}
                    </span>
                  )}
                  {/* {error && <span className="inputErrorMsg">{error}</span>} */}
                </div>

                <div className="formFooter">
                  <button>Submit</button>
                  {/* <div className="loginLink">
                    Not a Member? <Link to="/register">Register Now</Link>
                  </div> */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Resetpassword;
