import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Table from "./Table";
import { format } from "date-fns";
import axios from "axios";
import reqHeader from "../../helper/reqHeader.js";
import { DownloadArrow, DeleteBtn } from "../icons.component.jsx";
import { CustomDialog, Confirm } from "react-st-modal";
import PopupFileDetails from "./PopupFileDetails.jsx";
import jsPDF from "jspdf";
import logo from "../../assets/images/logo.png";
import { inWords } from "../../helper/AmountInWords";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const disableStyle = {
  pointerEvents: "none",
  opacity: "0.4",
};

const enableStyle = {
  pointerEvents: "all",
  opacity: "inherit",
};

const TableList = (props) => {
  const [datalist, setDataList] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [contentLoading, setContentLoading] = useState(false);

  const history = useHistory();

  const {
    src,
    pagination,
    hideTargetDownload,
    hideInvoiceDownload,
    hideReceiptDownload,
    parent,
  } = props;

  const fileDetails = async (props) => {
    // console.log("Popup props tablelist is ", props);

    await CustomDialog(<PopupFileDetails original={props} />, {
      title: "File Details",
      showCloseIcon: true,
    });
  };

  let columns = [];
  if (src === "transcriptionTempFiles") {
    columns = [
      {
        Header: "Id",
        accessor: "_id",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "File Name",
        accessor: "fileName",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Duration",
        accessor: "fileLength",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Delete",
        Cell: ({ row }) => {
          return (
            <button
              style={{ border: "none", background: "none" }}
              onClick={(e) => deleteFile(row.original, "TS", e)}
            >
              <DeleteBtn />
            </button>
          );
        },
      }

    ];
  } else if (src === "transcriptionFileList") {
    columns = [
      {
        Header: "Id",
        accessor: "_id",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              <span title={value}>{value}</span>
            </div>
          );
        },
      },
      {
        Header: "Name",
        accessor: "fileName",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              <span title={value}>{value}</span>
            </div>
          );
        },
      },
      {
        Header: "Duration",
        accessor: "fileDuration",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              <span title={value}>{value}</span>
            </div>
          );
        },
      },
      {
        Header: "Rate",
        accessor: "rate",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              <span title={value}>{value}</span>
            </div>
          );
        },
      },
      {
        Header: "Amount",
        accessor: "netAmount",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              <span title={value}>{value}</span>
            </div>
          );
        },
      },
      {
        Header: "ETA",
        accessor: "ETA",
        sortType: "basic",
        filter: "text",
        Cell: ({ value }) => {
          return (
            <div>
              <span title={format(new Date(value), "dd/MM/yyyy")}>
                {format(new Date(value), "dd/MM/yyyy")}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              <span title={value}>{value}</span>
            </div>
          );
        },
      },
      {
        Header: "Download",
        accessor: "targetFileLocation",
        disableSortBy: true,
        Cell: ({ value, row, cell }) => {
          return (
            <button
              className="downloadBtn"
              value={value}
              onClick={() => downloadFile(row.original, value)}
              style={
                row.original.targetFileLocation === ""
                  ? disableStyle
                  : enableStyle
              }>
              <DownloadArrow />
            </button>
          );
        },
      },
    ];
  } else if (src === "translationFileList") {
    columns = [
      {
        Header: "Id",
        accessor: "_id",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              <span title={value}>{value}</span>
            </div>
          );
        },
      },
      {
        Header: "Name",
        accessor: "fileName",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              <span title={value}>{value}</span>
            </div>
          );
        },
      },
      {
        Header: "Target Language",
        accessor: "tgtLang",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              <span title={value}>{value}</span>
            </div>
          );
        },
      },
      {
        Header: "Word Count",
        accessor: "fileWordCount",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              <span title={value}>{value}</span>
            </div>
          );
        },
      },
      {
        Header: "Rate",
        accessor: "rate",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              <span title={value}>{value}</span>
            </div>
          );
        },
      },
      {
        Header: "Amount",
        accessor: "netAmount",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              <span title={value}>{value}</span>
            </div>
          );
        },
      },
      {
        Header: "ETA",
        accessor: "ETA",
        sortType: "basic",
        filter: "text",
        Cell: ({ value }) => {
          return (
            <div>
              <span title={format(new Date(value), "dd/MM/yyyy")}>
                {format(new Date(value), "dd/MM/yyyy")}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              <span title={value}>{value}</span>
            </div>
          );
        },
      },
      {
        Header: "Download",
        accessor: "targetFileLocation",
        disableSortBy: true,
        Cell: ({ value, row, cell }) => {
          return (
            <button
              className="downloadBtn"
              value={value}
              onClick={() => downloadFile(row.original, value)}
              style={
                row.original.targetFileLocation === ""
                  ? disableStyle
                  : enableStyle
              }>
              <DownloadArrow />
            </button>
          );
        },
      },
    ];
  } else if (src === "subtitlingFileList") {
    columns = [
      {
        Header: "Id",
        accessor: "_id",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              <span title={value}>{value}</span>
            </div>
          );
        },
      },
      {
        Header: "Name",
        accessor: "fileName",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              <span title={value}>{value}</span>
            </div>
          );
        },
      },
      {
        Header: "Target Language",
        accessor: "tgtLang",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              <span title={value}>{value}</span>
            </div>
          );
        },
      },
      {
        Header: "Duration",
        accessor: "fileDuration",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              <span title={value}>{value}</span>
            </div>
          );
        },
      },
      {
        Header: "Rate",
        accessor: "rate",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              <span title={value}>{value}</span>
            </div>
          );
        },
      },
      {
        Header: "Amount",
        accessor: "netAmount",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              <span title={value}>{value}</span>
            </div>
          );
        },
      },
      {
        Header: "ETA",
        accessor: "ETA",
        sortType: "basic",
        filter: "text",
        Cell: ({ value }) => {
          return (
            <div>
              <span title={format(new Date(value), "dd/MM/yyyy")}>
                {format(new Date(value), "dd/MM/yyyy")}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              <span title={value}>{value}</span>
            </div>
          );
        },
      },
      {
        Header: "Download",
        accessor: "targetFileLocation",
        disableSortBy: true,
        Cell: ({ value, row, cell }) => {
          return (
            <button
              className="downloadBtn"
              value={value}
              onClick={() => downloadFile(row.original, value)}
              style={
                row.original.targetFileLocation === ""
                  ? disableStyle
                  : enableStyle
              }>
              <DownloadArrow />
            </button>
          );
        },
      },
    ];
  } else if (src === "translationTempFiles") {
    columns = [
      {
        Header: "Id",
        accessor: "_id",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "File Name",
        accessor: "fileName",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Word Count",
        accessor: "fileWordCount",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Delete",
        Cell: ({ row }) => {
          return (
            <button
              style={{ border: "none", background: "none" }}
              onClick={(e) => deleteFile(row.original, "TL", e)}
            >
              <DeleteBtn />
            </button>
          );
        },
      }
    ];
  } else if (src === "subtitleTempFiles") {
    columns = [
      {
        Header: "Id",
        accessor: "_id",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "File Name",
        accessor: "fileName",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Duration",
        accessor: "fileLength",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Delete",
        Cell: ({ row }) => {
          return (
            <button
              style={{ border: "none", background: "none" }}
              onClick={(e) => deleteFile(row.original, "ST", e)}
            >
              <DeleteBtn />
            </button>
          );
        },
      }
    ];
  } else if (src === "translation") {
    columns = [
      {
        Header: "Id",
        accessor: "_id",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => fileDetails(row.original, column)}>
              <span title="Click for the detailed File List">{value}</span>
            </div>
          );
        },
      },
      {
        Header: "Project Name",
        accessor: "projectName",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div
              style={{ cursor: "pointer" }} //fontWeight: "bold", color: "blue",
              onClick={() => fileDetails(row.original, column)}>
              <span title="Click for the detailed File List">{value}</span>
            </div>
          );
        },
      },
      {
        Header: "Source Language",
        accessor: "srcLang",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => fileDetails(row.original, column)}>
              <span title="Click for the detailed File List">{value}</span>
            </div>
          );
        },
      },
      {
        Header: "Target Language",
        accessor: "tgtLang",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div
              style={{ cursor: "pointer", overflowWrap: "break-word", width: "100px" }}
              onClick={() => fileDetails(row.original, column)}>
              <span title="Click for the detailed File List">{value}</span>
            </div>
          );
        },
      },
      {
        Header: "Word Count",
        accessor: "totalWordCount",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => fileDetails(row.original, column)}>
              <span title="Click for the detailed File List">{value}</span>
            </div>
          );
        },
      },
      {
        Header: "Priority",
        accessor: "priority",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => fileDetails(row.original, column)}>
              <span title="Click for the detailed File List">{value}</span>
            </div>
          );
        },
      },
      {
        Header: "ETA",
        accessor: "ETA",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => fileDetails(row.original, column)}>
              <span title="Click for the detailed File List">
                {format(new Date(value), "dd/MM/yyyy")}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => fileDetails(row.original, column)}>
              <span title="Click for the detailed File List">{value}</span>
            </div>
          );
        },
      },
      {
        Header: "Invoice",
        accessor: "invoice",
        sortType: "basic",
        disableSortBy: true,
        className: "center",
        Cell: ({ value, row, cell }) => (
          <button
            className="invoiceBtn"
            value={value}
            onClick={(e) => downloadInvoice(row.original, value)}>
            Invoice
          </button>
        ),
      },
    ];
  } else if (src === "subtitling") {
    columns = [
      {
        Header: "Id",
        accessor: "_id",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => fileDetails(row.original, column)}>
              <span title="Click for the detailed File List">{value}</span>
            </div>
          );
        },
      },
      {
        Header: "Project Name",
        accessor: "projectName",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div
              style={{ cursor: "pointer" }} //fontWeight: "bold", color: "blue",
              onClick={() => fileDetails(row.original, column)}>
              <span title="Click for the detailed File List">{value}</span>
            </div>
          );
        },
        style: {
          width: "55%",
        },
      },
      {
        Header: "Source Language",
        accessor: "srcLang",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => fileDetails(row.original, column)}>
              <span title="Click for the detailed File List">{value}</span>
            </div>
          );
        },
      },
      {
        Header: "Target Language",
        accessor: "tgtLang",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div
              style={{ cursor: "pointer", overflowWrap: "break-word", width: "100px" }}
              onClick={() => fileDetails(row.original, column)}>
              <span title="Click for the detailed File List">{value}</span>
            </div>
          );
        },
      },
      {
        Header: "Duration",
        accessor: "totalDuration",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => fileDetails(row.original, column)}>
              <span title="Click for the detailed File List">{value}</span>
            </div>
          );
        },
      },
      {
        Header: "Priority",
        accessor: "priority",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => fileDetails(row.original, column)}>
              <span title="Click for the detailed File List">{value}</span>
            </div>
          );
        },
      },
      {
        Header: "ETA",
        accessor: "ETA",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => fileDetails(row.original, column)}>
              <span title="Click for the detailed File List">
                {format(new Date(value), "dd/MM/yyyy")}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => fileDetails(row.original, column)}>
              <span title="Click for the detailed File List">{value}</span>
            </div>
          );
        },
      },
      {
        Header: "Invoice",
        accessor: "invoice",
        sortType: "basic",
        disableSortBy: true,
        className: "center",
        Cell: ({ value, row, cell }) => (
          <button
            className="invoiceBtn"
            value={value}
            onClick={(e) => downloadInvoice(row.original, value)}>
            Invoice
          </button>
        ),
      },
    ];
  } else if (src === "allProjects" || src === "recentlist") {
    columns = [
      {
        Header: "Id",
        accessor: "_id",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => fileDetails(row.original, column)}>
              <span title="Click for the detailed File List">{value}</span>
            </div>
          );
        },
      },
      {
        Header: "Type",
        accessor: "type",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div
              style={{ cursor: "pointer" }} //fontWeight: "bold", color: "blue",
              onClick={() => fileDetails(row.original, column)}>
              <span title="Click for the detailed File List">{value}</span>
            </div>
          );
        },
      },
      {
        Header: "Project Name",
        accessor: "projectName",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div
              style={{ cursor: "pointer" }} //fontWeight: "bold", color: "blue",
              onClick={() => fileDetails(row.original, column)}>
              <span title="Click for the detailed File List">{value}</span>
            </div>
          );
        },
      },
      {
        Header: "Source Lang.",
        accessor: "srcLang",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => fileDetails(row.original, column)}>
              <span title="Click for the detailed File List">{value}</span>
            </div>
          );
        },
      },
      {
        Header: "Target Lang.",
        accessor: "tgtLang",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div
              style={{ cursor: "pointer", overflowWrap: "break-word", width: "100px" }}
              onClick={() => fileDetails(row.original, column)}>
              <span title="Click for the detailed File List">{value}</span>
            </div>
          );
        },
      },
      {
        Header: "Duration / Count",
        accessor: "length",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => fileDetails(row.original, column)}>
              <span title="Click for the detailed File List">{value}</span>
            </div>
          );
        },
      },
      {
        Header: "Priority",
        accessor: "priority",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => fileDetails(row.original, column)}>
              <span title="Click for the detailed File List">{value}</span>
            </div>
          );
        },
      },
      {
        Header: "ETA",
        accessor: "ETA",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => fileDetails(row.original, column)}>
              <span title="Click for the detailed File List">
                {format(new Date(value), "dd/MM/yyyy")}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => fileDetails(row.original, column)}>
              <span title="Click for the detailed File List">{value}</span>
            </div>
          );
        },
      },
      {
        Header: "Invoice",
        accessor: "invoice",
        sortType: "basic",
        disableSortBy: true,
        className: "center",
        Cell: ({ value, row, cell }) => (
          <button
            className="invoiceBtn"
            value={value}
            onClick={(e) => downloadInvoice(row.original, value)}>
            Invoice
          </button>
        ),
      },
    ];
  } else {
    columns = [
      {
        Header: "Id",
        accessor: "_id",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => fileDetails(row.original, column)}>
              <span title="Click for the detailed File List">{value}</span>
            </div>
          );
        },
      },
      {
        Header: "Project Name",
        accessor: "projectName",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div
              style={{ cursor: "pointer" }} //fontWeight: "bold", color: "blue",
              onClick={() => fileDetails(row.original, column)}>
              <span title="Click for the detailed File List">{value}</span>
            </div>
          );
        },
        style: {
          width: "55%",
        },
      },
      {
        Header: "Language",
        accessor: "srcLang",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => fileDetails(row.original, column)}>
              <span title="Click for the detailed File List">{value}</span>
            </div>
          );
        },
      },
      {
        Header: "Duration",
        accessor: "totalDuration",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => fileDetails(row.original, column)}>
              <span title="Click for the detailed File List">{value}</span>
            </div>
          );
        },
      },
      {
        Header: "Priority",
        accessor: "priority",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => fileDetails(row.original, column)}>
              <span title="Click for the detailed File List">{value}</span>
            </div>
          );
        },
      },
      {
        Header: "ETA",
        accessor: "ETA",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => fileDetails(row.original, column)}>
              <span title="Click for the detailed File List">
                {format(new Date(value), "dd/MM/yyyy")}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => fileDetails(row.original, column)}>
              <span title="Click for the detailed File List">{value}</span>
            </div>
          );
        },
      },
      {
        Header: "Invoice",
        accessor: "invoice",
        sortType: "basic",
        disableSortBy: true,
        className: "center",
        Cell: ({ value, row, cell }) => (
          <button
            className="invoiceBtn"
            value={value}
            onClick={(e) => downloadInvoice(row.original, value)}>
            Invoice
          </button>
        ),
      },
    ];
  }

  let title =
    src === "recentlist"
      ? "Recent Projects "
      : src === "transcriptionTempFiles" ||
        src === "translationTempFiles" ||
        src === "subtitleTempFiles" ||
        src === "transcriptionFileList" ||
        src === "translationFileList" ||
        src === "subtitlingFileList"
        ? "Files List "
        : "Projects ";

  useEffect(() => {
    async function getTableData() {
      let url = "";

      const headers = reqHeader(true, true, true);
      const config = { headers };
      setContentLoading(true);
      if (src === "transcription") {
        url = `${process.env.REACT_APP_URL}/transcription/jobhistory`;

        await axios
          .get(url, config)
          .then((res) => {
            setDataList(res.data.data);
            setDataCount(res.data.data.length);
            setContentLoading(false);
          })
          .catch((err) => {
            setDataList([]);
            setDataCount(0);
            setContentLoading(false);
          });
      } else if (src === "translation") {
        url = `${process.env.REACT_APP_URL}/translation/jobhistory`;

        await axios
          .get(url, config)
          .then((res) => {
            setDataList(res.data.data);
            setDataCount(res.data.data.length);
            setContentLoading(false);
          })
          .catch((err) => {
            setDataList([]);
            setDataCount(0);
            setContentLoading(false);
          });
      } else if (src === "subtitling") {
        url = `${process.env.REACT_APP_URL}/subtitling/jobhistory`;

        await axios
          .get(url, config)
          .then((res) => {
            setDataList(res.data.data);
            setDataCount(res.data.data.length);
            setContentLoading(false);
          })
          .catch((err) => {
            setDataList([]);
            setDataCount(0);
            setContentLoading(false);
          });
      } else if (src === "allProjects") {
        url = `${process.env.REACT_APP_URL}/projects/jobhistory`;

        await axios
          .get(url, config)
          .then((res) => {
            setDataList(res.data.data);
            setDataCount(res.data.data.length);
            setContentLoading(false);
          })
          .catch((err) => {
            setDataList([]);
            setDataCount(0);
            setContentLoading(false);
          });
      } else if (src === "recentlist") {
        url = `${process.env.REACT_APP_URL}/projects/recentlists`;

        await axios
          .get(url, config)
          .then((res) => {
            setDataList(res.data.data);
            setDataCount(res.data.data.length);
            setContentLoading(false);
          })
          .catch((err) => {
            setDataList([]);
            setDataCount(0);
            setContentLoading(false);
          });
      } else if (src === "transcriptionFileList") {
        url = `${process.env.REACT_APP_URL}/transcription/jobhistoryfiles/`;

        const { _id, currency } = parent;

        const params = {
          ProjectID: _id,
          currency: currency,
        };

        const config = { headers, params };

        await axios
          .get(url, config)
          .then((res) => {
            setDataList(res.data.data);
            setDataCount(res.data.data.length);
            setContentLoading(false);
          })
          .catch((err) => {
            setDataList([]);
            setDataCount(0);
            setContentLoading(false);
          });
      } else if (src === "translationFileList") {
        url = `${process.env.REACT_APP_URL}/translation/jobhistoryfiles/`;

        const { _id, currency } = parent;

        const params = {
          ProjectID: _id,
          currency: currency,
        };

        const config = { headers, params };

        await axios
          .get(url, config)
          .then((res) => {
            setDataList(res.data.data);
            setDataCount(res.data.data.length);
            setContentLoading(false);
          })
          .catch((err) => {
            setDataList([]);
            setDataCount(0);
            setContentLoading(false);
          });
      } else if (src === "subtitlingFileList") {
        url = `${process.env.REACT_APP_URL}/subtitling/jobhistoryfiles/`;

        const { _id, currency } = parent;

        const params = {
          ProjectID: _id,
          currency: currency,
        };

        const config = { headers, params };

        await axios
          .get(url, config)
          .then((res) => {
            setDataList(res.data.data);
            setDataCount(res.data.data.length);
            setContentLoading(false);
          })
          .catch((err) => {
            setDataList([]);
            setDataCount(0);
            setContentLoading(false);
          });
      } else if (src === "transcriptionTempFiles") {
        url = `${process.env.REACT_APP_URL}/transcription/gettempfiles/`;

        await axios
          .get(url, config)
          .then((res) => {
            setDataList(res.data.data.TempTransFiles);
            setDataCount(res.data.data.TempTransFiles.length);
            setContentLoading(false);
          })
          .catch((err) => {
            setDataList([]);
            setDataCount(0);
            setContentLoading(false);
          });
      } else if (src === "translationTempFiles") {
        url = `${process.env.REACT_APP_URL}/translation/gettempfiles/`;

        await axios
          .get(url, config)
          .then((res) => {
            //console.log("gettempfiles", res.data);
            setDataList(res.data.data.files);
            setDataCount(res.data.data.files.length);
            setContentLoading(false);
          })
          .catch((err) => {
            setDataList([]);
            setDataCount(0);
            setContentLoading(false);
          });
      } else if (src === "subtitleTempFiles") {
        url = `${process.env.REACT_APP_URL}/subtitling/gettempfiles/`;

        await axios
          .get(url, config)
          .then((res) => {
            setDataList(res.data.data.files);
            setDataCount(res.data.data.files.length);
            setContentLoading(false);
          })
          .catch((err) => {
            setDataList([]);
            setDataCount(0);
            setContentLoading(false);
          });
      } else {
        setDataList([]);
        setDataCount(0);
        setContentLoading(false);
      }
    }

    getTableData();
  }, []);

  const downloadFile = (row, val) => {
    const headers = reqHeader(true, true, true);

    let url = `${process.env.REACT_APP_URL}/upload/download/`;

    const params = {
      fpath: row.targetFileLocation,
      type: row.type,
    };

    let fname = row.targetFileLocation.split("/")[7];

    //url=row.TargetFileLocation;
    const config = { headers, params };
    axios
      .get(url, config)
      .then((response) => {


        const { url } = response.data.data;


        const link = document.createElement("a");
        link.download = fname;
        link.target = "_blank";
        link.href = url;
        // link.setAttribute("download", "Samples");
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => { });
  };

  const downloadInvoice = (row, val) => {
    const headers = reqHeader(true, true, true);

    let url = `${process.env.REACT_APP_URL}/invoice/getInvoice/`;

    const params = {
      id: row._id,
      type: row.type,
    };

    // console.log("URL is ", url);

    const config = { headers, params };

    axios
      .get(url, config)
      .then((res) => {
        //console.log("response is ", res.data.data);
        //console.log("response.headers is ", res.headers);

        let data = res.data.data;
        //console.log("data is ", data);

        if (data) {
          let doc = jsPDF("portrait", "px", "a4", "false");
          doc.addImage(logo, "PNG", 50, 50, 100, 25);

          let fileName = "";

          data.forEach((value) => {
            doc.setFont("Helvetica", "Bold");
            doc.setFontSize(10);
            doc.setTextColor("#444444");

            //Company Address Part
            if (value.currency === "USD") {
              doc.text("Megdap Inc", 250, 60);
              doc.text("1415 2nd Ave, #504, Seattle WA 98101, USA", 250, 70);
              doc.text("Email: contact@megdap.com", 250, 80);
              doc.text("Phone: +1 (425) 835 3454", 250, 90);

              doc.setFont("Helvetica", "Bold");
              doc.setFontSize(20);
              doc.setTextColor("#444444");
              doc.text("Invoice", 200, 130);
            } else {
              doc.text("Megdap Innovation Labs Private Limited", 250, 60);
              doc.text("201, Polekar Heights, Bhau Patil Road, Pune", 250, 70);
              doc.text("Email: contact@megdap.com", 250, 80);
              doc.text("Toll Free: 1800-833-0304", 250, 90);
              doc.text("GSTIN: 27AAJCM8621K1Z1", 250, 100);

              doc.setFont("Helvetica", "Bold");
              doc.setFontSize(20);
              doc.setTextColor("#444444");
              doc.text("Tax Invoice", 200, 130);
            }

            doc.setDrawColor("#aaaaaa");
            doc.setLineWidth(1);
            doc.line(50, 140, 400, 140);

            //Filename Part
            fileName = value.invDocNo.replaceAll("/", "");

            //Invoice Details Part
            doc.setFont("Helvetica", "Bold");
            doc.setTextColor("#444444");
            doc.setFontSize(10);
            doc.text("Invoice Number:", 50, 155);

            doc.setFont("Helvetica", "Bold");
            doc.setTextColor("#444444");
            doc.setFontSize(10);
            doc.text(value.invDocNo, 120, 155);

            doc.setFont("Helvetica", "Bold");
            doc.setTextColor("#444444");
            doc.setFontSize(10);
            doc.text("Invoice Date:", 50, 168);

            doc.setFont("Helvetica", "Bold");
            doc.setTextColor("#444444");
            doc.setFontSize(10);
            doc.text(
              format(new Date(value.invDocDate), "dd/MM/yyyy"),
              120,
              168
            );

            //Customer Address Details Part
            doc.setFont("Helvetica", "Bold");
            doc.setTextColor("#444444");
            doc.setFontSize(10);
            doc.text("Buyer", 230, 155);

            doc.setFont("Helvetica", "Bold");
            doc.setTextColor("#444444");
            doc.setFontSize(10);
            doc.text(
              value.billingAddress.firstName +
              " " +
              value.billingAddress.lastName,
              230,
              168
            );

            doc.setFont("Helvetica", "");
            doc.setTextColor("#444444");
            doc.setFontSize(10);
            doc.text(
              value.billingAddress.address1 +
              " " +
              value.billingAddress.address2,
              230,
              181
            );

            doc.setFont("Helvetica", "");
            doc.setTextColor("#444444");
            doc.setFontSize(10);
            doc.text(
              value.billingAddress.city +
              ", " +
              value.billingAddress.state +
              ", " +
              value.billingAddress.country +
              ", " +
              value.billingAddress.zipcode,
              230,
              194
            );

            doc.setFont("Helvetica", "");
            doc.setTextColor("#444444");
            doc.setFontSize(10);
            doc.text("Email: " + value.billingAddress.email, 230, 207);

            doc.setFont("Helvetica", "");
            doc.setTextColor("#444444");
            doc.setFontSize(10);
            doc.text("Mobile: " + value.billingAddress.mobile, 230, 220);

            doc.setDrawColor("#aaaaaa");
            doc.setLineWidth(1);
            doc.line(50, 233, 400, 233);

            //Detail Column Header Part
            if (value.currency === "USD") {
              doc.setFont("Helvetica", "Bold");
              doc.setTextColor("#444444");
              doc.setFontSize(10);
              doc.text("Description", 50, 250);

              doc.setFont("Helvetica", "Bold");
              doc.setTextColor("#444444");
              doc.setFontSize(10);
              doc.text("Unit Cost", 220, 250);

              doc.setFont("Helvetica", "Bold");
              doc.setTextColor("#444444");
              doc.setFontSize(10);
              doc.text(
                row.type === "Translation" ? "Word Count" : "Duration",
                295,
                250
              );

              doc.setFont("Helvetica", "Bold");
              doc.setTextColor("#444444");
              doc.setFontSize(10);
              doc.text("Amount", 400, 250, { align: "right" });
            } else {
              doc.setFont("Helvetica", "Bold");
              doc.setTextColor("#444444");
              doc.setFontSize(10);
              doc.text("Product / Service Name", 50, 250);

              doc.setFont("Helvetica", "Bold");
              doc.setTextColor("#444444");
              doc.setFontSize(10);
              doc.text("HSN / SAC", 180, 250);

              doc.setFont("Helvetica", "Bold");
              doc.setTextColor("#444444");
              doc.setFontSize(10);
              doc.text("Unit Cost", 260, 250);

              doc.setFont("Helvetica", "Bold");
              doc.setTextColor("#444444");
              doc.setFontSize(10);
              doc.text(
                row.type === "Translation" ? "Word Count" : "Duration",
                315,
                250
              );

              doc.setFont("Helvetica", "Bold");
              doc.setTextColor("#444444");
              doc.setFontSize(10);
              doc.text("Amount", 400, 250, { align: "right" });
            }

            doc.setDrawColor("#aaaaaa");
            doc.setLineWidth(1);
            doc.line(50, 260, 400, 260);

            //Service Details Part
            let transcription = value.transcription;
            //console.log("transcription is ", transcription);
            let subtitling = value.subtitling;
            //console.log("subtitling is ", subtitling);
            let translation = value.translation;
            //console.log("translation is ", translation);

            if (transcription.length > 0) {
              transcription.forEach((tsp) => {
                if (value.currency === "USD") {
                  doc.setFont("Helvetica", "");
                  doc.setTextColor("#444444");
                  doc.setFontSize(10);
                  doc.text("Description for transcription", 50, 272);

                  doc.setFont("Helvetica", "");
                  doc.setTextColor("#444444");
                  doc.setFontSize(10);
                  doc.text("$ " + tsp.rate.toString(), 220, 272);

                  doc.setFont("Helvetica", "");
                  doc.setTextColor("#444444");
                  doc.setFontSize(10);
                  doc.text(tsp.totalDuration, 295, 272);

                  doc.setFont("Helvetica", "");
                  doc.setTextColor("#444444");
                  doc.setFontSize(10);
                  doc.text("$ " + tsp.netAmount.toString(), 400, 272, {
                    align: "right",
                  });

                  doc.setDrawColor("#aaaaaa");
                  doc.setLineWidth(1);
                  doc.line(50, 280, 400, 280);
                } else {
                  doc.setFont("Helvetica", "");
                  doc.setTextColor("#444444");
                  doc.setFontSize(10);
                  doc.text("Description for transcription", 50, 272);

                  doc.setFont("Helvetica", "");
                  doc.setTextColor("#444444");
                  doc.setFontSize(10);
                  doc.text("998395", 180, 272);

                  doc.setFont("Helvetica", "");
                  doc.setTextColor("#444444");
                  doc.setFontSize(10);
                  doc.text(tsp.rate.toString(), 260, 272);

                  doc.setFont("Helvetica", "");
                  doc.setTextColor("#444444");
                  doc.setFontSize(10);
                  doc.text(tsp.totalDuration, 315, 272);

                  doc.setFont("Helvetica", "");
                  doc.setTextColor("#444444");
                  doc.setFontSize(10);
                  doc.text(tsp.netAmount.toString(), 400, 272, {
                    align: "right",
                  });

                  doc.setDrawColor("#aaaaaa");
                  doc.setLineWidth(1);
                  doc.line(50, 280, 400, 280);

                  //Box left vertical line
                  doc.setDrawColor("#aaaaaa");
                  doc.setLineWidth(1);
                  doc.line(200, 280, 200, 320);

                  //Box right vertical line
                  doc.setDrawColor("#aaaaaa");
                  doc.setLineWidth(1);
                  doc.line(400, 280, 400, 320);

                  doc.setFont("Helvetica", "");
                  doc.setTextColor("#444444");
                  doc.setFontSize(10);
                  doc.text("Tax", 215, 305);

                  // doc.setDrawColor(255, 0, 0); // red lines
                  doc.setDrawColor("#aaaaaa");
                  doc.setLineWidth(1);
                  doc.line(250, 280, 250, 320);

                  let xTax = 260;
                  let yTax = 280;

                  value.taxDetails.forEach((tax, i, arr) => {
                    yTax = yTax + 15;
                    doc.setFont("Helvetica", "");
                    doc.setTextColor("#444444");
                    doc.setFontSize(9);
                    doc.text(
                      tax.taxCode + " @ " + tax.taxPerc + " %",
                      xTax + 5,
                      yTax
                    );

                    if (arr.length !== i + 1) {
                      doc.setDrawColor("#aaaaaa");
                      doc.setLineWidth(1);
                      doc.line(250, yTax + 5, 400, yTax + 5);
                    }

                    doc.setDrawColor("#aaaaaa");
                    doc.setLineWidth(1);
                    doc.line(330, 280, 330, 320);

                    doc.setFont("Helvetica", "");
                    doc.setTextColor("#444444");
                    doc.setFontSize(9);
                    doc.text(tax.taxAmt.toString(), xTax + 90, yTax);
                  });

                  doc.setDrawColor("#aaaaaa");
                  doc.setLineWidth(1);
                  doc.line(200, 320, 400, 320);
                }
              });
            } else if (subtitling.length > 0) {
              subtitling.forEach((tsp) => {
                if (value.currency === "USD") {
                  doc.setFont("Helvetica", "");
                  doc.setTextColor("#444444");
                  doc.setFontSize(10);
                  doc.text("Description for Subtitling", 50, 272);

                  doc.setFont("Helvetica", "");
                  doc.setTextColor("#444444");
                  doc.setFontSize(10);
                  doc.text("$ " + tsp.rate.toString(), 220, 272);

                  doc.setFont("Helvetica", "");
                  doc.setTextColor("#444444");
                  doc.setFontSize(10);
                  doc.text(tsp.totalDuration, 295, 272);

                  doc.setFont("Helvetica", "");
                  doc.setTextColor("#444444");
                  doc.setFontSize(10);
                  doc.text("$ " + tsp.netAmount.toString(), 400, 272, {
                    align: "right",
                  });

                  doc.setDrawColor("#aaaaaa");
                  doc.setLineWidth(1);
                  doc.line(50, 280, 400, 280);
                } else {
                  doc.setFont("Helvetica", "");
                  doc.setTextColor("#444444");
                  doc.setFontSize(10);
                  doc.text("Description for Subtitling", 50, 272);

                  doc.setFont("Helvetica", "");
                  doc.setTextColor("#444444");
                  doc.setFontSize(10);
                  doc.text("998395", 180, 272);

                  doc.setFont("Helvetica", "");
                  doc.setTextColor("#444444");
                  doc.setFontSize(10);
                  doc.text(tsp.rate.toString(), 260, 272);

                  doc.setFont("Helvetica", "");
                  doc.setTextColor("#444444");
                  doc.setFontSize(10);
                  doc.text(tsp.totalDuration, 315, 272);

                  doc.setFont("Helvetica", "");
                  doc.setTextColor("#444444");
                  doc.setFontSize(10);
                  doc.text(tsp.netAmount.toString(), 400, 272, {
                    align: "right",
                  });

                  doc.setDrawColor("#aaaaaa");
                  doc.setLineWidth(1);
                  doc.line(50, 280, 400, 280);

                  //Box left vertical line
                  doc.setDrawColor("#aaaaaa");
                  doc.setLineWidth(1);
                  doc.line(200, 280, 200, 320);

                  //Box right vertical line
                  doc.setDrawColor("#aaaaaa");
                  doc.setLineWidth(1);
                  doc.line(400, 280, 400, 320);

                  doc.setFont("Helvetica", "");
                  doc.setTextColor("#444444");
                  doc.setFontSize(10);
                  doc.text("Tax", 215, 305);

                  // doc.setDrawColor(255, 0, 0); // red lines
                  doc.setDrawColor("#aaaaaa");
                  doc.setLineWidth(1);
                  doc.line(250, 280, 250, 320);

                  let xTax = 260;
                  let yTax = 280;

                  value.taxDetails.forEach((tax, i, arr) => {
                    yTax = yTax + 15;
                    doc.setFont("Helvetica", "");
                    doc.setTextColor("#444444");
                    doc.setFontSize(9);
                    doc.text(
                      tax.taxCode + " @ " + tax.taxPerc + " %",
                      xTax + 5,
                      yTax
                    );

                    if (arr.length !== i + 1) {
                      doc.setDrawColor("#aaaaaa");
                      doc.setLineWidth(1);
                      doc.line(250, yTax + 5, 400, yTax + 5);
                    }

                    doc.setDrawColor("#aaaaaa");
                    doc.setLineWidth(1);
                    doc.line(330, 280, 330, 320);

                    doc.setFont("Helvetica", "");
                    doc.setTextColor("#444444");
                    doc.setFontSize(9);
                    doc.text(tax.taxAmt.toString(), xTax + 90, yTax);
                  });

                  doc.setDrawColor("#aaaaaa");
                  doc.setLineWidth(1);
                  doc.line(200, 320, 400, 320);
                }
              });
            } else if (translation.length > 0) {
              translation.forEach((tsp) => {
                if (value.currency === "USD") {
                  doc.setFont("Helvetica", "");
                  doc.setTextColor("#444444");
                  doc.setFontSize(10);
                  doc.text("Description for Translation", 50, 272);

                  doc.setFont("Helvetica", "");
                  doc.setTextColor("#444444");
                  doc.setFontSize(10);
                  doc.text("$ " + tsp.rate.toString(), 220, 272);

                  doc.setFont("Helvetica", "");
                  doc.setTextColor("#444444");
                  doc.setFontSize(10);
                  doc.text(tsp.totalWordCount.toString(), 295, 272);

                  doc.setFont("Helvetica", "");
                  doc.setTextColor("#444444");
                  doc.setFontSize(10);
                  doc.text("$ " + tsp.netAmount.toString(), 400, 272, {
                    align: "right",
                  });

                  doc.setDrawColor("#aaaaaa");
                  doc.setLineWidth(1);
                  doc.line(50, 280, 400, 280);
                } else {
                  doc.setFont("Helvetica", "");
                  doc.setTextColor("#444444");
                  doc.setFontSize(10);
                  doc.text("Description for Translation", 50, 272);

                  doc.setFont("Helvetica", "");
                  doc.setTextColor("#444444");
                  doc.setFontSize(10);
                  doc.text("998395", 180, 272);

                  doc.setFont("Helvetica", "");
                  doc.setTextColor("#444444");
                  doc.setFontSize(10);
                  doc.text(tsp.rate.toString(), 260, 272);

                  doc.setFont("Helvetica", "");
                  doc.setTextColor("#444444");
                  doc.setFontSize(10);
                  doc.text(tsp.totalWordCount.toString(), 315, 272);

                  doc.setFont("Helvetica", "");
                  doc.setTextColor("#444444");
                  doc.setFontSize(10);
                  doc.text(tsp.netAmount.toString(), 400, 272, {
                    align: "right",
                  });

                  doc.setDrawColor("#aaaaaa");
                  doc.setLineWidth(1);
                  doc.line(50, 280, 400, 280);

                  //Box left vertical line
                  doc.setDrawColor("#aaaaaa");
                  doc.setLineWidth(1);
                  doc.line(200, 280, 200, 320);

                  //Box right vertical line
                  doc.setDrawColor("#aaaaaa");
                  doc.setLineWidth(1);
                  doc.line(400, 280, 400, 320);

                  doc.setFont("Helvetica", "");
                  doc.setTextColor("#444444");
                  doc.setFontSize(10);
                  doc.text("Tax", 215, 305);

                  // doc.setDrawColor(255, 0, 0); // red lines
                  doc.setDrawColor("#aaaaaa");
                  doc.setLineWidth(1);
                  doc.line(250, 280, 250, 320);

                  let xTax = 260;
                  let yTax = 280;

                  value.taxDetails.forEach((tax, i, arr) => {
                    yTax = yTax + 15;
                    doc.setFont("Helvetica", "");
                    doc.setTextColor("#444444");
                    doc.setFontSize(9);
                    doc.text(
                      tax.taxCode + " @ " + tax.taxPerc + " %",
                      xTax + 5,
                      yTax
                    );

                    if (arr.length !== i + 1) {
                      doc.setDrawColor("#aaaaaa");
                      doc.setLineWidth(1);
                      doc.line(250, yTax + 5, 400, yTax + 5);
                    }

                    doc.setDrawColor("#aaaaaa");
                    doc.setLineWidth(1);
                    doc.line(330, 280, 330, 320);

                    doc.setFont("Helvetica", "");
                    doc.setTextColor("#444444");
                    doc.setFontSize(9);
                    doc.text(tax.taxAmt.toString(), xTax + 90, yTax);
                  });

                  doc.setDrawColor("#aaaaaa");
                  doc.setLineWidth(1);
                  doc.line(200, 320, 400, 320);
                }
              });
            }

            //Summary Details Part

            if (value.currency === "USD") {
              doc.setFont("Helvetica", "");
              doc.setTextColor("#444444");
              doc.setFontSize(10);
              doc.text("Sub Total", 295, 295);

              doc.setFont("Helvetica", "");
              doc.setTextColor("#444444");
              doc.setFontSize(10);
              doc.text("$ " + value.netAmount.toString(), 400, 295, {
                align: "right",
              });

              doc.setFont("Helvetica", "");
              doc.setTextColor("#444444");
              doc.setFontSize(10);
              doc.text("Discount", 295, 310);

              doc.setFont("Helvetica", "");
              doc.setTextColor("#444444");
              doc.setFontSize(10);
              doc.text("$ 0", 400, 310, {
                align: "right",
              });

              doc.setDrawColor("#aaaaaa");
              doc.setLineWidth(1.5);
              doc.line(295, 320, 400, 320);

              doc.setFont("Helvetica", "Bold");
              doc.setTextColor("#444444");
              doc.setFontSize(10);
              doc.text("Grand Total", 295, 332);

              doc.setFont("Helvetica", "Bold");
              doc.setTextColor("#444444");
              doc.setFontSize(10);
              doc.text("$ " + value.netAmount.toString(), 400, 332, {
                align: "right",
              });

              doc.setDrawColor("#aaaaaa");
              doc.setLineWidth(1.5);
              doc.line(295, 340, 400, 340);
            } else {
              doc.setFont("Helvetica", "");
              doc.setTextColor("#444444");
              doc.setFontSize(10);
              doc.text("Sub Total", 295, 340);

              doc.setFont("Helvetica", "");
              doc.setTextColor("#444444");
              doc.setFontSize(10);
              doc.text(value.amount.toString(), 400, 340, {
                align: "right",
              });

              doc.setFont("Helvetica", "");
              doc.setTextColor("#444444");
              doc.setFontSize(10);
              doc.text("Discount", 295, 355);

              doc.setFont("Helvetica", "");
              doc.setTextColor("#444444");
              doc.setFontSize(10);
              doc.text("0", 400, 355, {
                align: "right",
              });

              doc.setFont("Helvetica", "");
              doc.setTextColor("#444444");
              doc.setFontSize(10);
              doc.text("Taxable Amount", 295, 370);

              doc.setFont("Helvetica", "");
              doc.setTextColor("#444444");
              doc.setFontSize(10);
              doc.text(value.amount.toString(), 400, 370, {
                align: "right",
              });

              doc.setFont("Helvetica", "");
              doc.setTextColor("#444444");
              doc.setFontSize(10);
              doc.text("Total Tax", 295, 385);

              doc.setFont("Helvetica", "");
              doc.setTextColor("#444444");
              doc.setFontSize(10);
              doc.text(value.taxAmount.toString(), 400, 385, {
                align: "right",
              });

              doc.setDrawColor("#aaaaaa");
              doc.setLineWidth(1.5);
              doc.line(295, 395, 400, 395);

              doc.setFont("Helvetica", "Bold");
              doc.setTextColor("#444444");
              doc.setFontSize(10);
              doc.text("Grand Total", 295, 415);

              doc.setFont("Helvetica", "Bold");
              doc.setTextColor("#444444");
              doc.setFontSize(10);
              doc.text(value.netAmount.toString(), 400, 415, {
                align: "right",
              });

              doc.setDrawColor("#aaaaaa");
              doc.setLineWidth(1.5);
              doc.line(295, 425, 400, 425);
            }

            //Amount in Words Part
            const amtInWords = inWords(value.netAmount, value.currency);

            if (value.currency === "USD") {
              doc.setFont("Helvetica", "");
              doc.setTextColor("#444444");
              doc.setFontSize(10);
              doc.text("Amount in Words : ", 50, 360);



              doc.setFont("Helvetica", "Bold");
              doc.setTextColor("#444444");
              doc.setFontSize(10);
              doc.text(amtInWords.toUpperCase(), 120, 360);


            } else {
              doc.setFont("Helvetica", "");
              doc.setTextColor("#444444");
              doc.setFontSize(10);
              doc.text("Amount in Words : ", 50, 445);

              doc.setFont("Helvetica", "Bold");
              doc.setTextColor("#444444");
              doc.setFontSize(10);
              doc.text(amtInWords.toUpperCase(), 120, 445);

            }
          });

          doc.setDrawColor("#aaaaaa");
          doc.setLineWidth(1);
          doc.line(50, 590, 400, 590);

          doc.setFont("Helvetica", "");
          doc.setTextColor("#444444");
          doc.setFontSize(9);
          doc.text("This is a Computer Generated Invoice", 175, 600);

          doc.save(fileName + ".pdf");
        }
      })
      .catch((err) => {
        //console.log("Error is ", err);
      });
  };


  const deleteFile = async (row, type, e) => {
    e.preventDefault();
    e.stopPropagation();
    e.persist();

    let totalwrdcnt = 0;
    //console.log("row val,type is ", row,type);
    //let confirmText = "Are you sure you want to delete this file from the list?";
    const result = await Confirm('Are you sure you want to delete this file from the list?');
    if (result) {
      const headers = reqHeader(true, true, true);
      //const config = { headers };
      const url = `${process.env.REACT_APP_URL}/upload/deletefile/`;
      // const data={
      //   id:row._id,
      //   fp:row.folderPath,
      //   type
      // }
      const config = {
        headers, data: {
          id: row._id,
          fp: row.folderPath,
          type
        }
      };
      axios.delete(url, config)
        .then((res) => {
          //console.log("response is ", res.data);
          //console.log("data.filecnt ", res.data.data.filecnt);
          setDataList(datalist.filter(item => item._id !== row._id));
          if (res.data.data.filecnt === 0) {
            toast.error("You have removed all files from your list, if you want to proceed please upload your files first.");
            if (type === "TS") {
              history.push({
                pathname: "/addtranscription",
              });
            }
            else if (type === "TL") {
              history.push({
                pathname: "/addtranslation",
              });
            }
            else if (type === "ST") {
              history.push({
                pathname: "/addsubtitling",
              });
            }
          }
          else {
            if (type === "TL") {
              if (props.type === "image") {
                //console.log("row.totalWords,row.fileWordCount",)
                //console.log("Inside TL image files");
                totalwrdcnt = parseInt(row.totalWordCount) - parseInt(row.fileWordCount);
                //console.log("Tablelist img totalwrdcnt..",totalwrdcnt);
                let body = {
                  id: row._id,
                  folderPath: row.folderPath,
                  totalWordCount: totalwrdcnt
                }
                const url1 = `${process.env.REACT_APP_URL}/translation/updateimgtempfiletotalwordcnt`;
                const headers = reqHeader(true, true, true);
                const config = { headers };
                axios.put(url1, body, config)
                  .then((res) => {
                    //console.log("response is", res.data.msg);
                    if (res.data.success === false && res.data.msg) {
                      toast.error(res.data.msg);
                    } else {
                      history.push({
                        pathname: "/translationorder",
                        state: {
                          fp: row.folderPath,
                          wordCount: totalwrdcnt,
                          type: props.type,
                          srcLang: props.srcLang,
                          tgtLang: props.tgtLang,
                          priority: props.priority,
                          currencyval: props.currencyval,
                          //totalWords:row.totalWords
                        },
                      });
                      setTimeout(() => {
                        window.location.reload();
                      }, 3000);
                    }
                  })
                  .catch((err) => {
                    //console.log("Error from Submit Handler is ", err);
                  });
                toast.success(res.data.msg);
              }
              else {
                //console.log("Inside TL text files");
                const url1 = `${process.env.REACT_APP_URL}/translation/updatefilewordcount`;
                const headers = reqHeader(true, true, true);
                const config = { headers };
                axios
                  .get(url1, config)
                  .then((res) => {
                    //console.log("res is", res.data.data);
                    if (res.data.data) {
                      setLoading(false);

                      let totalwrdcnt = res.data.data;
                      console.log(res.data);
                      //console.log("Tablelist txt totalwrdcnt..",totalwrdcnt);
                      history.push({
                        pathname: "/translationorder",
                        //search: `?fp=${data.data.folderPath}`,
                        //state: { fp: folder, wordCount: res.data.data },

                        state: {
                          fp: row.folderPath,
                          wordCount: totalwrdcnt,
                          type: props.type,
                          srcLang: props.srcLang,
                          tgtLang: props.tgtLang,
                          priority: props.priority,
                          currencyval: props.currencyval,
                          //totalWords:row.totalWords
                        },
                      });
                      setTimeout(() => {
                        window.location.reload();
                      }, 3000);
                      //}
                    }
                  })
                  .catch((err) => {
                    //console.log("Error in Wordcount update");
                  });
                toast.success(res.data.msg);
              }
            }
            else if (type === "ST") {
              const params = {
                folderPath: row.folderPath,
              };
              const url = `${process.env.REACT_APP_URL}/subtitling/gettotalduration/`;
              const config = { headers, params };

              if (row.folderPath !== "") {
                axios
                  .get(url, config)
                  .then((res) => {
                    //console.log("totalTime..",res.data.data.totalTime);

                    history.push({
                      pathname: "/subtitlingorder",
                      //search: `?fp=${data.data.folderPath}`,
                      //state: { fp: folder, wordCount: res.data.data },
                      state: {
                        srcLang: props.srcLang,
                        tgtLang: props.tgtLang,
                        priority: props.priority,
                        currencyval: props.currencyval,
                        totalDuration: res.data.data.totalTime,
                        fp: row.folderPath
                      },
                    });
                    setTimeout(() => {
                      window.location.reload();
                    }, 3000);

                  })
                  .catch((err) => { });
                toast.success(res.data.msg);
              }

            }
            else {
              // toast.success(res.data.msg);
              // setTimeout(()=>{
              //   window.location.reload();
              // },3000);
              history.push({
                pathname: "/transcriptionorder",
                //search: `?fp=${data.data.folderPath}`,
                //state: { fp: folder, wordCount: res.data.data },
                state: {
                  srcLang: props.srcLang,
                  priority: props.priority,
                  currencyval: props.currencyval,
                  //totalDuration:res.data.data.totalTime,
                  timestampVal: props.timestampVal,
                  speakerTagVal: props.speakerTagVal,
                  fp: row.folderPath
                },
              });
              setTimeout(() => {
                window.location.reload();
              }, 3000);
              toast.success(res.data.msg);
            }
          }
        })
        .catch((err) => {
          alert("Error in deletion!");
          toast.error(err);
        });

      return true;
    }
    else {
      return false;
    }

  };

  return (


    <Table
      columns={columns}
      data={datalist}
      title={title}
      count={dataCount}
      loading={contentLoading}
      filter={src === "recentlist" ? false : true}
      showAddFilesBtn={src === 'translationTempFiles'}
      pagination={pagination}
      hideTargetDownload={hideTargetDownload}
      hideInvoiceDownload={hideInvoiceDownload}
      hideReceiptDownload={hideReceiptDownload}
    />

  );
};

export default React.memo(TableList);
