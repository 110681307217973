import React from "react";
import { useHistory } from "react-router-dom";
import LeftNavigation from "./left-navigation";
import Header from "./Header";
import TableList from "./Table/TableList";

const Subtitling = () => {
  const history = useHistory();

  const handleClick = () => {
    history.push("/addsubtitling");
  };
  return (
    <>
      <div className="container recordingPage d-flex">
        <LeftNavigation type="Subtitling" />

        <div className="pageRight">
          <Header title="My Subtitlings " type="Subtitling" />

          <div className="d-flex addProjectBtn">
            <button className="btn " onClick={handleClick}>
              Add Subtitling Project
            </button>
          </div>

          <TableList
            src="subtitling"
            pagination={true}
            hideTargetDownload={true}
            hideInvoiceDownload={true}
            hideReceiptDownload={true}
          />
        </div>
      </div>
    </>
  );
};

export default Subtitling;
