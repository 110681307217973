import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import LeftNavigation from "./left-navigation";
import Tile from "./Tile";
import TableList from "./Table/TableList";
import Header from "./Header.jsx";


const Dashboard = () => {
  const location = useLocation();
  useEffect(() => {
    return location.userPaymentInfo && location.userPaymentInfo
      ? ((window.location.hash = "!"),
        (window.onhashchange = function () {
          window.location.hash = "!";
        }))
      : null;
  }, [location]);
  return (
    <>
      <div className="container dashboard d-flex">
        <LeftNavigation type="Dashboard" />

        <div className="pageRight">
          <Header title="Welcome " type="Dashboard" />

          <div className="projectListWrap">
            <ul className="projectList d-flex">
              <Tile
                title="Transcription"
                titleType="Transcription"
                theme="transcriptionBlock"
                url="/addtranscription"
              />

              <Tile
                title="Translation"
                titleType="Translation"
                theme="translationBlock"
                url="/addtranslation"
              />

              {/* <Tile
                title="Subtitling"
                titleType="Subtitling"
                theme="subtitlingBlock"
                url="/addsubtitling"
              />

              <Tile
                title="Data Products"
                titleType="Dataproducts"
                theme="allProjectBlock"
              // url="/recordings"
              /> */}
            </ul>
          </div>

          <TableList
            src="recentlist"
            pagination={false}
            hideTargetDownload={true}
            hideInvoiceDownload={true}
            hideReceiptDownload={true}
          />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
