import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import LeftNavigation from "./left-navigation";
import Header from "./Header.jsx";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import reqHeader from "../helper/reqHeader.js";
import axios from "axios";
import Spinner from "./Spinner";
import { toast } from "react-toastify";
import moment from "moment";
import { CustomDialog, Confirm, useDialog } from "react-st-modal";
import PopupSupportedFiles from "./Table/PopupSupportedFiles";
import UploadFailedPopup from "./Table/UploadFailedPopup";

const SubtitlingProject = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const userLogin = useSelector((state) => state.userLogin);
  const email = userLogin.user.email;

  // handle the status of the file upload
  const handleChangeStatus = ({ meta, file }, status) => { };

  // const onUploadProgress = (event) => {
  //   const percentCompleted = Math.round((event.loaded * 100) / event.total);
  //   console.log("onUploadProgress", percentCompleted);
  // };

  const handleSubmit = async (files, allFiles) => {
    let momentdatetime = moment().format();
    let currentDateTime = momentdatetime.replace(/[^a-z\d]+/gi, "");
    let ind = email.indexOf("@");
    let folder = email.substr(0, ind) + currentDateTime;
    let largestfile = { name: "", size: 0 };
    setLoading(true);
    allFiles.forEach((f) => f.remove());

    const headers = reqHeader(true, true, true);
    const config = {
      headers,
      // onUploadProgress: function (progressEvent) {
      //   var percentCompleted = Math.round(
      //     (progressEvent.loaded * 100) / progressEvent.total
      //   );
      //   setProgress(percentCompleted)
      //   console.log(percentCompleted);
      // },
    };

    let file = files.map((f) => f.file);
    let meta = files.map((f) => f.meta);
    let cumulativeSize = 0;

    let fileObj = new Object();
    let fileDetails = new Object();

    let fileSizeFlag = true;
    let largeFile = "";

    for (var i = 0; i < file.length; i++) {
      cumulativeSize += file[i].size;
      if (cumulativeSize > 1073741824) {
        fileSizeFlag = false;
        largeFile = file[i].name;
      }
      if (file[i].size > largestfile.size) {
        largestfile.name = file[i].name;
        largestfile.size = file[i].size;
      }
    }
    if (fileSizeFlag) {
      let completedCount = 0;
      let length = file.length;
      for (let i = 0; i < file.length; i++) {
        fileObj = file[i];
        //fileObj.duration = meta[i].duration;
        fileDetails.name = file[i].name;
        fileDetails.size = file[i].size;
        fileDetails.duration = meta[i].duration;
        fileDetails.folder = folder;
        // fileDetails.push({
        //   name: file[i].name,
        //   size: file[i].size,
        //   duration: meta[i].duration,
        // });
        console.log("fileDetails is ", fileDetails);
        // formData.append("file", fileObj);

        const url = `${process.env.REACT_APP_URL}/upload/uploadSubtitlingFiles`;
        try {
          const body = {
            fileDetails,
          };
          const { data } = await axios.post(url, body, config);
          if (data) {
            const signedurl = data.data.url;

            let xhr = new XMLHttpRequest();
            xhr.open("PUT", signedurl, true);
            xhr.setRequestHeader("Content-type", "application/octet-stream");
            xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
            xhr.onload = function (response) {
              // console.log("on-load", response);
            };

            xhr.onreadystatechange = function () {
              if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                  // console.log("Status OK");
                } else {
                  // console.log("Status not 200");
                }
              }
            };

            xhr.onerror = function (response) {
              // console.log("Response error", response);
            };

            if (largestfile && largestfile.name != "") {
              if (largestfile.name == file[i].name) {
                // console.log("lfn,fn", largestfile.name, file[i].name);
                xhr.upload.onprogress = function (evt) {
                  // For uploads
                  if (evt.lengthComputable) {
                    var percentComplete = parseInt(
                      (evt.loaded / evt.total) * 100
                    );
                    setProgress(percentComplete);
                    // console.log("progress", percentComplete);
                  }
                };
              }
            }

            xhr.onloadend = function (response) {
              // console.log("completedCount, length", completedCount, length);

              //console.log("onloadend response is ", response); //returnValue: true

              completedCount++;
              if (completedCount == length) {
                //console.log("signedurl..",signedurl);
                let fl = signedurl.split(folder);
                //console.log("fl..",fl[0]);
                // subtitlingstagingprjts
                // subtitlingprojects
                let ffl = fl[0].split("subtitlingstagingprjts/");
                let gcp = ffl[1] + folder + "/";
                //console.log("gcp..",gcp);
                const params = {
                  type: "ST",
                  fp: folder,
                  fullp: gcp,
                };
                const url1 = `${process.env.REACT_APP_URL}/upload/isfileuploadedingcp`;
                const headers = reqHeader(true, true, true);
                const config = { headers, params };
                axios
                  .get(url1, config)
                  .then(async (res) => {
                    //console.log("res is", res.data.data);
                    if (!res.data.data) {
                      //console.log("Inside res.data.data false",res.data.data);
                      // const result = await Confirm(<UploadFailedPopup/>, {
                      //   title: "Alert",
                      //   showCloseIcon: true,
                      // });
                      const result = await Confirm(
                        "Something went wrong, please try again!"
                      );
                      //console.log("result...",result);
                      if (result) {
                        // Сonfirmation confirmed
                        //console.log("Inside true confirm");
                        window.location.reload();
                      } else {
                        //console.log("Inside esle..confirm");
                        // Сonfirmation not confirmed
                        //return <Redirect to="/dashboard" />;
                        history.push({
                          pathname: "/dashboard",
                        });
                      }
                    } else if (res.data.data) {
                      setLoading(false);
                      history.push({
                        pathname: "/subtitlingorder",
                        //search: `?fp=${data.data.folderPath}`,
                        state: { fp: folder },
                      });
                    }
                  })
                  .catch((err) => {
                    //console.log("Error in File upload");
                  });
              }
            };
            xhr.send(fileObj);
          }
        } catch (error) {
          //console.log("Error in upload ", error);
        }
      }
    } else {
      toast.error("Upload file size exceeding 1GB!");
      setLoading(false);
    }
  };

  // preview component
  const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta;
    return (
      <div className="preview-box">
        <img src={previewUrl} /> <span className="name">{name}</span> -{" "}
        <span className="status">{status}</span>
        {status !== "done" && (
          <span className="percent"> ({Math.round(percent)}%)</span>
        )}
      </div>
    );
  };

  return loading ? (
    <div>
      <Spinner
        spinneruploadtext={"Uploading files... Please Wait!"}
        progress={progress}
      />
    </div>
  ) : (
    <>
      <div className="container dashboard d-flex">
        <LeftNavigation />

        <div className="pageRight">
          <Header title="Create Subtitling" type="Subtitling" />

          <div className="tableWrap block">
            <header className="d-flex">
              <h2>File Details</h2>
              <span
                className="fileFormatLink"
                onClick={async (e) => {
                  e.preventDefault();
                  const result = await CustomDialog(
                    <PopupSupportedFiles type="Subtitling" />,
                    {
                      title: "Supported file formats and languages",
                      showCloseIcon: true,
                    }
                  );
                }}>
                Supported file formats and languages
              </span>
            </header>

            <form action="#" encType="multipart/form-data">
              {/* Drag and Drop Files */}
              <div className="browseFileWrap">
                <Dropzone
                  // getUploadParams={getUploadParams}
                  onChangeStatus={handleChangeStatus}
                  onSubmit={handleSubmit}
                  autoUpload={false}
                  styles={{
                    dropzone: {
                      overflow: "auto",
                    },
                    // dropzoneReject: { borderColor: "red", backgroundColor: "#DAA" },
                    inputLabelWithFiles: { display: "none" },
                  }}
                  canRemove={true}
                  // InputComponent={null}
                  // SubmitButtonComponent={null}
                  // PreviewComponent={null}
                  accept="video/*"
                  //accept="text/*,.doc,.docx,.xml,.xlsx,.pptx,.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  inputContent={(files, extra) =>
                    extra.reject
                      ? "Video files only"
                      : "Click Me or Drag Only Video Formats"
                  }
                />
                {/* <div>
                <button className="btn" onClick={ClickHandler}>
                  proceed
                </button>
              </div> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubtitlingProject;
