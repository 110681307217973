import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import LeftNavigation from "./left-navigation";
import Header from "./Header.jsx";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import reqHeader from "../helper/reqHeader.js";
import axios from "axios";
import Spinner from "./Spinner";
import { toast } from "react-toastify";
import moment from "moment";
import { CustomDialog } from "react-st-modal";
import PopupSupportedFiles from "./Table/PopupSupportedFiles";

const TranslationProject = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [updloadedFiles, setUploadedFiles] = useState([]);
  const [isProcessingFiles, setIsProcessingFiles] = useState(false);
  const [spinnerMessage, setSpinnerMessage] = useState(
    "Uploading files... Please Wait!"
  );

  const userLogin = useSelector((state) => state.userLogin);
  const email = userLogin.user.email;

  // handle the status of the file upload
  const handleChangeStatus = ({ meta, file }, status) => { };

  // const handleSubmit = async (files, allFiles) => {
  //   let momentdatetime = moment().format();
  //   let currentDateTime = momentdatetime.replace(/[^a-z\d]+/gi, "");
  //   let ind = email.indexOf("@");
  //   let folder = email.substr(0, ind) + currentDateTime;
  //   let largestfile = { name: "", size: 0 };
  //   setLoading(true);
  //   allFiles.forEach((f) => f.remove());

  //   const headers = reqHeader(true, true, true);
  //   const config = {
  //     headers,
  //   };

  //   let file = files.map((f) => f.file);
  //   let meta = files.map((f) => f.meta);
  //   let cumulativeSize = 0;


  //   let fileObj = {};
  //   let fileDetails = {};

  //   let fileSizeFlag = true;
  //   // let largeFile = "";

  //   for (var i = 0; i < file.length; i++) {
  //     cumulativeSize += file[i].size;
  //     if (cumulativeSize > 1073741824) {
  //       fileSizeFlag = false;
  //       // largeFile = file[i].name;
  //     }
  //     if (file[i].size > largestfile.size) {
  //       largestfile.name = file[i].name;
  //       largestfile.size = file[i].size;
  //     }
  //   }
  //   if (fileSizeFlag) {
  //     let completedCount = 0;
  //     let length = file.length;
  //     for (let i = 0; i < file.length; i++) {
  //       fileObj = file[i];
  //       fileDetails.name = file[i].name;
  //       fileDetails.size = file[i].size;
  //       fileDetails.duration = meta[i].duration;
  //       fileDetails.folder = folder;

  //       //console.log("fileDetails is ", fileDetails);

  //       const url = `${process.env.REACT_APP_URL}/upload/uploadTranslationFiles`;
  //       try {
  //         const body = {
  //           fileDetails,
  //         };
  //         const { data } = await axios.post(url, body, config);
  //         //console.log("Data is", data);
  //         if (data) {
  //           const signedurl = data.data.url;
  //           //console.log(signedurl);

  //           let xhr = new XMLHttpRequest();
  //           xhr.open("PUT", signedurl, true);
  //           xhr.setRequestHeader("Content-type", "application/octet-stream");
  //           xhr.setRequestHeader("Access-Control-Allow-Origin", "*");


  //           if (largestfile && largestfile.name !== "") {
  //             if (largestfile.name === file[i].name) {

  //               xhr.upload.onprogress = function (evt) {
  //                 // For uploads
  //                 if (evt.lengthComputable) {
  //                   var percentComplete = parseInt(
  //                     (evt.loaded / evt.total) * 100
  //                   );
  //                   setProgress(percentComplete);
  //                   //console.log("progress", percentComplete);
  //                 }
  //               };
  //             }
  //           }


  //           // eslint-disable-next-line no-loop-func
  //           xhr.onloadend = async function () {
  //             //console.log("completedCount, length", completedCount, length);
  //             completedCount++;

  //             //console.log("onloadend response is ", response); //returnValue: true

  //             if (completedCount === length) {
  //               //console.log("signedurl..",signedurl);
  //               let fl = signedurl.split(folder);
  //               //console.log("fl..",fl[0]);
  //               // translationstagingprjts
  //               // translationprojects
  //               let ffl = fl[0].split("translationstagingprjts/");

  //               let gcp = ffl[1] + folder + "/";
  //               //console.log("gcp..",gcp);
  //               const params = {
  //                 type: "TL",
  //                 fp: folder,
  //                 fullp: gcp
  //               };

  //               const url1 = `${process.env.REACT_APP_URL}/upload/isfileuploadedingcp`;
  //               const headers = reqHeader(true, true, true);
  //               const config = { headers, params };
  //               axios
  //                 .get(url1, config)
  //                 .then(async (res) => {
  //                   if (!res.data.data) {
  //                     const result = await Confirm('Something went wrong, please try again!');
  //                     if (result) {
  //                       window.location.reload();
  //                     } else {
  //                       history.push({
  //                         pathname: "/dashboard",
  //                       });
  //                     }
  //                   }
  //                   else if (res.data.data) {
  //                     const url1 = `${process.env.REACT_APP_URL}/translation/updatefilewordcount`;
  //                     const headers = reqHeader(true, true, true);
  //                     const config = { headers };
  //                     await axios
  //                       .get(url1, config)
  //                       .then((res) => {
  //                         console.log("res is", res.data.data);
  //                         if (res.data.data) {
  //                           setLoading(false);
  //                           history.push({
  //                             pathname: "/translationorder",
  //                             //search: `?fp=${data.data.folderPath}`,
  //                             state: {
  //                               fp: folder,
  //                               wordCount: res.data.data,
  //                               type: "text",
  //                               srcLang: "",
  //                             },
  //                           });
  //                         }
  //                       })
  //                       .catch(() => {
  //                         //console.log("Error in Wordcount update");
  //                       });
  //                   }
  //                 })
  //                 .catch(() => {
  //                   //console.log("Error in File upload");
  //                 });
  //             }
  //           };
  //           xhr.send(fileObj);
  //         }
  //       } catch (error) {
  //         //console.log("Error in upload ", error);
  //       }
  //     }
  //   } else {
  //     toast.error("Upload file size exceeding 1GB!");
  //     setLoading(false);
  //   }

  // };

  // const handleSubmit1 = async (files, allFiles) => {

  //   const momentdatetime = moment().format();
  //   const currentDateTime = momentdatetime.replace(/[^a-z\d]+/gi, "");
  //   const ind = email.indexOf("@");
  //   const folder = prevFolderName === '' ? email.substr(0, ind) + currentDateTime : prevFolderName;
  //   setPrevFolderName(folder);
  //   let largestfile = { name: "", size: 0 };
  //   setLoading(true);
  //   allFiles.forEach(f => f.remove());

  //   const headers = reqHeader(true, true, true);
  //   const config = { headers };

  //   const fileList = files.map(f => f.file);
  //   setUploadedFiles(fileList);
  //   const metaList = files.map(f => f.meta);

  //   const cumulativeSize = fileList.reduce((acc, file) => acc + file.size, 0);
  //   if (cumulativeSize > 1073741824) {
  //     toast.error("Upload file size exceeding 1GB!");
  //     setLoading(false);
  //     return;
  //   }

  //   fileList.forEach(file => {
  //     if (file.size > largestfile.size) {
  //       largestfile = { name: file.name, size: file.size };
  //     }
  //   });
  //   let completedCount = 0;
  //   const length = fileList.length;

  //   const handleUploadEnd = async (signedurl) => {
  //     setScannedPdfError(false);
  //     completedCount++;
  //     if (completedCount === length) {

  //       const [baseUrl,] = signedurl.split(folder);
  //       const [, gcpPath,] = baseUrl.split("translationstagingprjts/");

  //       const gcp = gcpPath + folder + "/";
  //       const params = { type: "TL", fp: folder, fullp: gcp };

  //       try {
  //         setIsProcessingFiles(true);
  //         const uploadCheckUrl = `${process.env.REACT_APP_URL}/upload/isfileuploadedingcp`;
  //         const uploadCheckResponse = await axios.get(uploadCheckUrl, { headers, params });
  //         if (!uploadCheckResponse.data.data) {
  //           const result = await Confirm('Something went wrong when uploading file, please try again!');
  //           if (result) {
  //             window.location.reload();
  //           } else {
  //             history.push({ pathname: "/dashboard" });
  //           }
  //         } else {
  //           setSpinnerMessage("Processing files... Please Wait!");
  //           const updateUrl = `${process.env.REACT_APP_URL}/translation/updatefilewordcount`;
  //           const updateResponse = await axios.get(updateUrl, { headers });
  //           if (updateResponse.data.data) {
  //             setLoading(false);
  //             history.push({
  //               pathname: "/translationorder",
  //               state: {
  //                 fp: folder,
  //                 wordCount: updateResponse.data.data,
  //                 type: "text",
  //                 srcLang: "",
  //               },
  //             });
  //           } else {
  //             setLoading(false);
  //             setIsProcessingFiles(false);

  //             toast.error("Error occured when processing files! Please try again.");
  //           }
  //         }
  //       } catch (error) {
  //         setLoading(false);
  //         const message = error.response.data.error || 'Error occured during file upload or word count update';
  //         await Confirm(message);
  //         setScannedPdfError(true);
  //         setIsProcessingFiles(false);

  //       }
  //     }
  //   };



  //   if (scannedPdfError) {
  //     completedCount = length - 1;
  //     handleUploadEnd(prevSignedUrl);
  //     return;
  //   }

  //   setIsProcessingFiles(false);
  //   setProgress(0);
  //   setSpinnerMessage("Uploading files... Please Wait!");




  //   const handleUploadProgress = evt => {
  //     if (evt.lengthComputable) {
  //       const percentComplete = parseInt((evt.loaded / evt.total) * 100);
  //       setProgress(percentComplete);
  //     }
  //   };



  //   for (let i = 0; i < fileList.length; i++) {
  //     const fileObj = fileList[i];
  //     const fileDetails = {
  //       name: fileObj.name,
  //       size: fileObj.size,
  //       duration: metaList[i].duration,
  //       folder: folder,
  //     };

  //     const uploadUrl = `${process.env.REACT_APP_URL}/upload/uploadTranslationFiles`;
  //     try {
  //       const { data } = await axios.post(uploadUrl, { fileDetails }, config);
  //       if (data) {
  //         const signedurl = data.data.url;
  //         setPrevSignedUrl(signedurl);
  //         console.log(signedurl, "Signed URL is ");
  //         const xhr = new XMLHttpRequest();
  //         xhr.open("PUT", signedurl, true);
  //         xhr.setRequestHeader("Content-type", "application/octet-stream");
  //         xhr.setRequestHeader("Access-Control-Allow-Origin", "*");

  //         // if (largestfile.name === fileObj.name) {
  //         xhr.upload.onprogress = handleUploadProgress;
  //         // }

  //         xhr.onloadend = () => handleUploadEnd(signedurl);
  //         xhr.send(fileObj);
  //       }
  //     } catch (error) {
  //       console.error("Error during upload", error);
  //     }
  //   }
  // };

  const handleSubmit = async (files) => {
    setProgress(0);
    setIsProcessingFiles(false);
    const filesData = files.map(file => file.file);

    const momentdatetime = moment().format();
    const currentDateTime = momentdatetime.replace(/[^a-z\d]+/gi, "");
    const ind = email.indexOf("@");
    const folder = email.substr(0, ind) + currentDateTime;

    const form = new FormData();
    filesData.forEach(file => form.append('file', file));
    form.append('folder', folder);
    form.append('userEmail', email);
    setUploadedFiles(filesData);

    const url = `${process.env.REACT_APP_FILE_CONVERTER_URL}/upload-file`;
    const headers = reqHeader(true, true, false);

    try {
      setLoading(true);
      const response = await axios.post(url, form, {
        headers: headers,
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgress(percentCompleted);
          if (percentCompleted === 100) {
            setIsProcessingFiles(true);
            setSpinnerMessage("Processing files... Please Wait!");
          }
        },

      });
      setLoading(false);
      setUploadedFiles([]);
      history.push({
        pathname: "/translationorder",
        state: {
          fp: folder,
          wordCount: response.data.wordCount,
          type: "text",
          srcLang: "",
          totalFiles: filesData.length,
        },
      });
    }
    catch (error) {
      setLoading(false);
      const errorMessage = error.response.data.error || 'Error occured during file upload';
      toast.error(errorMessage);
    }
  }

  return loading ? (
    <div>
      <Spinner spinneruploadtext={spinnerMessage} progress={progress} isProcessingFiles={isProcessingFiles} />
    </div>
  ) : (
    <>
      <div className="container dashboard d-flex">
        <LeftNavigation />

        <div className="pageRight">
          <Header title="Create Translation" type="Translating" />

          <div className="tableWrap block">
            <header className="d-flex">
              <h2>File Details</h2>
              <div className="d-flex headerRightWrap">

                <span
                  className="fileFormatLink"
                  onClick={async (e) => {
                    e.preventDefault();
                    await CustomDialog(
                      <PopupSupportedFiles type="Translation" />,
                      {
                        title: "Supported file formats and languages",
                        showCloseIcon: true,
                      }
                    );
                  }}>
                  Supported file formats and languages
                </span>
              </div>
            </header>

            <form action="#" encType="multipart/form-data">
              {/* Drag and Drop Files */}

              <div className="browseTranslationWrap">
                <Dropzone
                  onChangeStatus={handleChangeStatus}
                  onSubmit={handleSubmit}
                  initialFiles={updloadedFiles}
                  autoUpload={false}
                  maxFiles={10}
                  styles={{
                    dropzone: {
                      overflow: "auto",
                    },
                    inputLabelWithFiles: { display: "none" },
                  }}
                  canRemove={true}

                  accept={

                    "text/*,.doc,.docx,.xlsx,.pptx,.pdf,"

                  }
                  inputContent={(files, extra) =>

                    "Click Me or Drag only Text Format Files"

                  }
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default TranslationProject;
