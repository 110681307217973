import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import LeftNavigation from "./left-navigation";
import Header from "./Header.jsx";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import reqHeader from "../helper/reqHeader.js";
import axios from "axios";
import Spinner from "./Spinner";
import { toast } from "react-toastify";
import moment from "moment";
import { CustomDialog, Confirm, useDialog } from "react-st-modal";
import PopupSupportedFiles from "./Table/PopupSupportedFiles";

const TranscriptionProject = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploadFileName, setUploadFileName] = useState()
  const userLogin = useSelector((state) => state.userLogin);
  const email = userLogin.user.email;

  // handle the status of the file upload
  const handleChangeStatus = ({ meta, file }, status) => { };


  const handleSubmit = async (files, allFiles) => {
    console.log("files", files);
    console.log("allFiles", allFiles);
    let momentdatetime = moment().format();
    let currentDateTime = momentdatetime.replace(/[^a-z\d]+/gi, "");
    let ind = email.indexOf("@");
    let folder = email.substr(0, ind) + currentDateTime; // anassain1392021-09-07T12:00:00+05:30
    let largestfile = { name: "", size: 0 };
    setLoading(true);
    allFiles.forEach((f) => f.remove());

    const headers = reqHeader(true, true, true);
    const config = { headers };

    let file = files.map((f) => f.file);
    let meta = files.map((f) => f.meta);
    let cumulativeSize = 0;

    let fileObj = {};
    let fileDetails = {};

    let fileSizeFlag = true;

    console.log("files length", files.length);


    for (let i = 0; i < file.length; i++) {
      cumulativeSize += file[i].size;
      if (cumulativeSize > 1073741824) {
        fileSizeFlag = false;
      }
      if (file[i].size > largestfile.size) {
        largestfile.name = file[i].name;
        largestfile.size = file[i].size;
      }
    }

    if (fileSizeFlag) {
      let completedCount = 0;
      let length = file.length;


      for (let i = 0; i < file.length; i++) {
        fileObj = file[i];
        fileDetails.name = file[i].name;
        fileDetails.size = file[i].size;
        fileDetails.duration = meta[i].duration;
        fileDetails.folder = folder;


        const url = `${process.env.REACT_APP_URL}/upload/uploadTranscriptionFiles`;
        try {
          const body = {
            fileDetails,
          };
          const { data } = await axios.post(url, body, config);
          //console.log("Data is", data);
          if (data) {
            const signedurl = data.data.url;
            //console.log(signedurl);

            let xhr = new XMLHttpRequest();
            xhr.open("PUT", signedurl, true);
            xhr.setRequestHeader("Content-type", "application/octet-stream");
            xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
            xhr.onload = function (response) {
              //console.log("on-load", response);
            };

            xhr.onreadystatechange = function () {
              if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                  //console.log("Status OK");
                } else {
                  //console.log("Status not 200");
                }
              }
            };

            xhr.onerror = function (response) {
              //console.log("Response error", response);
            };

            if (largestfile && largestfile.name !== "") {
              if (largestfile.name === file[i].name) {

                xhr.upload.onprogress = function (evt) {
                  // For uploads
                  if (evt.lengthComputable) {
                    var percentComplete = parseInt(
                      (evt.loaded / evt.total) * 100
                    );
                    //setUploadFileName(file[i].name)
                    setProgress(percentComplete);
                    //console.log("progress", percentComplete);
                  }
                };
              }
            }



            xhr.onloadend = function (response) {

              completedCount++;
              if (completedCount === length) {
                //console.log("signedurl..",signedurl);
                let fl = signedurl.split(folder);
                //console.log("fl..",fl[0]);
                // transcriberstagingprjts
                // transcriberprojects
                const bucketName = process.env.NODE_ENV === "development" ? "transcriberstagingprjts" : "transcriberprojects";
                let ffl = fl[0].split(`${bucketName}/`);

                let gcp = ffl[1] + folder + "/";
                //console.log("gcp..",gcp);
                const params = {
                  type: "TS",
                  fp: folder,
                  fullp: gcp
                };

                const url1 = `${process.env.REACT_APP_URL}/upload/isfileuploadedingcp`;
                const headers = reqHeader(true, true, true);
                const config = { headers, params };
                axios
                  .get(url1, config)
                  .then(async (res) => {
                    if (!res.data.data) {

                      const result = await Confirm('Something went wrong, please try again!');
                      if (result) {
                        window.location.reload();
                      } else {
                        history.push({
                          pathname: "/dashboard",
                        });
                      }

                    }
                    else if (res.data.data) {
                      setLoading(false);
                      history.push({
                        pathname: "/transcriptionorder",
                        //search: `?fp=${data.data.folderPath}`,
                        state: { fp: folder },
                      });
                    }
                  })
                  .catch((err) => {
                    //console.log("Error in File upload");
                  });
              }
            };
            xhr.send(fileObj);
          }
        } catch (error) {
          //console.log("Error in upload ", error);
        }
      }
    } else {
      toast.error("Upload file size exceeding 1GB!");
      setLoading(false);
    }
  };

  // preview component
  const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta;
    return (
      <div className="preview-box">
        <img src={previewUrl} /> <span className="name">{name}</span> -{" "}
        <span className="status">{status}</span>
        {status !== "done" && (
          <span className="percent"> ({Math.round(percent)}%)</span>
        )}
      </div>
    );
  };

  const ClickHandler = () => {
    //history.push("./transcriptionorder");
  };

  return loading ? (
    <div>
      <Spinner
        spinneruploadtext={"Uploading files... Please Wait!"}
        progress={progress}
      //uploadFileName={uploadFileName}
      />
    </div>
  ) : (
    <>
      <div className="container dashboard d-flex">
        <LeftNavigation />

        <div className="pageRight">
          <Header title="Create Transcription" type="Transcription" />

          <div className="tableWrap block">
            <header className="d-flex">
              <h2>File Details</h2>
              <div className="d-flex headerRightWrap">
                <span
                  className="fileFormatLink"
                  onClick={async (e) => {
                    e.preventDefault();
                    await CustomDialog(
                      <PopupSupportedFiles type="Transcription" />,
                      {
                        title: "Supported file formats and languages",
                        showCloseIcon: true,
                      }
                    );
                  }}>
                  Supported file formats and languages
                </span>
              </div>
            </header>

            <form action="#" encType="multipart/form-data">
              {/* Drag and Drop Files */}
              <div className="browseFileWrap">
                <Dropzone
                  onChangeStatus={handleChangeStatus}
                  onSubmit={handleSubmit}
                  autoUpload={false}
                  styles={{
                    dropzone: {
                      overflow: "auto",
                    },
                    inputLabelWithFiles: { display: "none" },
                  }}
                  canRemove={true}
                  accept="audio/wav,"
                  inputContent={(files, extra) =>
                    extra.reject
                      ? "Audio and Video files only"
                      : "Click Me or Drag Only WAV Formats"
                  }
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default TranscriptionProject;
