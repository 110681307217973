import React, { useEffect } from 'react'
import LeftNavigation from "./left-navigation";
import Header from "./Header";
import axios from 'axios';
import { toast } from 'react-toastify';
import reqHeader from '../helper/reqHeader'
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';


const schema = yup.object().shape({
  name: yup.string().required("Name is required !!!"),
  email: yup
    .string()
    .required("Email ID is required !!!")
    .email("Enter a valid Email ID !!!"),
  message: yup.string().required("Message is required !!!")
});

const Contactus = () => {

  const [loading, setLoading] = React.useState(false)

  const { userLogin } = useSelector((state) => state);
  const { register, handleSubmit, errors, reset } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schema),

  });

  useEffect(() => {
    reset({
      name: userLogin.user.fname + " " + userLogin.user.lname,
      email: userLogin.user.email,
    })
  }, [reset, userLogin]);

  const onSubmit = async (data) => {
    try {
      const headers = reqHeader(true, true, true);
      setLoading(true);
      await axios.post(`${process.env.REACT_APP_URL}/users/contact`, data, { headers });
      toast.success('Message sent successfully');
      setLoading(false);
    } catch (error) {

      console.log(error);
      toast.error('Unable to send message');
      setLoading(false);
    }
  }
  console.log(errors);
  return (
    <>
      <div className="container recordingPage d-flex">
        <LeftNavigation type="Contactus" />

        <div className="pageRight">
          <Header title="Contact Us" type="Contactus" />

          <div class="feedback-cont">
            <p>Send your message in the form below and we will get back to you as early as possible.</p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='innerForm'>
                <div className="inputBlock">
                  <label htmlFor="" className="label">
                    Name <span className="requiredField">*</span>
                  </label>
                  <div
                    className={`inputWrap ${errors.name ? "error" : ""}`}>
                    <input type='text' ref={register} name='name' placeholder='Enter your name' />
                    {errors.name && (
                      <span className="inputErrorMsg">
                        {errors.name.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="inputBlock">
                  <label htmlFor="" className="label">
                    Email <span className="requiredField">*</span>
                  </label>
                  <div
                    className={`inputWrap ${errors.email ? "error" : ""}`}>
                    <input type='text' ref={register} name='email' placeholder='Enter your email' />
                    {errors.email && (
                      <span className="inputErrorMsg">
                        {errors.email.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="inputBlock">
                <label htmlFor="" className="label">
                  Message <span className="requiredField">*</span>
                </label>
                <div
                  className={`inputWrap ${errors.message ? "error" : ""}`}>
                  <textarea ref={register} id='message' name='message' placeholder='Enter your message' rows={3} />
                  {errors.message && (
                    <span className="inputErrorMsg">
                      {errors.message?.message}
                    </span>
                  )}
                </div>
              </div>
              <div className='btnDiv'>
                <button disabled={loading}>
                  {loading ? 'Sending...' : 'Send'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default Contactus
