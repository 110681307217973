import React from "react";
import { Cross } from "./icons.component";

const PopupFullPage = () => {
  const clickHandler = () => {
    alert("Close clicked from Popup !!!");
  };
  return (
    <>
      <div className="fullPagePopup">
        <div className="overlay"></div>
        <div className="popupInner">
          <div className="popupClose" onClick={clickHandler}>
            <Cross />
          </div>

          <div className="popupContent">
            <h2 className="oneLineMessage">Service Not available</h2>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopupFullPage;
