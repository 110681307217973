const numberToWords = require('number-to-words');

function inWords(num, currency) {
  if (num.toString().length > 9) return "overflow";

  let digits = num.toString().split(".");
  let integerPart = parseInt(digits[0], 10);
  let decimalPart = digits[1] ? parseInt(digits[1].substr(0, 2), 10) : 0;

  let integerWords = numberToWords.toWords(integerPart);
  let decimalWords = decimalPart ? numberToWords.toWords(decimalPart) : '';

  let currencyUnit = currency === "USD" ? "Dollar" : "Rupee";
  let subUnit = currency === "USD" ? "Cent" : "Paisa";

  let words = `${integerWords} ${currencyUnit}${integerPart > 1 ? 's' : ''}`;

  if (decimalWords) {
    words += ` and ${decimalWords} ${subUnit}${decimalPart > 1 ? 's' : ''}`;
  }

  words += ' only';

  return words.charAt(0).toUpperCase() + words.slice(1);
}




module.exports = {
  inWords,
};
