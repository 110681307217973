import React, { useEffect, useState } from "react";
import axios from "axios";
import reqHeader from "../../helper/reqHeader.js";
import "../../assets/style/_table.sass";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.scss";

const PopupSupportedFiles = (props) => {
  const { type } = props;

  const [languages, setLanguages] = useState([]);



  useEffect(() => {
    async function getlanguages() {
      const url =
        type === "Transcription"
          ? `${process.env.REACT_APP_URL}/language/activeTranscriptionLanguages`
          : type === "Translation"
            ? `${process.env.REACT_APP_URL}/language/activeTranslationLanguages`
            : type === "Subtitling"
              ? `${process.env.REACT_APP_URL}/language/activeSubtitlingLanguages`
              : "";

      const headers = reqHeader(true, true, true);
      const config = { headers };

      await axios
        .get(url, config)
        .then((res) => {
          setLanguages(
            res.data.data.map(({ _id, name }) => ({
              key: _id,
              label: name,
              value: name,
            }))
          );
        })
        .catch((err) => { });
    }
    getlanguages();
  }, [type]);

  const fileTypes =
    type === "Transcription"
      ? ["wav"]
      : type === "Translation"
        ? [
          "text/*",
          "doc",
          "docx",
          "xls",
          "xlsx",
          "ppt",
          "pptx",
          "pdf",
          // "jpg",
          // "jpeg",
          // "png",
          //"bmp",
        ]
        : type === "Subtitling"
          ? ["mp4", "mkv"]
          : [];

  return (
    <div className="SupportFilePopup">
      <Tabs forceRenderTabPanel defaultIndex={0} className="SupportFilePopup">
        <TabList className="SupportedTabs">
          <Tab className="tabItem">Supported Languages</Tab>
          <Tab className="tabItem">Supported File Formats</Tab>
        </TabList>

        <TabPanel>
          <div className="supportFileTabContent languageTab">
            <ul>
              {languages.map((lang, index) => (
                <li key={lang.key}>
                  <span>{lang.value}</span>
                </li>
              ))}
            </ul>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="supportFileTabContent fileFormatTab">
            <ul className="fileFormatList">
              {fileTypes.map((type, index) => (
                <li key={index} className="fileFormatList">
                  {type}
                </li>
              ))}
            </ul>
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default PopupSupportedFiles;
