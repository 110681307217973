import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { logout } from "../actions/userActions.js";
import { SignOut } from "./icons.component";
import profileIcon from "./../assets/images/profile.svg";
import passwordIcon from "./../assets/images/passwordChange.svg";
import NavBtn from "./NavBtn.jsx"

const Header = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const UserLogin = useSelector((state) => state.userLogin);
  const { fname } = UserLogin.user;
  const { title, type } = props;

  const titleMore = type === "Dashboard" ? ` ${fname},` : "";

  const profile = () => {
    history.push("/changeprofile");
  };

  const passwordChange = () => {
    history.push("/changepassword");
  };

  const logoutHandler = () => {
    dispatch(logout());
  };

  return (
    <div className="userSection">
      <NavBtn />
      <div className="welcomeUser">
        {title}
        {titleMore}
      </div>

      <div className="userInfoWrap">
        <div className="userAvatar"></div>
        <div className="userName">
          {fname}
          <div className="twoWayArrow"></div>
        </div>
        <ul className="profileOptions">
          <li className="profileItem" onClick={profile}>
            <img src={profileIcon} alt="" />
            <span>my profile</span>
          </li>

          <li className="changePwdItem" onClick={passwordChange}>
            <img src={passwordIcon} alt="" />
            <span>change password</span>
          </li>

          <li className="signOutItem" onClick={logoutHandler}>
            <SignOut />
            <span>Sign Out</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
