import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import LeftNavigation from "./left-navigation";
import reqHeader from "../helper/reqHeader.js";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./payment-checkout-form";
import PopupSuccess from "./popupSuccess";
import PopupError from "./popupError";
import { format } from "date-fns";
import Spinner from "./Spinner";

import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,

} from "react-phone-number-input";
import "react-phone-number-input/style.css";

const stripePromiseUSD = loadStripe(
  process.env.REACT_APP_ENVIRONMENT === "development" ?
    "pk_test_51IsOZvEQsxwg2PsXfRIwG0f6H7ulPWSDEPObjxSDZFcX4YBgI6uAoO8o1LLtE3tn4OuLEr0PGTEdZL9xpLHRcgs10017KV3YO7" :
    "pk_live_51IsOZvEQsxwg2PsXLj9nYbArSVTNMegydyHQQac5CjhkoYBiavF5TwQ3bCj6D3ZTOrgAr5HjCAcO9T3zGuuOMETy00ZvkRxine"
);

const stripePromiseINR = loadStripe(
  process.env.REACT_APP_ENVIRONMENT === "development" ?
    "pk_test_51IsObHSDcB6iERu75UFqTv4kZfa37RLZ3Op4paSUREAoeOm0Stix9C8r2fbmRt4c6NGfrn8NBSRv5Srpa6pg5cco001sR8IsG0" :
    "pk_live_51IsObHSDcB6iERu73pmnDwWeIYYYGq3dSK5kgZGqzPzJXMb2Q2lJDcHoeF2jlcxaZH1h6UK2ua9mZt3BESn0EPkR00ecVZZxYC"
);

const schema = yup.object().shape({
  firstName: yup
    .string()
    .matches(/^[A-Za-z\s]+$/, "Only alphabets are allowed!")
    .required("First Name is required !!!"),
  lastName: yup
    .string()
    .matches(/^[A-Za-z\s]+$/, "Only alphabets are allowed!")
    .required("Last Name is required !!!"),
  email: yup
    .string()
    .required("Email ID is required!")
    .email("Enter a valid Email ID!"),
  address1: yup
    .string()
    .required("Address is required!")
    .matches(
      /^[aA-zZ0-9-_,#/\s]+$/,
      "Only alphabets, numbers, underscore, hyphen, comma, hash and slash are allowed!"
    ),
  state: yup
    .string()
    .required("State is required!")
    .matches(/^[A-Za-z\s]+$/, "Only alphabets are allowed!"),
  city: yup
    .string()
    .required("City is required !!!")
    .matches(/^[A-Za-z\s]+$/, "Only alphabets are allowed!"),
  zipcode: yup
    .string()
    .required("Zip Code is required !!!")
    .matches(/[A-za-z0-9\s]+$/, "Only Alphnumeric values allowed!"),
});

const Payment = () => {
  const location = useLocation();

  const [countries, setCountries] = useState([]);
  const [billAddress, setBillAddress] = useState([]);
  const [custMobile, setCustMobile] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  const userPaymentInfo = useSelector((state) => state.userPayment);



  let pathParams = location.search.split("&");
  let loadStripe = stripePromiseINR;
  let type = "TS";
  let pjtName = "";
  let folderPath = "";

  if (pathParams.length === 4) {
    loadStripe =
      pathParams[0].slice(pathParams[0].length - 3, pathParams[0].length) ===
        "INR"
        ? stripePromiseINR
        : stripePromiseUSD;


    type = pathParams[1].slice(pathParams[1].length - 2, pathParams[1].length);

    pjtName = pathParams[2].slice(12, pathParams[2].length);
    pjtName = decodeURIComponent(pjtName);

    folderPath = pathParams[3].slice(3, pathParams[3].length);
    folderPath = decodeURIComponent(folderPath);
  }


  const { user, loginLocation, token } = userLogin;
  const {
    firstName,
    lastName,
    email,
    mobile,
    address1,
    address2,
    country,
    state,
    city,
    zipcode,
  } = user.billingAddress;

  const { register, handleSubmit, reset, errors, getValues } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const [lang, setLang] = useState("Hindi");
  const [rate, setRate] = useState(0);
  const [totalduration, setTotalduration] = useState(0);
  const [ETA, setETA] = useState("");
  const [totalamount, setTotalAmount] = useState(0);
  const [projname, setProjectName] = useState("");
  const [outformat, setOutFormat] = useState("");
  const [currencies, setCurrencies] = useState("");

  const headers = reqHeader(true, true, true);

  useEffect(() => {
    const fetchData = async () => {
      const url = `${process.env.REACT_APP_URL}/country/activeCountries`;
      const headers = reqHeader(true, true, true);
      const config = { headers };
      await axios
        .get(url, config)
        .then((res) => {
          //console.log("response data is", res.data.data);
          setCountries(
            res.data.data.map(({ code, name, _id: id }) => ({
              id: id,
              label: name,
              value: code,
            }))
          );
        })

      reset({
        firstName,
        lastName,
        email:
          user.email === "" || user.email === "undefined" ? email : user.email,
        mobile: mobile === "" || mobile === "undefined" ? user.mobile : mobile,
        address1,
        address2,
        country,
        state,
        city,
        zipcode,
        currency: currencies,
      });

      setBillAddress({
        firstName,
        lastName,
        email:
          user.email === "" || user.email === "undefined" ? email : user.email,
        mobile: mobile === "" || mobile === "undefined" ? user.mobile : mobile,
        address1,
        address2,
        country,
        state,
        city,
        zipcode,
        currency: currencies,
      });

      setCustMobile(
        mobile === "" || mobile === "undefined" ? user.mobile : mobile
      );
    };

    fetchData();
  }, [reset, currencies, address1, address2, city, country, email, firstName, lastName, mobile, state, user.billingAddress, user.email, user.mobile, user, userLogin, zipcode]);

  useEffect(() => {
    const getProjectSummary = async () => {
      let url = "";
      type === "TS"
        ? (url = `${process.env.REACT_APP_URL}/transcription/getprojectsummary/`)
        : type === "ST"
          ? (url = `${process.env.REACT_APP_URL}/subtitling/getprojectsummary/`)
          : (url = `${process.env.REACT_APP_URL}/translation/getprojectsummary/`);

      const params = {
        projectName: pjtName,
        folderPath,
      };
      const config = { headers, params };


      await axios
        .get(url, config)
        .then((res) => {
          setProjectName(res.data.data.projectName);
          setLang(res.data.data.srcLang);
          setRate(res.data.data.rate);
          setTotalduration(res.data.data.totalDuration);
          setETA(format(new Date(res.data.data.ETA), "dd/MM/yyyy"));
          setOutFormat(res.data.data.OutputFormat);
          setTotalAmount(res.data.data.totalAmount);
          setCurrencies(res.data.data.currency);

          setBillAddress(
            getValues({
              firstName: getValues("firstName"),
              lastName: getValues("lastName"),
              email: getValues("email"),
              mobile: getValues("mobile"),
              address1: getValues("address1"),
              address2: getValues("address2"),
              country: getValues("country"),
              state: getValues("state"),
              city: getValues("city"),
              zipcode: getValues("zipcode"),
              currency: currencies,
            })
          );
        })
        .catch((err) => {
          //console.log("Error from useEffect");
        });
    };
    getProjectSummary();
  }, []);


  const blurHandler = async (event) => {
    setBillAddress({
      firstName: getValues("firstName"),
      lastName: getValues("lastName"),
      email: getValues("email"),
      mobile: custMobile,
      address1: getValues("address1"),
      address2: getValues("address2"),
      country: getValues("country"),
      state: getValues("state"),
      city: getValues("city"),
      zipcode: getValues("zipcode"),
      currency: currencies,
    });

    //setBillAddress(getValues());
    // console.log("billAddress is", billAddress);
  };

  const submitHandler = async (event) => {
    //event.preventDefault();
  };

  if (userPaymentInfo.loading === false && userPaymentInfo.success === true) {
    window.location.hash = "!";

    window.onhashchange = function () {
      window.location.hash = "!";
    };
  }

  return userPaymentInfo && userPaymentInfo.loading === true ? (
    <Spinner
      spinnerpaytext={
        "Please do not refresh the Page while payment is in process."
      }
    />
  ) : (
    <>
      <div className="container payment d-flex">
        <LeftNavigation type="payment" />

        {userPaymentInfo &&
          userPaymentInfo.loading === false &&
          userPaymentInfo.success === true ? (
          <PopupSuccess />
        ) : null}
        {userPaymentInfo &&
          userPaymentInfo.loading === false &&
          userPaymentInfo.success === false ? (
          <PopupError />
        ) : null}

        <div className="pageRight">
          <form
            className="form"
            style={{ display: "flex" }}
            onSubmit={handleSubmit(submitHandler)}>
            <div className="contentLeft">
              <div className="block">
                <h2>Payment</h2>

                <div className="billingAddress paymentBlock">
                  <header>
                    <div className="paymentTitle">Billing Address</div>
                  </header>

                  <div className="billingAddressContent">
                    <div className="fullNameField d-flex">
                      <div className="inputBlock">
                        <label htmlFor="" className="label">
                          First Name <span className="requiredField">*</span>
                        </label>
                        <div
                          className={`inputWrap ${errors.firstName ? "error" : ""
                            }`}>
                          <input
                            type="text"
                            placeholder="First Name"
                            name="firstName"
                            ref={register}
                            onBlur={blurHandler}
                            maxLength="50"
                          />
                          {errors.firstName && (
                            <span className="inputErrorMsg">
                              {errors.firstName?.message}
                            </span>
                          )}
                        </div>
                      </div>


                      <div className="inputBlock">
                        <label htmlFor="" className="label">
                          Last Name <span className="requiredField">*</span>
                        </label>
                        <div
                          className={`inputWrap ${errors.lastName ? "error" : ""
                            }`}>
                          <input
                            type="text"
                            placeholder="Last Name"
                            name="lastName"
                            ref={register}
                            onBlur={blurHandler}
                          />

                          {errors.lastName && (
                            <span className="inputErrorMsg">
                              {errors.lastName?.message}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="inputBlock">
                        <label className="label">
                          Email ID <span className="requiredField">*</span>
                        </label>

                        <div
                          className={`inputWrap ${errors.email ? "error" : ""
                            }`}>
                          <input
                            type="text"
                            placeholder="Email"
                            name="email"
                            ref={register}
                            onBlur={blurHandler}
                          />

                          {errors.email && (
                            <span className="inputErrorMsg">
                              {errors.email?.message}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="inputBlock">
                        <label htmlFor="" className="label">
                          Mobile No.
                        </label>

                        <div
                          className={`inputWrap ${formatPhoneNumberIntl(custMobile) &&
                            !isValidPhoneNumber(custMobile)
                            ? "error"
                            : ""
                            }`}>
                          <PhoneInput
                            international
                            defaultCountry="IN"
                            placeholder="Enter Mobile Number"
                            value={formatPhoneNumberIntl(custMobile)}
                            onChange={(phone) => setCustMobile(phone)}
                            name="mobile"
                            ref={register}
                            onBlur={blurHandler}
                          />
                          {formatPhoneNumberIntl(custMobile) &&
                            !isValidPhoneNumber(custMobile) ? (
                            <span className="inputErrorMsg">
                              {" "}
                              Invalid phone number{" "}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="inputBlock addressField">
                      <label className="label">
                        Address 1 <span className="requiredField">*</span>
                      </label>

                      <div
                        className={`inputWrap ${errors.address1 ? "error" : ""
                          }`}>
                        <input
                          type="text"
                          placeholder="Address 1"
                          name="address1"
                          ref={register}
                          onBlur={blurHandler}
                        />

                        {errors.address1 && (
                          <span className="inputErrorMsg">
                            {errors.address1?.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="inputBlock addressField">
                      <label className="label">Address 2</label>

                      <div
                        className={`inputWrap ${errors.address2 ? "error" : " "
                          }`}>
                        <input
                          type="text"
                          placeholder="Address 2"
                          name="address2"
                          ref={register}
                          onBlur={blurHandler}
                        />
                        {errors.address2 && (
                          <span className="inputErrorMsg">
                            {errors.address2?.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="country-state d-flex justify-between flex-wrap">
                      <div className="inputBlock">
                        <label className="label">Country</label>
                        <div
                          className={`selectWrap ${errors.country ? "error" : ""
                            }`}>
                          {/* <div className="selectWrap"> */}
                          <select
                            name="country"
                            ref={register}
                            onBlur={blurHandler}>
                            <option key="" value="Select Country">
                              Select Country
                            </option>
                            {countries.map((op) => (
                              <option key={op.id} value={op.value}>
                                {op.label}
                              </option>
                            ))}
                          </select>
                          {errors.country && (
                            <span className="inputErrorMsg">
                              {errors.country?.message}
                            </span>
                          )}
                        </div>
                      </div>


                      <div className="inputBlock">
                        <label className="label">
                          State <span className="requiredField">*</span>
                        </label>

                        <div
                          className={`inputWrap ${errors.state ? "error" : ""
                            }`}>
                          <input
                            type="text"
                            placeholder="State"
                            name="state"
                            ref={register}
                            onBlur={blurHandler}
                            maxLength="189"
                          />

                          {errors.state && (
                            <span className="inputErrorMsg">
                              {errors.state?.message}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="inputBlock">
                        <label className="label">
                          City <span className="requiredField">*</span>
                        </label>

                        <div
                          className={`inputWrap ${errors.city ? "error" : ""}`}>
                          <input
                            type="text"
                            placeholder="City"
                            name="city"
                            ref={register}
                            onBlur={blurHandler}
                            maxLength="189"
                          />

                          {errors.city && (
                            <span className="inputErrorMsg">
                              {errors.city?.message}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="inputBlock">
                        <label className="label">
                          Postal Code <span className="requiredField">*</span>
                        </label>

                        <div
                          className={`inputWrap ${errors.zipcode ? "error" : ""
                            }`}>
                          <input
                            type="text"
                            placeholder="Postal Code"
                            name="zipcode"
                            ref={register}
                            onBlur={blurHandler}
                            maxLength="10"
                          />

                          {errors.zipcode && (
                            <span className="inputErrorMsg">
                              {errors.zipcode?.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="contentRight">
              <div className="block orderPlace">
                <h2>Place Order</h2>
                {type === "TS" ? (
                  <ul className="order-projectDetails">
                    <li>
                      <span className="label">Project Name:</span>
                      <span className="value">{projname}</span>
                    </li>

                    <li>
                      <span className="label">Language:</span>
                      <span className="value">{lang}</span>
                    </li>

                    <li className="d-flex twoOptions">
                      <div>
                        <span className="label">Rate per Minute:</span>
                        <span className="value">
                          {currencies} {rate}
                        </span>
                      </div>

                      <div>
                        <span className="label">Total Duration:</span>
                        <span className="value">{totalduration}</span>
                      </div>
                    </li>

                    <li className="d-flex twoOptions">
                      <div>
                        <span className="label">ETA:</span>
                        <span className="value">{ETA}</span>
                      </div>

                      <div>
                        <span className="label">Output Format:</span>
                        <span className="value">{outformat}</span>
                      </div>
                    </li>

                    <div className="payableRow">
                      <span className="label">Amount</span>
                      <span className="PayableValue">
                        {currencies} {totalamount}
                      </span>
                    </div>
                  </ul>
                ) : type === "TL" ? (
                  <ul className="order-projectDetails">
                    <li>
                      <span className="label">Project Name:</span>
                      <span className="value">{projname}</span>
                    </li>

                    <li className="d-flex twoOptions">
                      <div>
                        <span className="label">Wordcount:</span>
                        <span className="value">{totalduration}</span>
                      </div>

                      <div>
                        <span className="label">ETA:</span>
                        <span className="value">{ETA}</span>
                      </div>
                    </li>

                    <div className="payableRow">
                      <span className="label">Amount</span>
                      <span className="PayableValue">
                        {currencies} {totalamount}
                      </span>
                    </div>
                  </ul>
                ) : (
                  <ul className="order-projectDetails">
                    <li>
                      <span className="label">Project Name:</span>
                      <span className="value">{projname}</span>
                    </li>

                    <li>
                      <span className="label">Total Duration:</span>
                      <span className="value">{totalduration}</span>
                    </li>

                    <li className="d-flex twoOptions">
                      <div>
                        <span className="label">ETA:</span>
                        <span className="value">{ETA}</span>
                      </div>

                      <div>
                        <span className="label">Output Format:</span>
                        <span className="value">{outformat}</span>
                      </div>
                    </li>

                    <div className="payableRow">
                      <span className="label">Amount</span>
                      <span className="PayableValue">
                        {currencies} {totalamount}
                      </span>
                    </div>
                  </ul>
                )}


                <div className="">
                  <Elements stripe={loadStripe}>
                    <CheckoutForm
                      address={billAddress}
                      currency={currencies}
                      totalamount={totalamount}
                      type={type}
                      prjt={pjtName}
                      fp={folderPath}
                      user={user}
                      loginLocation={loginLocation}
                      token={token}
                    />
                  </Elements>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Payment;
