import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, Redirect } from "react-router-dom";
import { login, googleLogin } from "../actions/userActions.js";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
// import { GoogleLogin } from 'react-google-login';
// import MicrosoftLogin from "react-microsoft-login";
import Logo from "../assets/images/Logo.svg";
import { PasswordHide, PasswordShow } from "./icons.component";
// import FB from "../assets/images/FB.svg";
// import googleIcon from "../assets/images/google.svg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GoogleLogin } from '@react-oauth/google';



const schema = yup.object().shape({
  email: yup
    .string()
    .required("Email ID is required !!!")
    .email("Enter a valid Email ID !!!"),
  password: yup
    .string()
    .required("Password is required !!!")
});



const CustomerLogin = () => {
  const { register, handleSubmit, errors } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const userLogin = useSelector((state) => state.userLogin);
  let { authenticated, error, loading } = userLogin;
  const [passwordShown, setPasswordShown] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      //console.log("Inside useEffect ", error);
      toast.error(error);
    } else {
      // console.log("can update user");
    }
  }, [error]);

  //console.log("From FB State", fbstate);
  const responseGoogle = (response) => {
    console.log(response);
    if (response.credential
    )
      dispatch(googleLogin({
        tokenId: response.credential
      }))
  };

  // const responseFacebook = (response) => {
  //   if (response.status !== "unknown" && response.accessToken && response.userID)
  //     dispatch(facebookLogin({ accessToken: response.accessToken, userID: response.userID }))
  // }

  // const authHandlerMicrosoft = (err, data) => {
  //   if (data !== undefined && data !== "" && data != null)
  //     dispatch(
  //       microsoftLogin({
  //         accessToken: data.accessToken,
  //         userID: data.uniqueId,
  //         email: data.account.idToken.email,
  //         name: data.account.idToken.name,
  //         given_name: data.account.idToken.given_name,
  //         family_name: data.account.idToken.family_name,
  //       })
  //     );
  // };


  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const submitHandler = (data) => {
    if (loading) return;
    //console.log("Login Data in Submit Handler is ", data);
    dispatch(login(data));
  };

  if (authenticated) {
    return <Redirect to="/dashboard" />;
  }
  console.log(loading);
  return (
    <>
      <div className="container RegisterWrap d-flex">
        <div className="registerLHS">
          <img src={Logo} alt="Logo" className="logo" />
          <div className="description">
            Copyright &copy; Megdap Innovation Labs |{" "}
            <Link
              to={{ pathname: "https://www.megdap.com/contact/" }}
              target="_blank">
              Contact Us
            </Link>
          </div>
        </div>
        <div className="registerRHS">
          <div className="registrationForm loginForm">
            <h2 className="loginTitle">Login to your Account</h2>

            <div className="loginType">
              <ul>
                {/* <li className="FBBtn">

                  <FacebookLogin
                    appId={process.env.REACT_APP_FB_APP_ID}
                    autoLoad={false}
                    //onClick={componentClicked}
                    callback={responseFacebook}
                    render={(renderProps) => (
                      <button className="FB" onClick={renderProps.onClick}>
                        <img src={FB} alt="login with facebook" /> Login with
                        Facebook
                      </button>
                    )}
                  />
                </li> */}

                <li className="googleBtn">
                  {/* <GoogleLogin
                    clientId={process.env.REACT_APP_GOOGLE_CLIENTID}
                    render={(renderProps) => (
                      <button
                        className="google"
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}>
                        <img src={googleIcon} alt="login with Google" /> Login
                        with Google
                      </button>
                    )}
                    buttonText="Login"
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                  // cookiePolicy={"single_host_origin"}
                  /> */}
                  <GoogleLogin
                    onSuccess={responseGoogle}
                    onError={() => {
                      toast.error("Google Login Failed");
                    }}

                  />
                </li>

                <li className="officeBtn">
                  {/* <button className="office365">
                    <img src={officeIcon} alt="login with Office 365" /> Login
                    with Office 365
                  </button> */}
                  {/* <MicrosoftLogin clientId={process.env.REACT_APP_MS_APP_ID}
                    authCallback={authHandlerMicrosoft}
                  /> */}
                </li>
              </ul>
            </div>

            <div className="orDivider">or</div>

            <form className="form" onSubmit={handleSubmit(submitHandler)}>
              <div className="registerFormWrap">

                <div
                  className={`inputWrap emailField ${errors.email ? "error" : ""
                    }`}>
                  <input
                    ref={register}
                    type="text"
                    placeholder="Email ID"
                    name="email"
                  />
                  {errors.email && (
                    <span className="inputErrorMsg">
                      {errors.email?.message}
                    </span>
                  )}
                </div>

                <div
                  className={`inputWrap passwordField ${errors.password ? "error" : ""
                    }`}>
                  <input
                    ref={register}
                    type={passwordShown ? "text" : "password"}
                    placeholder="Password"
                    name="password"
                  />
                  <div className="passwordEye">
                    <i onClick={togglePasswordVisiblity}>
                      {passwordShown ? <PasswordShow /> : <PasswordHide />}
                    </i>{" "}
                    {/* <PasswordHide /> */}
                  </div>
                  {errors.password && (
                    <span className="inputErrorMsg">
                      {errors.password?.message}
                    </span>
                  )}
                  {/* {error && <span className="inputErrorMsg">{error}</span>} */}
                </div>

                <div className="remember-forgotPass d-flex">
                  <div className="rememberMeWrap">
                    <input
                      ref={register}
                      type="checkbox"
                      id="rememberMe"
                      name="isRememberMe"
                    />
                    <label htmlFor="rememberMe">Remember me</label>
                  </div>

                  <div className="Forgotpassword">
                    <Link to="/forgotpassword">Forgot Password?</Link>
                  </div>
                </div>

                <div className="formFooter">
                  <button disabled={loading}>
                    {loading ? "Logging in..." : "Login"}
                  </button>
                  <div className="loginLink">
                    Not a Member? <Link to="/register">Register Now</Link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerLogin;
