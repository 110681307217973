import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import LeftNavigation from "./left-navigation";
import { PasswordHide, PasswordShow } from "./icons.component";
import axios from "axios";
import reqHeader from "../helper/reqHeader.js";
import Header from "./Header.jsx";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";


const schema = yup.object().shape({
  currentPassword: yup.string().required("Password is required !"),
  newPassword: yup
    .string()
    .required("New Password is required !")
    .min(6, "New Password should be 6 characters long"),
  confirmpassword: yup
    .string()
    .required("Confirm Password is required !")
    .min(6, "Confirm Password should be 6 characters")
    .oneOf(
      [yup.ref("newPassword"), null],
      "New Password and Confirm Password does not match!"
    ),
});

toast.configure();


const ChangePassword = (ispasswordchanged) => {

  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const url = `${process.env.REACT_APP_URL}/updatepassword/`;
  const headers = reqHeader(true, true, true);
  const config = { headers };

  const userLogin = useSelector((state) => state.userLogin);
  const { error, } = userLogin;

  const [passwordShown, setPasswordShown] = useState(false);
  const [newpasswordShown, setNewPasswordShown] = useState(false);
  const [conpasswordShown, setConPasswordShown] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const toggleNewPasswordVisiblity = () => {
    setNewPasswordShown(newpasswordShown ? false : true);
  };
  const toggleConPasswordVisiblity = () => {
    setConPasswordShown(conpasswordShown ? false : true);
  };

  const submitHandler = (data) => {
    //console.log("form data..", data);
    const { currentPassword, newPassword } = data;
    const body = {
      currentPassword,
      newPassword,
    };
    axios
      .put(url, body, config)
      .then((res) => {

        toast.success(res.data.msg);
      })
      .catch((error) => {

        toast.error(error.response.data.error);
      });

    document.getElementById("changepwd-form").reset();
  };

  return (
    <>
      {/* <ToastContainer /> */}
      <div className="container updateUserDetails d-flex updatePassword">
        <LeftNavigation />
        <div className="pageRight">
          <form
            className="form"
            id="changepwd-form"
            onSubmit={handleSubmit(submitHandler)}>

            <Header title="Change Password" type="ChangePassword" />

            <div className="block ">
              <header>
                <h2>Edit Password</h2>
              </header>
              {error && <span>{error.error}</span>}
              <div className="blockContent d-flex">
                <div className="inputBlock">
                  <label htmlFor="" className="label">
                    Old Password <span className="requiredField">*</span>
                  </label>
                  <div className={`inputWrap passwordField ${errors.currentPassword ? "error" : ""
                    }`}>
                    <input
                      type={passwordShown ? "text" : "password"}
                      placeholder="Old Password"
                      name="currentPassword"
                      ref={register}
                    />
                    <div className="passwordEye">
                      <i onClick={togglePasswordVisiblity}>
                        {passwordShown ? <PasswordShow /> : <PasswordHide />}
                      </i>{" "}
                    </div>
                    {errors.currentPassword && (
                      <span className="inputErrorMsg">
                        {errors.currentPassword?.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="inputBlock">
                  <label htmlFor="" className="label">
                    New Password <span className="requiredField">*</span>
                  </label>
                  <div className={`inputWrap passwordField ${errors.newPassword ? "error" : ""
                    }`}>
                    <input
                      type={newpasswordShown ? "text" : "password"}
                      placeholder="New Password"
                      name="newPassword"
                      ref={register}
                    />
                    <div className="passwordEye">
                      <i onClick={toggleNewPasswordVisiblity}>
                        {newpasswordShown ? <PasswordShow /> : <PasswordHide />}
                      </i>{" "}
                    </div>
                    {errors.newPassword && (
                      <span className="inputErrorMsg">
                        {errors.newPassword?.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="inputBlock">
                  <label htmlFor="" className="label">
                    Confirm new password <span className="requiredField">*</span>
                  </label>
                  <div className={`inputWrap passwordField ${errors.confirmpassword ? "error" : ""
                    }`}>
                    <input
                      type={conpasswordShown ? "text" : "password"}
                      placeholder="Confirm Password"
                      name="confirmpassword"
                      ref={register}
                    />
                    <div className="passwordEye">
                      <i onClick={toggleConPasswordVisiblity}>
                        {conpasswordShown ? <PasswordShow /> : <PasswordHide />}
                      </i>{" "}
                    </div>
                    {errors.confirmpassword && (
                      <span className="inputErrorMsg">
                        {errors.confirmpassword?.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <footer className="blockFooter">
                <button className="btn">update</button>
              </footer>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
