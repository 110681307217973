import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { forgotpassword, clearMessage } from "../actions/userActions.js";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Logo from "../assets/images/Logo.svg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const schema = yup.object().shape({
  email: yup
    .string()
    .required("Email ID is required !!!")
    .email("Enter a valid Email ID !!!")
});



const Forgotpassword = () => {
  const { register, handleSubmit, errors } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const userLogin = useSelector((state) => state.userLogin);
  //console.log("userLogin", userLogin)
  let { loading, error, msg } = userLogin;
  const dispatch = useDispatch();


  useEffect(() => {
    if (loading && msg !== "") {
      toast.success(msg);
      dispatch(clearMessage(userLogin));
    } else if (error !== "") {
      toast.error(error);
      dispatch(clearMessage(userLogin));
    }
  }, [error, msg, dispatch, userLogin, loading]);


  const submitHandler = (data) => {
    dispatch(forgotpassword(data));
  };


  return (
    <>
      <div className="container RegisterWrap d-flex">
        <div className="registerLHS">
          <Link to={'/'} className="logo">
            <img src={Logo} alt="Logo" className="logo" />
          </Link>
          <div className="description">
            Copyright &copy; Megdap Innovation Labs |{" "}
            <Link
              to={{ pathname: "https://www.megdap.com/contact/" }}
              target="_blank">
              Contact Us
            </Link>
          </div>
        </div>
        <div className="registerRHS">
          <div className="registrationForm loginForm">
            <h2 className="loginTitle">Forgot Password?</h2>

            <form className="form" onSubmit={handleSubmit(submitHandler)}>
              <div className="registerFormWrap">

                <div
                  className={`inputWrap emailField ${errors.email ? "error" : ""
                    }`}>
                  <input
                    ref={register}
                    type="text"
                    placeholder="Email ID"
                    name="email"
                  />
                  {errors.email && (
                    <span className="inputErrorMsg">
                      {errors.email?.message}
                    </span>
                  )}
                </div>

                <div className="formFooter">
                  <button>Submit</button>

                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Forgotpassword;
