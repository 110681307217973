import React from "react";
import LeftNavigation from "./left-navigation";
import Header from "./Header";
import TableList from "./Table/TableList";

const Recording = () => {
  return (
    <>
      <div className="container recordingPage d-flex">
        <LeftNavigation type="Recordings" />

        <div className="pageRight">
          <Header title="My Orders " type="Recording" />

          <TableList
            src="allProjects"
            pagination={true}
            hideTargetDownload={false}
            hideInvoiceDownload={false}
            hideReceiptDownload={false}
          />
        </div>
      </div>
    </>
  );
};

export default Recording;
