import React from "react";
import { Link, useHistory } from "react-router-dom";
import Logo from "../assets/images/Logo.svg";
import {
  FileFolder,
  Recording,
  Transcription,
  Translation,
  // Subtitling,
  SignOut,
  ContactUs,
  Faqs
} from "./icons.component";
import { useDispatch } from "react-redux";
import { logout } from "../actions/userActions.js";
import { withRouter } from "react-router-dom"

import { handleSideNav } from "./NavBtn.jsx"

const LeftNavigation = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { type } = props;

  const home = () => {
    history.push("/dashboard");
  };

  const recordings = () => {
    history.push("/recordings");
  };

  const transcription = () => {
    history.push("/transcriptions");
  };

  const translation = () => {
    history.push("/translations");
  };

  const contactUs = () => {
    history.push("/contactus")
  }

  const faqs = () => {
    history.push("/faqs")
  }

  // const subtitling = () => {
  //   history.push("/subtitlings");
  // };

  const logoutHandler = () => {
    dispatch(logout());
  };

  return (
    <>
      <div className="leftSideBar">
        <img src={Logo} alt="Logo" className="LHSLogo" onClick={home} />
        <div className="menuBtnIconClose" onClick={() => handleSideNav("open")}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" className="size-6" width="30px">
            <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>

        </div>

        <ul className="navigationWrap">
          <li
            className={type === "Dashboard" ? "navItem active" : "navItem"}
            onClick={home}>
            <FileFolder />
            <div className="navLabel">Home</div>
          </li>

          <li
            className={type === "Recordings" ? "navItem active" : "navItem"}
            onClick={recordings}>
            <Recording />
            <div className="navLabel">My Orders</div>
          </li>

          <li
            className={type === "Transcription" ? "navItem active" : "navItem"}
            onClick={transcription}>
            <Transcription />
            <div className="navLabel">Transcription</div>
          </li>

          <li
            className={type === "Translation" ? "navItem active" : "navItem"}
            onClick={translation}>
            <Translation />
            <div className="navLabel">Translation</div>
          </li>

          {/* <li
            className={type === "Subtitling" ? "navItem active" : "navItem"}
            onClick={subtitling}>
            <Subtitling />
            <div className="navLabel">Subtitling</div>
          </li> */}

          <li className={type === "Contactus" ? "navItem active" : "navItem"} onClick={contactUs}>
            <ContactUs />
            <div className="navLabel">
              Contact Us
            </div>
          </li>

          <li className={type === "Faqs" ? "navItem active" : "navItem"} onClick={faqs}>
            <Faqs />
            <div className="navLabel">
              FAQS
            </div>
          </li>
        </ul>

        <div className="logoutLink">

          <ul className="navigationWrap">
            <li className="navItem" onClick={logoutHandler}>
              <SignOut />
              <div className="navLabel"> Sign Out</div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default withRouter(LeftNavigation);
