import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { PlusIcon } from "./icons.component";
import axios from "axios";
import reqHeader from "../helper/reqHeader.js";

const Tile = (props) => {
  const [projectCount, setProjectCount] = useState(0);
  const [progressCount, setProgressCount] = useState(0);
  const history = useHistory();
  const { title, titleType, theme, url } = props;

  const tileTheme = `projectBlock ${theme}`;

  useEffect(() => {
    async function getJobCount() {
      const url =
        titleType === "Transcription"
          ? `${process.env.REACT_APP_URL}/transcription/jobcount`
          : titleType === "Translation"
            ? `${process.env.REACT_APP_URL}/translation/jobcount`
            : titleType === "Subtitling"
              ? `${process.env.REACT_APP_URL}/subtitling/jobcount`
              : titleType === "Dataproducts"
                ? null
                : null;
      const headers = reqHeader(true, true, true);
      const config = { headers };

      axios
        .get(url, config)
        .then((res) => {
          setProjectCount(res.data.total);
          setProgressCount(res.data.progress);
        })
        .catch((err) => {
          setProjectCount(0);
          setProgressCount(0);
        });
    }
    getJobCount();
  }, []);

  const handleClick = () => {
    history.push(url);
  };

  return (
    <li className={tileTheme} onClick={handleClick}>
      <div className="circle"></div>
      <h2 className="projecttitle">{title}</h2>
      <div className="d-flex twoOptions">
        {titleType === "Dataproducts" ? (
          <h5 className="projectCount">Ongoing {progressCount}</h5>
        ) : (
          <h5 className="projectCount">Ongoing {progressCount}</h5>
        )}
        {titleType === "Dataproducts" ? (
          <h5 className="projectCount">Total {projectCount}</h5>
        ) : (
          <h5 className="projectCount">Total {projectCount}</h5>
        )}
      </div>

      <div className="addProject d-flex">
        <PlusIcon />
        {titleType === "Dataproducts" ? (
          <span>List all products</span>
        ) : (
          <span>add project</span>
        )}
      </div>
    </li>
  );
};

export default Tile;
