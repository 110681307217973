import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import ErrorIcon from "./../assets/images/ErrorPop.svg";
import { resetPayment } from "../actions/paymentAction.js";


const PopupError = () => {
  const [classhide, setClassHide] = useState(false)

  const dispatch = useDispatch();
  const history = useHistory();

 const hidePaymentError = () => {
  dispatch(resetPayment());
  setClassHide(true);
 }

 const handleClick = () => {
  dispatch(resetPayment());
  history.push({
    pathname: "./dashboard",
    search: "?query=true",
  });
};

 const userPayment = useSelector((state) => state.userPayment);
 const stripeError = userPayment.msg;

  return (
    <>
      <div className={`fullPagePopup Error ${classhide === true ? "hide" : ""}`}>
        <div className="overlay"></div>
        <div className="popupInner">
          <div className="popupContent">
            <div className="popupMessageWrap">
              <div className="messageIconWrap">
                <img src={ErrorIcon} alt="" />
              </div>
              <h2 className="popupMessage">Payment Failed</h2>
            </div>

            <div className="messageblock">Your payment has been failed. <br />
              <span>{stripeError}</span>
            </div>

            <div className="tryAgain">
              <a href="#" onClick={hidePaymentError}> Click Here</a> to try again
            </div>

            {/* <div className="downloadRecipt d-flex align-center justify-center">
              <Link to="/dashboard">
                <span>Dashboard</span>
              </Link>
            </div> */}

            <div
              className="downloadRecipt d-flex align-center justify-center"
              onClick={handleClick}>
                <Link><span>Dashboard</span></Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopupError;
