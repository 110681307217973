import React from "react";
import spinner from "../assets/images/spinner.gif";
import { Line } from 'rc-progress';

const Spinner = ({ spinneruploadtext, spinnerpaytext, progress, isProcessingFiles = false }) => (
  <div className="fullPageLoader">
    <div className="overlay"></div>
    <div className="loaderInner">
      <img
        src={spinner}
        style={{ width: "100px", margin: "auto", display: "block" }}
        alt="Loading....."
      />
      {spinneruploadtext ?
        <div className="loadingTxt">
          <div>{spinneruploadtext}</div>

          {!isProcessingFiles && <Line percent={progress} strokeWidth="4" strokeColor="#57E3E0" />}
          {!isProcessingFiles && `${progress}%`}
        </div> :
        <div className="loadingTxt">
          {spinnerpaytext}
        </div>
      }

    </div>
  </div>
);

export default Spinner;
