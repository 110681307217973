import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import LeftNavigation from "./left-navigation";
import reqHeader from "../helper/reqHeader.js";
import axios from "axios";
import TableList from "./Table/TableList";
import Select from "react-select";
import { format } from "date-fns";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PopupQuatation from "./Table/PopupQuotation";
import { CustomDialog } from "react-st-modal";

const schema = yup.object().shape({
  projname: yup
    .string()
    .matches(
      /^[aA-zZ0-9-_\s]+$/,
      "Only alphabets, numbers, underscore and hyphen are allowed!"
    )
    .required("Project Name is required!"),
});

toast.configure();

const SubtitlingOrder = () => {
  const location = useLocation();
  const history = useHistory();
  const headers = reqHeader(true, true, true);

  const userLogin = useSelector((state) => state.userLogin);
  const { id } = userLogin.user;
  const { loginCurrency } = userLogin.loginLocation;

  const folderPath = location.state != undefined ? location.state.fp : "";

  console.log("folderPath is ", folderPath);

  const methods = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const { register, handleSubmit, errors, setValue } = methods;

  const [srcLanguage, setSrcLanguage] = useState([]);
  const [tgtLanguage, setTgtLanguage] = useState([]);
  const [priorities, setPriorities] = useState([
    { value: "Normal" },
    { value: "High" },
    { value: "Urgent" },
  ]);
  const [outputformats, setOutputformats] = useState([
    { value: "SRT" },
    { value: "VTT" },
    // { value: "SCC" },
  ]);

  const [totalDuration, setTotalDuration] = useState("00:00:00");
  const [ETA, setETA] = useState("48");
  const [totalrate, setTotalRate] = useState(0);
  const [totalamount, setTotalAmount] = useState(0);
  const [currencyval, setCurrrencyVal] = useState(
    loginCurrency == "" || loginCurrency == undefined ? "INR" : loginCurrency
  );

  const [rateMatrix, setRateMatrix] = useState([]);

  const [srcLang, setSrcLang] = useState("");
  const OnChangeSourceLanguage = (item) => {
    setValue("srcLang", item.value);
    setSrcLang(item);
  };

  const [tgtLang, setTgtLang] = useState("");
  const OnChangeTargetLanguage = (item) => {
    const target = item.map((vals) => vals.value).toString();
    setValue("tgtLang", target);
    setTgtLang(item);
  };

  const [priority, setPriority] = useState(priorities[0]);
  const OnChangePriority = (item) => {
    setValue("priority", item.value);
    setPriority(item);
  };

  const [output, setOutput] = useState(outputformats[0]);
  const [toasterror, setToastError] = useState(
    "Project Name already exists. Please choose a different name."
  );

  const OnChangeOutput = (item) => {
    setValue("output", item.value);
    setOutput(item);
  };

  useEffect(() => {
    if (location.state.srcLang != undefined) {
      setValue("srcLang", location.state.srcLang);
      setSrcLang(location.state.srcLang);
    }
    if (location.state.tgtLang != undefined && location.state.tgtLang) {
      const target = location.state.tgtLang
        .map((vals) => vals.value)
        .toString();
      setValue("tgtLang", target);
      setTgtLang(location.state.tgtLang);
    }
    if (location.state.priority != undefined) {
      setValue("priority", location.state.priority);
      setPriority(location.state.priority);
    }

    if (location.state.currencyval != undefined) {
      setCurrrencyVal(location.state.currencyval);
    }
    if (location.state.totalDuration != undefined) {
      setTotalDuration(location.state.totalDuration);
    }
  }, []);

  useEffect(() => {
    async function getlanguages() {
      const url = `${process.env.REACT_APP_URL}/language/activeSubtitlingLanguages`;
      const config = { headers };
      await axios
        .get(url, config)
        .then((res) => {
          setSrcLanguage(
            res.data.data.map(({ name }) => ({ label: name, value: name }))
          );

          setTgtLanguage(
            res.data.data.map(({ name }) => ({ label: name, value: name }))
          );
        })
        .catch((err) => { });
    }
    getlanguages();

    async function getPriorities() {
      const url = `${process.env.REACT_APP_URL}/priorities/`;
      const params = { type: "Subtitling" };
      const config = { headers, params };
      await axios
        .get(url, config)
        .then((res) => {
          setPriorities(res.data.data.map(({ name }) => ({ value: name })));
        })
        .catch((err) => { });
    }
    // getPriorities();

    const params = {
      folderPath: folderPath,
    };

    async function getTotalDuration() {
      const url = `${process.env.REACT_APP_URL}/subtitling/gettotalduration/`;
      const config = { headers, params };

      if (folderPath != "") {
        await axios
          .get(url, config)
          .then((res) => {
            setTotalDuration(res.data.data.totalTime);
          })
          .catch((err) => { });
      }
    }
    getTotalDuration();

    setValue("priority", priorities[0].value);
    setValue("output", outputformats[0].value);
    setValue("currency", loginCurrency);
  }, []);

  useEffect(() => {
    async function getDeadline() {
      const url = `${process.env.REACT_APP_URL}/deadline/getspecificdeadline/`;

      const params = {
        type: "Subtitling",
        srcLang: srcLang.value,
        priority: priority.value,
        quality: "3",
      };

      const config = { headers, params };

      if (srcLang && priority) {
        await axios
          .get(url, config)
          .then((res) => {
            setETA(res.data.data.ETAHour);
          })
          .catch((err) => {
            // console.log("Error from useEffect");
          });
      }
    }

    getDeadline();
  }, [srcLang, priority]);

  useEffect(() => {
    async function getRates() {
      let totals = 0;
      let rates = 0;

      let para = [];

      for (let i = 0; i < tgtLang.length; i++) {
        const element = {
          type: "Subtitling",
          srcLang: srcLang.value,
          tgtLang: tgtLang[i].value,
          duration: totalDuration,
          priority: priority.value,
          quality: "3",
        };

        para = [...para, element];
      }

      let rateList = [];
      let convEngRateINR = 115 / tgtLang.length,
        convEngRateUSD = 115 / tgtLang.length / 70;

      for (let i = 0; i < para.length; i++) {
        const url = `${process.env.REACT_APP_URL}/rates/OC/subtitling`;
        const headers = reqHeader(true, true, true);
        let params = para[i];
        const config = { headers, params };

        await axios
          .get(url, config)
          .then((res) => {
            let data = res.data.data;

            data.map((value, index) => {
              // let rate =
              //   (currencyval == "INR" ? value.INRRate : value.USDRate) *
              //   value.priorityFactor *
              //   value.tgtCategory *
              //   value.discFactor;

              let rate =
                (currencyval == "INR"
                  ? (value.INRRate + convEngRateINR).toFixed(2)
                  : (value.USDRate + convEngRateUSD).toFixed(2)) *
                value.priorityFactor *
                value.tgtCategory *
                value.discFactor;

              const times = totalDuration.split(":");
              const hhRate = Number(times[0]) * 60 * rate;
              const mmRate = Number(times[1]) * rate;
              const ssRate = (Number(times[2]) / 60) * rate;
              const amount = hhRate + mmRate + ssRate;

              const rated = {
                id: index + "_" + (i + 1),
                srcLang: params.srcLang,
                tgtLang: params.tgtLang,
                rate: rate,
                currency: currencyval,
              };

              rateList = [...rateList, rated];

              rates = rates + rate;
              totals = totals + amount;
            });
          })
          .catch((err) => { });
      }

      setRateMatrix(rateList);
      setTotalRate(rates.toFixed(2));
      setTotalAmount(totals.toFixed(2));
    }

    getRates();
  }, [srcLang, tgtLang, priority, currencyval]);

  const submitHandler = (data, e) => {
    e.preventDefault();
    e.stopPropagation();
    e.persist();

    if (
      (currencyval === "INR" ? totalamount >= 100 : totalamount >= 1) &&
      data.projname !== "" &&
      data.projname !== undefined &&
      data.projname != null &&
      srcLang !== "" &&
      srcLang !== undefined &&
      srcLang !== null &&
      tgtLang !== "" &&
      tgtLang !== undefined &&
      tgtLang !== null &&
      priority !== "" &&
      priority !== undefined &&
      priority !== null &&
      output !== "" &&
      output !== undefined &&
      output != null
    ) {
      const url = `${process.env.REACT_APP_URL}/subtitling/updatetempprojfiles/`;
      const headers = reqHeader(true, true, true);
      const body = {
        folderPath,
        customerID: id,
        projectName: data.projname,
        srcLang: srcLang.value,
        tgtLang: tgtLang.map((vals) => vals.value).toString(),
        priority: priority.value,
        ETA: ETA,
        outputformat: output.value,
        currency: currencyval,
        rates: rateMatrix,
        totalRate: totalrate,
        totalduration: totalDuration,
        amount: totalamount,
      };

      const config = { headers };

      axios
        .put(url, body, config)
        .then((res) => {
          // console.log("response is", res.data.data.msg);
          if (res.data.success === false && res.data.msg) {
            toast.error(toasterror);
            //setToastError("");
          } else {
            history.push({
              pathname: "./payment",
              search: `?currency=${currencyval}&type=ST&projectName=${data.projname}&fp=${folderPath}`,
              //state: { fp: folderPath },
            });
            setToastError("");
          }
        })
        .catch((err) => {
          // console.log("Error from useEffect");
        });
    } else {
      if (
        data.projname === "" ||
        data.projname === undefined ||
        data.projname == null
      ) {
        toast.error("Project Name is required !!!");
      } else if (srcLang === "" || srcLang === undefined || srcLang == null) {
        toast.error("Source Language is required !!!");
      } else if (tgtLang === "" || tgtLang === undefined || tgtLang == null) {
        toast.error("Target Language is required !!!");
      } else if (priority === "" || priority === undefined || priority == null) {
        toast.error("Priority is required !!!");
      } else if (output === "" || output === undefined || output == null) {
        toast.error("Output Format is required !!!");
      } else if (currencyval === "INR" ? totalamount < 100 : totalamount < 1) {
        const amt = currencyval === "INR" ? 100 : 1;
        toast.error(
          `Minimum Payable Amount should be ${currencyval} ${amt} !!!`
        );
      } else if (totalamount === 0) {
        const amt = currencyval === "INR" ? 100 : 1;
        toast.error(`Payable Amount should not be ${currencyval} ${amt} !!!`);
      }
    }
  };

  let minutesToAdd = ETA * 60;
  let currentDate = new Date();

  let futureDate = new Date(currentDate.getTime() + minutesToAdd * 60000);
  let isostr = futureDate.toISOString().split("T")[0];
  futureDate = format(new Date(isostr), "dd/MM/yyyy");

  return (
    <>
      <div className="container dashboard d-flex">
        <LeftNavigation />

        <div className="pageRight d-flex">
          <form className="form d-flex flex-col" onSubmit={handleSubmit(submitHandler)}>
            <div className="contentLeft">
              <div className="block">
                <h2>Summary</h2>
                <div className="addProjectDetails">
                  <div className="d-flex createOption">
                    <div className="inputWrap">
                      <label className="label">
                        Project Name <span className="requiredField">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Project Name"
                        name="projname"
                        ref={register}
                        //onBlur={(e) => handleProjectName(e)}
                        maxLength="30"
                      />
                      {errors.projname && (
                        <span className="inputErrorMsg">
                          {errors.projname?.message}
                        </span>
                      )}
                    </div>

                    <div className="inputWrap">
                      <label className="label">Priority</label>
                      <Controller
                        control={methods.control}
                        name="priority"
                        defaultValue={{}}
                        render={({ onChange, value, name, ref }) => (
                          <Select
                            inputRef={ref}
                            name={name}
                            value={priority}
                            onChange={OnChangePriority}
                            placeholder={"Select Priority"}
                            options={priorities}
                            getOptionValue={(option) => option.value}
                            getOptionLabel={(option) => option.value}
                          //styles={customStyles}
                          />
                        )}
                      />
                    </div>

                    <div className="inputWrap">
                      <label className="label">Source Language</label>
                      <Controller
                        control={methods.control}
                        name="srcLang"
                        defaultValue={{}}
                        render={({ onChange, value, name, ref }) => (
                          <Select
                            inputRef={ref}
                            name={name}
                            value={srcLang}
                            onChange={OnChangeSourceLanguage}
                            options={srcLanguage}
                            placeholder={"Select Source Language"}
                            getOptionValue={(option) => option.value}
                            getOptionLabel={(option) => option.value}
                          //styles={customStyles}
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className="d-flex createOption">
                    <div className="inputWrap targetLangSelect">
                      <label className="label">Target Languages</label>
                      <Controller
                        control={methods.control}
                        name="srcLang"
                        defaultValue={{}}
                        render={({ onChange, value, name, ref }) => (
                          <Select
                            inputRef={ref}
                            name={name}
                            value={tgtLang}
                            onChange={OnChangeTargetLanguage}
                            options={tgtLanguage}
                            isMulti={true}
                            isSearchable={true}
                            placeholder={"Select Target Languages"}
                            getOptionValue={(option) => option.value}
                            getOptionLabel={(option) => option.value}
                          //styles={customStyles}
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className="d-flex createOption">
                    <div className="inputWrap">
                      <label className="label">Output Format</label>
                      <Controller
                        control={methods.control}
                        name="output"
                        defaultValue={{}}
                        render={({ onChange, value, name, ref }) => (
                          <Select
                            inputRef={ref}
                            name={name}
                            value={output}
                            onChange={OnChangeOutput}
                            options={outputformats}
                            placeholder={"Select Output"}
                            getOptionValue={(option) => option.value}
                            getOptionLabel={(option) => option.value}
                          //styles={customStyles}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <TableList
                  src="subtitleTempFiles"
                  pagination={true}
                  srcLang={srcLang}
                  tgtLang={tgtLang}
                  priority={priority}
                  currencyval={currencyval}
                />
              </div>
            </div>

            <div className="contentRight">
              <div className="block orderPlace">
                <h2>Place Order</h2>
                <ul>
                  <div className="orderRow">
                    <span className="label">Total Duration</span>
                    <span className="value">{totalDuration}</span>
                  </div>

                  {/* <div className="orderRow">
                    <span className="label">Rate Matrix</span>
                    <span className="value">{totalDuration}</span>
                  </div> */}
                </ul>
                <div className="currencyRow">
                  <div className="label">Currency</div>
                  <select
                    name="currency"
                    ref={register}
                    onChange={(e) => setCurrrencyVal(e.target.value)}>
                    <option defaultValue value="INR">
                      INR
                    </option>
                    <option value="USD">USD</option>
                  </select>
                </div>

                <div className="payableRow">
                  <span className="label">Payable Amount</span>
                  <span className="PayableValue">
                    {currencyval} {totalamount}
                  </span>
                </div>

                <button
                  className="quoteBtn"
                  onClick={async (e) => {
                    e.preventDefault();
                    await CustomDialog(
                      <PopupQuatation
                        src="subtitleTempFiles"
                        ratelist={rateMatrix}
                        totalrate={totalrate}
                        totalamount={totalamount}
                        currency={currencyval}
                      />,
                      {
                        title: "Quote Detail",
                        showCloseIcon: true,
                      }
                    );
                  }}>
                  Quote Detail
                </button>

                <div className="rightAlignRow border-btm">
                  <span className="value">
                    <strong>{ETA} Hours Turnaround</strong>
                  </span>
                </div>

                <div className="rightAlignRow">
                  <span className="label ETALabel">ETA : </span>
                  <span className="value">
                    <strong>{futureDate}</strong>
                  </span>
                </div>
                <button className="btn">Confirm</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SubtitlingOrder;
