import React from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination
} from "react-table";
import GlobalFilter from "./GlobalFilter.jsx";
import { SortIconUP, SortIcon } from "../icons.component";

const Table = ({
  filter,
  columns,
  data,
  title,
  count,
  pagination,
  hideTargetDownload,
  hideInvoiceDownload,
  hideReceiptDownload,
  loading,
  showAddFilesBtn,
}) => {


  const hideColumns = ["_id"];

  hideTargetDownload ? hideColumns.push("download") : hideColumns.push("");

  hideInvoiceDownload ? hideColumns.push("invoice") : hideColumns.push("");

  hideReceiptDownload ? hideColumns.push("receipt") : hideColumns.push("");

  let pageSizeArr = [];

  if (count <= 10) {
    pageSizeArr.push("10");
  } else if (count > 10 && count <= 25) {
    pageSizeArr.push("10");
    pageSizeArr.push("25");
  } else if (count > 25 && count <= 50) {
    pageSizeArr.push("10");
    pageSizeArr.push("25");
    pageSizeArr.push("50");
  } else if (count > 50) {
    pageSizeArr.push("10");
    pageSizeArr.push("25");
    pageSizeArr.push("50");
    pageSizeArr.push("100");
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    setGlobalFilter,
    state,
  } = useTable(
    {
      columns,
      data,
      initialState: { hiddenColumns: hideColumns },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const { pageIndex, pageSize } = state;

  const paginationControl = pagination && data.length > 0 ? (
    <div className="paginationWrap d-flex">
      <select
        className="showpages"
        value={pageSize}
        onChange={(e) => setPageSize(Number(e.target.value))}
        disabled={count <= 10}>
        {pageSizeArr.map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            Show {pageSize}
          </option>
        ))}
      </select>
      <div className="page">
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
          {"  "}
        </span>
        <span>
          | Go to Page{" "}
          <span className="inputWrap">
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const pageNumber = e.target.value
                  ? Number(e.target.value) - 1
                  : 0;
                gotoPage(pageNumber);
              }}
              onKeyPress={(e) => {
                var inputKeyCode = e.key;
                if (inputKeyCode != null) {
                  if (inputKeyCode === "-") e.preventDefault();
                }
              }}
            />
          </span>
        </span>
      </div>

      <div className="paginationAction">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </button>
        <button
          className="prevBtn"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}>
          &lt; Prev
        </button>
        <button
          className="NextBtn"
          onClick={() => nextPage()}
          disabled={!canNextPage}>
          Next &gt;
        </button>
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </button>
      </div>
    </div>
  ) : (
    ""
  );
  return (
    <div className="tableWrap projectTableWrap block">
      <header className="d-flex">
        <h2>
          {title} <span className="projectCount">({count})</span>
        </h2>

        {filter === false ? (
          ""
        ) : (
          <GlobalFilter
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        )}
      </header>

      {/* {showAddFilesBtn && (
        <div className="addFilesBtn">

          <button className="btn" type="button" >Add More Files</button>
        </div>
      )} */}

      <div className="projectTable">
        {/* React Table Will come here */}
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th // style={column.style}
                    {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <span>{column.render("Header")}</span>
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <span className="sortDown">
                            <SortIconUP />
                          </span>
                        ) : (
                          <span className="sortUp">
                            <SortIconUP />
                          </span>
                        )
                      ) : (
                        <span className="sortIcon">
                          <SortIcon />
                        </span>
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {
            loading ? <tr>
              < td colSpan={columns.length} style={{ textAlign: 'center' }}>
                Loading...
              </td>
            </tr> :
              data.length === 0 ? <tr>
                < td colSpan={columns.length} style={{ textAlign: 'center' }}>
                  No data found
                </td>
              </tr> :
                <tbody {...getTableBodyProps()}>
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
          }
        </table>
        {paginationControl}
      </div>
    </div>
  );
};

export default Table;
