import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage } from "../actions/userActions.js";
import LeftNavigation from "./left-navigation";
import Header from "./Header.jsx";
import reqHeader from "../helper/reqHeader.js";
import axios from "axios";
import TableList from "./Table/TableList";
import { format } from "date-fns";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const schema = yup.object().shape({
  projname: yup
    .string()
    .matches(
      /^[aA-zZ0-9-_\s]+$/,
      "Only alphabets, numbers, underscore and hyphen are allowed!"
    )
    //.length(30, "Length should not more than 30 character.")
    .required("Project Name is required!"),
  language: yup
    .string()
    //.matches(/^([^0-9]*)$/, "Last Name should not contain numbers !!!")
    .required("Langage field is required!"),
  priority: yup.string(),
  //.required("Email ID is required !!!")
  //.email("Enter a valid Email ID !!!"),
});

toast.configure();

const TranscriptionOrder = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  localStorage.setItem("myProjectName", "Test Jai");
  const headers = reqHeader(true, true, true);

  const userLogin = useSelector((state) => state.userLogin);
  const { id } = userLogin.user.id;
  const { loginCurrency } = userLogin.loginLocation;
  const { user } = userLogin;
  // console.log("user is", user);
  const folderPath = location.state.fp;
  // console.log("folderath..", folderPath);

  const { register, handleSubmit, watch, errors, getValues, setValue } =
    useForm({
      mode: "onBlur",
      resolver: yupResolver(schema),
    });

  const [lang, setLang] = useState("");
  const [priority, setPriority] = useState("");
  const [totalduration, setTotalduration] = useState("");
  const [ETA, setETA] = useState("48");
  const [rate, setRate] = useState(0);
  const [totalamount, setTotalAmount] = useState(0);
  const [currencyval, setCurrrencyVal] = useState(
    loginCurrency == "" || loginCurrency == undefined ? "INR" : loginCurrency
  );
  const [timestampVal, setTimestampVal] = useState("0");
  const [timestampRange, setTimestampRange] = useState("0");
  const [speakerTagVal, setSpeakerTagVal] = useState("0");

  const history = useHistory();
  const [timestamps] = useState([
    { label: "No Timestamping", value: "0" },
    { label: "5 secs", value: "5" },
    { label: "10 secs", value: "10" },
    { label: "15 secs", value: "15" },
    { label: "20 secs", value: "20" },
    { label: "25 secs", value: "25" },
    { label: "30 secs", value: "30" },
    { label: "35 secs", value: "35" },
    { label: "40 secs", value: "40" },
    { label: "45 secs", value: "45" },
    { label: "50 secs", value: "50" },
    { label: "55 secs", value: "55" },
    { label: "60 secs", value: "60" },
  ]);

  const [speakerTag] = useState([
    { label: "No Speaker Tag", value: "0" },
    { label: "1 - 3", value: "1 - 3" },
    { label: "4 - 6", value: "4 - 6" },
    { label: "7 - 9", value: "7 - 9" },
  ]);

  const [languages, setlanguages] = useState([]);
  const [toasterror, setToastError] = useState(
    "Project Name already exists. Please choose a different name."
  );

  useEffect(() => {
    // console.log("Inside the languages & duration effect ");
    async function getlanguages() {
      const url = `${process.env.REACT_APP_URL}/language/activeTranscriptionLanguages`;
      const config = { headers };
      await axios
        .get(url, config)
        .then((res) => {
          setlanguages(
            res.data.data.map(({ name }) => ({ label: name, value: name }))
          );
          //console.log("From useEffect languages");
        })
        .catch((err) => { });
    }
    getlanguages();

    const params = {
      folderPath: folderPath,
    };

    async function getTotalDuration() {
      const url = `${process.env.REACT_APP_URL}/transcription/gettotalfileduration/`;
      const config = { headers, params };
      await axios
        .get(url, config)
        .then((res) => {
          // console.log("setTotalduration", res.data.data.tottimeinminutes);
          setTotalduration(res.data.data.tottimeinminutes);
          //console.log("From useEffect Duration");
        })
        .catch((err) => { });
    }
    getTotalDuration();

    // console.log("Currency set in SetValue is ", loginCurrency);
    setValue("currency", loginCurrency);

    //setLang("Hindi");
    // setValue("language", "English African American");

    setPriority("Normal");
    // setValue("priority", "Normal");
  }, []);

  useEffect(() => {
    //console.log("Inside state useeffect");
    if (location.state.srcLang != undefined) {
      //console.log("location.state.srcLang..",location.state.srcLang);
      setValue("language", location.state.srcLang);
      setLang(location.state.srcLang);
    } else if (location.state.srcLang == undefined) {
      console.log("Inside src lang undefined");
      setLang("Hindi");
    }
    if (location.state.priority != undefined) {
      //console.log("location.state.priority..",location.state.priority);
      setValue("priority", location.state.priority);
      // setPriority(location.state.priority);
      setPriority(location.state.priority);
    }
    if (location.state.timestampVal != undefined) {
      //console.log("location.state.timestampVal..",location.state.timestampVal);
      let value = location.state.timestampVal;

      setValue("timestamp", location.state.timestampVal);
      setTimestampVal(value);

      let range = "0";

      if (value < 5) {
        range = "0";
      } else if (value >= 5 && value <= 15) {
        range = "5 - 15";
      } else if (value >= 16 && value <= 30) {
        range = "16 - 30";
      } else if (value >= 31 && value <= 60) {
        range = "31 - 60";
      }

      //console.log("value is ", value);
      //console.log("range is ", range);

      setTimestampRange(range);
    }
    if (location.state.currencyval != undefined) {
      setCurrrencyVal(location.state.currencyval);
    }
    if (location.state.speakerTagVal != undefined) {
      //console.log("location.state.speakerTagVal..",location.state.speakerTagVal);
      setValue("speakertag", location.state.speakerTagVal);
      setSpeakerTagVal(location.state.speakerTagVal);
    }
  }, []);

  useEffect(() => {
    // console.log("Inside the rates & deadline effect ");
    // console.log("Language inside rates effect is ", lang);
    // console.log("Priority inside rates effect is ", priority);

    async function getDeadline() {
      const url = `${process.env.REACT_APP_URL}/deadline/getspecificdeadline/`;

      const params = {
        type: "Transcription",
        srcLang: lang,
        priority,
        quality: "3",
      };

      const config = { headers, params };

      if (lang && priority) {
        await axios
          .get(url, config)
          .then((res) => {
            setETA(res.data.data.ETAHour);
            // console.log("From useEffect ETA is ", res.data.data.ETAHour);
          })
          .catch((err) => {
            // console.log("Error from useEffect");
          });
      }
    }

    getDeadline();

    async function getRates() {
      const url = `${process.env.REACT_APP_URL}/rates/OC/transcription`;

      // console.log("Language inside getRates is ", lang);
      // console.log("Priority inside getRates is ", priority);

      const params = {
        type: "Transcription",
        srcLang: lang,
        priority,
        quality: "3",
        timestamp: timestampRange,
        speakerTag: speakerTagVal,
      };

      const config = { headers, params };

      if (lang && priority) {
        await axios
          .get(url, config)
          .then((res) => {
            const data = res.data.data;
            //console.log("Rate data is ", data);

            const times = totalduration.split(":");
            if (currencyval == "INR") {
              if (res.data.data.INRRate) {
                let rate =
                  (res.data.data.INRRate +
                    res.data.data.INRTimeStampRate +
                    res.data.data.INRSpTagRate) *
                  res.data.data.priorityFactor *
                  res.data.data.tgtCategory *
                  res.data.data.discFactor;

                setRate(rate.toFixed(2));
                const hhRate = Number(times[0]) * 60 * rate;
                const mmRate = Number(times[1]) * rate;
                const ssRate = (Number(times[2]) / 60) * rate;
                const amount = hhRate + mmRate + ssRate;
                setTotalAmount(amount.toFixed(2));
              } else {
                setRate(0);
                setTotalAmount(0);
              }
            } else {
              if (res.data.data.USDRate) {
                let rate =
                  (res.data.data.USDRate +
                    res.data.data.USDTimeStampRate +
                    res.data.data.USDSpTagRate) *
                  res.data.data.priorityFactor *
                  res.data.data.tgtCategory *
                  res.data.data.discFactor;

                setRate(rate.toFixed(2));
                const hhRate = Number(times[0]) * 60 * rate;
                const mmRate = Number(times[1]) * rate;
                const ssRate = (Number(times[2]) / 60) * rate;
                const amount = hhRate + mmRate + ssRate;
                setTotalAmount(amount.toFixed(2));
              } else {
                setRate(0);
                setTotalAmount(0);
              }
            }
          })
          .catch((err) => {
            //console.log("Error from useEffect", err);
            toast.error(err);
          });
      }
    }

    getRates();
  }, [lang, priority, totalduration, currencyval, timestampVal, speakerTagVal]);
  // }, [lang, priority, currencyval, timestampVal, speakerTag]);

  const handleChangeInput = (e, i) => {
    const { name, value } = e.target;
    if (name == "language") {
      setLang(value);
    }
    if (name == "priority") {
      setPriority(value);
    }
    if (name == "currency") {
      setCurrrencyVal(value);
    }
    if (name == "timestamp") {
      setTimestampVal(value);

      let range = "0";

      if (value < 5) {
        range = "0";
      } else if (value >= 5 && value <= 15) {
        range = "5 - 15";
      } else if (value >= 16 && value <= 30) {
        range = "16 - 30";
      } else if (value >= 31 && value <= 60) {
        range = "31 - 60";
      }

      //console.log("value is ", value);
      //console.log("range is ", range);
      setTimestampRange(range);
    }
    if (name == "speakertag") {
      setSpeakerTagVal(value);
    }
  };

  const submitHandler = (data, e) => {
    // console.log("Data is ", data);
    // console.log("folderPath", folderPath)
    e.preventDefault();
    e.stopPropagation();
    e.persist();
    // formRef.current.dispatchEvent(new Event('submit', { cancelable: true }))

    localStorage.setItem("ProjectName", data.projname);
    if (
      (currencyval === "INR" ? totalamount >= 50 : totalamount >= 1) &&
      data.projname !== "" &&
      data.projname !== undefined &&
      data.projname != null
    ) {
      const url1 = `${process.env.REACT_APP_URL}/transcription/updatetempprojfiles/`;
      const headers = reqHeader(true, true, true);
      const body = {
        folderPath,
        customerID: id,
        projectName: data.projname,
        srcLang: data.language,
        priority: data.priority,
        timeStamping: data.timestamp,
        rate: rate,
        ETA: ETA,
        speakerTagging: data.speakertag,
        totalDuration: totalduration,
        outputformat: data.outputformat,
        currency: currencyval,
        amount: totalamount,
      };
      const config = { headers };
      axios
        .put(url1, body, config)
        .then((res) => {
          // history.push("./payment");
          //console.log(userLogin);
          if (res.data.success === false && res.data.msg) {
            toast.error(toasterror);
            //setToastError("");
          } else {
            history.push({
              pathname: "./payment",
              search: `?currency=${currencyval}&type=TS&projectName=${data.projname}&fp=${folderPath}`,
              //state: { fp: folderPath },
            });
            setToastError("");
          }
        })
        .catch((err) => {
          // console.log("Error from useEffect");
        });
    } else {
      if (
        data.projname === "" ||
        data.projname === undefined ||
        data.projname == null
      ) {
        toast.error("Project Name is required !!!");
      } else if (currencyval === "INR" ? totalamount < 50 : totalamount < 1) {
        const amt = currencyval === "INR" ? 50 : 1;
        toast.error(
          `Minimum Payable Amount should be ${currencyval} ${amt} !!!`
        );
      } else if (totalamount === 0) {
        const amt = currencyval === "INR" ? 100 : 1;
        toast.error(`Payable Amount should not be ${currencyval} ${amt} !!!`);
      }
    }
  };

  let minutesToAdd = ETA * 60;
  let currentDate = new Date();

  let futureDate = new Date(currentDate.getTime() + minutesToAdd * 60000);
  let isostr = futureDate.toISOString().split("T")[0];
  futureDate = format(new Date(isostr), "dd/MM/yyyy");
  //console.log("futureDate..", futureDate);

  // const handleProjectName = (e) => {
  //   e.preventDefault();
  //   const url = `${process.env.REACT_APP_URL}/transcription/projectnamecheck/`;

  //   const params = {
  //     projectname: e.target.value,
  //   };

  //   const config = { headers, params };
  //   axios
  //     .get(url, config)
  //     .then((res) => {
  //       if (res.data.msg) {
  //         toast.error(res.data.msg);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  return (
    <>
      <div className="container dashboard d-flex">
        <LeftNavigation />

        <div className="pageRight">
          <Header title="Transcription Order" type="Transcription" />
          <form className="form d-flex flex-col" onSubmit={handleSubmit(submitHandler)}>
            <div className="contentLeft">
              <div className="block">
                <h2>Summary</h2>
                <div className="addProjectDetails">
                  <div className="d-flex createOption">
                    <div className="inputWrap">
                      <label className="label">
                        Project Name <span className="requiredField">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Project Name"
                        name="projname"
                        ref={register}
                        //onBlur={(e) => handleProjectName(e)}
                        maxLength="30"
                      />
                      {errors.projname && (
                        <span className="inputErrorMsg">
                          {errors.projname?.message}
                        </span>
                      )}
                    </div>
                    {/* {fields.map((field, idx) => {
                      return (
                        <div key={`${field}-${idx}`} className="d-flex addSpeakerRow"> */}
                    <div className="inputWrap">
                      <label className="label">Language</label>
                      <select
                        name="language"
                        value={lang}
                        ref={register}
                        onChange={(e) => handleChangeInput(e)}>
                        {languages.map((language) => (
                          <option key={language.value} value={language.value}>
                            {language.label}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="inputWrap">
                      <label className="label">Priority</label>
                      <select
                        name="priority"
                        ref={register}
                        onChange={(e) => handleChangeInput(e)}>
                        <option defaultValue value="Normal">
                          Normal
                        </option>
                        <option value="High">High</option>
                        <option value="Urgent">Urgent</option>
                      </select>
                    </div>
                    {/* </div>
                    );
                  })} */}
                  </div>

                  <div className="d-flex createOption">
                    <div className="inputWrap">
                      <label className="label">Time Stamping</label>
                      <select
                        name="timestamp"
                        ref={register}
                        onChange={(e) => handleChangeInput(e)}>
                        {timestamps.map((timestamp) => (
                          <option key={timestamp.value} value={timestamp.value}>
                            {timestamp.label}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="inputWrap">
                      <label className="label">Speaker Tagging</label>
                      <select
                        name="speakertag"
                        ref={register}
                        onChange={(e) => handleChangeInput(e)}>
                        {speakerTag.map((speakerTag) => (
                          <option
                            key={speakerTag.value}
                            value={speakerTag.value}>
                            {speakerTag.label}
                          </option>
                        ))}
                        {/* <option defaultValue value="No">
                          No
                        </option>
                        <option value="Yes">Yes</option> */}
                      </select>
                    </div>

                    <div className="inputWrap">
                      <label className="label">Output Format</label>
                      <select name="outputformat" ref={register}>
                        <option defaultValue value="JSON">
                          JSON
                        </option>
                        <option value="Text">Text</option>
                        {/* <option value="DOCX">DOCX</option> */}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <TableList
                  src="transcriptionTempFiles"
                  pagination={true}
                  srcLang={lang}
                  priority={priority}
                  currencyval={currencyval}
                  timestampVal={timestampVal}
                  speakerTagVal={speakerTagVal}
                />
              </div>
            </div>

            <div className="contentRight">
              <div className="block orderPlace">
                <h2>Place Order</h2>
                <ul>
                  <div className="orderRow">
                    <span className="label">Total Audio Time</span>
                    <span className="value">{totalduration}</span>
                  </div>
                  <div className="orderRow">
                    <span className="label">Rate per Minutes</span>
                    <span className="value">
                      {currencyval} {rate}
                    </span>
                  </div>
                </ul>
                <div className="currencyRow">
                  <div className="label">Currency</div>
                  <select
                    name="currency"
                    ref={register}
                    onChange={(e) => handleChangeInput(e)}>
                    <option defaultValue value="INR">
                      INR
                    </option>
                    <option value="USD">USD</option>
                  </select>
                </div>

                <div className="payableRow">
                  <span className="label">Payable Amount</span>
                  {totalamount > 0 && <span className="PayableValue">
                    {currencyval} {totalamount}
                  </span>}
                </div>

                <div className="rightAlignRow border-btm">
                  <span className="value">
                    <strong>{ETA} Hours Turnaround</strong>
                  </span>
                </div>

                <div className="rightAlignRow">
                  <span className="label ETALabel">ETA:</span>
                  <span className="value">
                    <strong>{futureDate}</strong>
                  </span>
                </div>
                <button className="btn">Confirm</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default TranscriptionOrder;
