import React from "react";

export const PasswordHide = () => (
  <svg className="passwordHideIcon" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.5">
      <path d="M0.849685 0.849724C0.627793 1.07162 0.607626 1.41883 0.789168 1.66351L0.849685 1.73361L4.21183 5.09575C2.61017 6.22031 1.4131 7.89992 0.915693 9.887C0.831876 10.2218 1.03538 10.5612 1.37022 10.6451C1.70507 10.7288 2.04446 10.5254 2.12828 10.1905C2.56951 8.42792 3.65923 6.94956 5.11176 5.99543L6.61979 7.50358C6.03019 8.10483 5.66663 8.92858 5.66663 9.83717C5.66663 11.6782 7.15901 13.1705 8.99996 13.1705C9.90863 13.1705 10.7323 12.8069 11.3336 12.2173L16.2664 17.1503C16.5105 17.3943 16.9061 17.3943 17.1502 17.1503C17.3721 16.9284 17.3923 16.5812 17.2107 16.3365L17.1502 16.2664L12.0556 11.1712L12.0566 11.17L11.0565 10.1717L8.66496 7.78067L8.66663 7.78L6.2656 5.38152L6.26663 5.38L5.32223 4.43791L1.73357 0.849724C1.48949 0.605649 1.09376 0.605649 0.849685 0.849724ZM7.50338 8.38792L10.4493 11.3338C10.0743 11.697 9.56321 11.9205 8.99996 11.9205C7.84938 11.9205 6.91663 10.9877 6.91663 9.83717C6.91663 9.27392 7.14016 8.76283 7.50338 8.38792ZM8.99996 3.58333C8.16638 3.58333 7.35754 3.70672 6.59253 3.9375L7.62338 4.96766C8.06988 4.87943 8.53029 4.83333 8.99996 4.83333C12.2692 4.83333 15.0915 7.06688 15.8725 10.1943C15.9562 10.5292 16.2955 10.7328 16.6304 10.6492C16.9653 10.5656 17.169 10.2263 17.0853 9.89142C16.1661 6.2106 12.8462 3.58333 8.99996 3.58333ZM9.16221 6.50773L12.33 9.675C12.2451 7.96092 10.8726 6.5897 9.16221 6.50773Z" fill="#8F8EA4" />
    </g>
  </svg>
);

export const PasswordShow = () => (
  <svg className="passwordHideIcon" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.5">
      <path d="M10.0001 7.50385C11.8411 7.50385 13.3335 8.99625 13.3335 10.8372C13.3335 12.6782 11.8411 14.1705 10.0001 14.1705C8.15919 14.1705 6.66681 12.6782 6.66681 10.8372C6.66681 8.99625 8.15919 7.50385 10.0001 7.50385ZM10.0001 8.75383C8.84956 8.75383 7.91681 9.68658 7.91681 10.8372C7.91681 11.9878 8.84956 12.9205 10.0001 12.9205C11.1507 12.9205 12.0835 11.9878 12.0835 10.8372C12.0835 9.68658 11.1507 8.75383 10.0001 8.75383ZM10.0001 4.58334C13.8447 4.58334 17.1636 7.20834 18.0844 10.887C18.1682 11.2218 17.9647 11.5613 17.6299 11.6451C17.2951 11.7288 16.9556 11.5254 16.8718 11.1905C16.0894 8.06497 13.268 5.83334 10.0001 5.83334C6.73092 5.83334 3.90855 8.06689 3.12752 11.1943C3.04388 11.5292 2.7046 11.7328 2.36971 11.6493C2.03482 11.5656 1.83113 11.2263 1.91477 10.8914C2.83401 7.2106 6.15387 4.58334 10.0001 4.58334Z" fill="#8F8EA4" />
    </g>

  </svg>

);

export const FileFolder = () => (
  <svg className="fileFolderIcon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.6671 7.91831V7.29156C16.6671 6.25603 15.8276 5.41656 14.7921 5.41656H10.021L8.03682 3.76664C7.70008 3.48663 7.27595 3.33333 6.838 3.33333H3.54173C2.50645 3.33333 1.66709 4.1724 1.66673 5.20768L1.66342 14.791C1.66307 15.8268 2.50264 16.6667 3.53842 16.6667H3.56039C3.56381 16.6667 3.56725 16.6667 3.57068 16.6667H15.3915C16.0607 16.6667 16.6439 16.2113 16.8063 15.5622L18.2645 9.73041C18.4947 8.80999 17.7985 7.91831 16.8498 7.91831H16.6671ZM3.54173 4.58333H6.838C6.98398 4.58333 7.12535 4.63443 7.23761 4.72777L9.39543 6.52212C9.50768 6.61545 9.6491 6.66656 9.7951 6.66656H14.7921C15.1373 6.66656 15.4171 6.94638 15.4171 7.29156V7.91831H5.35363C4.49321 7.91831 3.74323 8.50391 3.53459 9.33866L2.91445 11.8197L2.91673 5.20811C2.91685 4.86302 3.19664 4.58333 3.54173 4.58333ZM4.74728 9.64174C4.81683 9.36349 5.06682 9.16833 5.35363 9.16833H16.8498C16.9853 9.16833 17.0848 9.29566 17.0518 9.42716L15.5936 15.2589C15.5704 15.3517 15.4871 15.4167 15.3915 15.4167H3.57068C3.43515 15.4167 3.3357 15.2893 3.36856 15.1578L4.74728 9.64174Z" />
  </svg>
);

export const Recording = () => (
  <svg className="recordingIcon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.5 4C3.11929 4 2 5.11929 2 6.5V9.33681C2.31085 9.18915 2.64693 9.08595 3 9.03544V6.5C3 5.67157 3.67157 5 4.5 5H11.5C12.3284 5 13 5.67157 13 6.5V13.5C13 14.3284 12.3284 15 11.5 15H11V16H11.5C12.8807 16 14 14.8807 14 13.5V12.5L16.4 14.3C17.0592 14.7944 18 14.324 18 13.5V6.49998C18 5.67594 17.0592 5.20556 16.4 5.69998L14 7.49998V6.5C14 5.11929 12.8807 4 11.5 4H4.5ZM14 8.74998L17 6.49998V13.5L14 11.25V8.74998Z" />
    <path d="M1 12.5C1 11.1193 2.11929 10 3.5 10H7.5C8.88071 10 10 11.1193 10 12.5V16.5C10 17.8807 8.88071 19 7.5 19H3.5C2.11929 19 1 17.8807 1 16.5V12.5ZM5.02008 12.534C4.94209 12.4811 4.85564 12.4546 4.7607 12.4546C4.69458 12.4546 4.63186 12.4687 4.57252 12.4969C4.51319 12.5234 4.46148 12.5596 4.41741 12.6054C4.37333 12.6513 4.33773 12.7051 4.31061 12.7668C4.28518 12.8286 4.27246 12.8938 4.27246 12.9626V16.0374C4.27246 16.1045 4.28518 16.1689 4.31061 16.2306C4.33773 16.2924 4.37333 16.347 4.41741 16.3947C4.46318 16.4405 4.51573 16.4776 4.57507 16.5058C4.6344 16.5323 4.69628 16.5455 4.7607 16.5455C4.80986 16.5455 4.85564 16.5384 4.89802 16.5243C4.94209 16.5102 4.98532 16.489 5.02771 16.4608L7.32395 14.8943C7.39346 14.8485 7.44771 14.7867 7.4867 14.7091C7.52569 14.6315 7.54519 14.5503 7.54519 14.4656C7.54519 14.3774 7.52485 14.2963 7.48416 14.2222C7.44347 14.1463 7.38753 14.0855 7.31633 14.0396L5.02008 12.534Z" />
  </svg>
);

export const Transcription = () => (
  <svg className="transcriptionIcon" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.1035 2.63477H3.89648C1.74791 2.63477 0 4.38268 0 6.53125V12.494C0 14.6424 1.74791 16.3905 3.89648 16.3905H15.1035C17.2521 16.3905 19 14.6424 19 12.494V6.53125C19 4.38268 17.2521 2.63477 15.1035 2.63477ZM17.8867 12.494C17.8867 14.0285 16.6382 15.2772 15.1035 15.2772H3.89648C2.36181 15.2772 1.11328 14.0285 1.11328 12.494V6.53125C1.11328 4.99657 2.36181 3.74805 3.89648 3.74805H15.1035C16.6382 3.74805 17.8867 4.99657 17.8867 6.53125V12.494Z" />
    <path d="M14.8365 6.9883H7.52176C7.2143 6.9883 6.96512 7.23749 6.96512 7.54494C6.96512 7.8524 7.2143 8.10159 7.52176 8.10159H14.8365C15.144 8.10159 15.3932 7.8524 15.3932 7.54494C15.3932 7.23749 15.144 6.9883 14.8365 6.9883Z" />
    <path d="M4.16351 8.10159H5.86025C6.16771 8.10159 6.41689 7.8524 6.41689 7.54494C6.41689 7.23749 6.16771 6.9883 5.86025 6.9883H4.16351C3.85606 6.9883 3.60687 7.23749 3.60687 7.54494C3.60687 7.8524 3.85606 8.10159 4.16351 8.10159Z" />
    <path d="M4.16351 10.0693H11.4783C11.7857 10.0693 12.0349 9.81992 12.0349 9.51261C12.0349 9.20515 11.7857 8.95597 11.4783 8.95597H4.16351C3.85606 8.95597 3.60687 9.20515 3.60687 9.51261C3.60687 9.81992 3.85606 10.0693 4.16351 10.0693Z" />
    <path d="M14.8365 8.95597H13.1398C12.8323 8.95597 12.5831 9.20515 12.5831 9.51261C12.5831 9.81992 12.8323 10.0693 13.1398 10.0693H14.8365C15.144 10.0693 15.3932 9.81992 15.3932 9.51261C15.3932 9.20515 15.144 8.95597 14.8365 8.95597Z" />
    <path d="M14.8365 10.9235H7.52176C7.2143 10.9235 6.96512 11.1728 6.96512 11.4801C6.96512 11.7876 7.2143 12.0368 7.52176 12.0368H14.8365C15.144 12.0368 15.3932 11.7876 15.3932 11.4801C15.3932 11.1728 15.144 10.9235 14.8365 10.9235Z" />
    <path d="M5.86025 10.9235H4.16351C3.85606 10.9235 3.60687 11.1728 3.60687 11.4801C3.60687 11.7876 3.85606 12.0368 4.16351 12.0368H5.86025C6.16771 12.0368 6.41689 11.7876 6.41689 11.4801C6.41689 11.1728 6.16771 10.9235 5.86025 10.9235Z" />
  </svg>
);

export const Translation = () => (
  <svg className="translationIcon" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.33 4.4902H9.1676L7.80165 0.381036C7.72602 0.15352 7.5132 0 7.27344 0H1.66997C0.749182 0 6.10352e-05 0.749121 6.10352e-05 1.66991V12.8397C6.10352e-05 13.7605 0.749182 14.5097 1.66997 14.5097H7.80399L9.15881 18.6079C9.23365 18.8479 9.45564 19 9.69054 19C9.69184 19 9.69314 18.9999 9.69444 18.9999H17.33C18.2508 18.9999 18.9999 18.2507 18.9999 17.3299V6.16011C18.9999 5.23932 18.2508 4.4902 17.33 4.4902ZM1.66997 13.3964C1.36304 13.3964 1.11333 13.1467 1.11333 12.8397V1.66991C1.11333 1.36298 1.36304 1.11327 1.66997 1.11327H6.87188L10.9549 13.3964C8.86965 13.3964 3.72886 13.3964 1.66997 13.3964ZM10.863 14.5097L9.77196 16.9158L8.97653 14.5097H10.863ZM17.8866 17.3299C17.8866 17.6369 17.6369 17.8866 17.33 17.8866H10.5542L12.2335 14.1829C12.2937 14.0501 12.2965 13.9029 12.2547 13.7772L9.53765 5.60347H17.33C17.6369 5.60347 17.8866 5.85318 17.8866 6.16011V17.3299Z" />
    <path d="M7.27346 6.71677H5.60355C5.29614 6.71677 5.04691 6.966 5.04691 7.27341C5.04691 7.58082 5.29614 7.83004 5.60355 7.83004H6.62108C6.3913 8.47793 5.77258 8.94331 5.04691 8.94331C4.12613 8.94331 3.37701 8.19419 3.37701 7.27341C3.37701 6.35262 4.12613 5.6035 5.04691 5.6035C5.49297 5.6035 5.9123 5.77721 6.22773 6.0926C6.44507 6.30999 6.79753 6.30999 7.01492 6.0926C7.23227 5.87522 7.23227 5.52279 7.01492 5.30541C6.48923 4.77972 5.79032 4.49023 5.04691 4.49023C3.51227 4.49023 2.26373 5.73877 2.26373 7.27341C2.26373 8.80805 3.51227 10.0566 5.04691 10.0566C6.58156 10.0566 7.83009 8.80805 7.83009 7.27341C7.83009 6.966 7.58087 6.71677 7.27346 6.71677Z" />
    <path d="M16.1797 8.94329H14.5097V8.38665C14.5097 8.07924 14.2605 7.83002 13.9531 7.83002C13.6457 7.83002 13.3965 8.07924 13.3965 8.38665V8.94329H11.7266C11.4191 8.94329 11.1699 9.19251 11.1699 9.49993C11.1699 9.80734 11.4191 10.0566 11.7266 10.0566H14.9602C14.7832 10.583 14.4028 11.2068 13.9606 11.8071C13.8653 11.6785 13.7712 11.5469 13.6805 11.4135C13.5076 11.1592 13.1614 11.0933 12.9072 11.2662C12.653 11.439 12.5871 11.7853 12.7599 12.0395C12.9161 12.2692 13.0798 12.4926 13.243 12.7044C12.9648 13.0271 12.6906 13.3193 12.45 13.5557C12.2306 13.771 12.2272 14.1235 12.4425 14.3429C12.657 14.5614 13.0093 14.5665 13.2297 14.3503C13.2489 14.3315 13.5525 14.0325 13.9579 13.5734C14.3536 14.0261 14.6498 14.3237 14.6728 14.3466C14.8901 14.5639 15.2424 14.564 15.4598 14.3467C15.6772 14.1294 15.6773 13.777 15.4601 13.5595C15.4542 13.5537 15.1135 13.211 14.6786 12.7039C15.4665 11.6853 15.9469 10.798 16.1133 10.0566H16.1796C16.487 10.0566 16.7363 9.80734 16.7363 9.49993C16.7363 9.19251 16.4871 8.94329 16.1797 8.94329Z" />
  </svg>

);

export const Subtitling = () => (
  <svg className="subtitlingIcon" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.16016 12.433C7.69481 12.433 8.94336 11.1845 8.94336 9.64981H7.83008C7.83008 10.5706 7.08095 11.3197 6.16016 11.3197C5.23936 11.3197 4.49023 10.5706 4.49023 9.64981V6.30997C4.49023 5.38917 5.23936 4.64005 6.16016 4.64005C7.08095 4.64005 7.83008 5.38917 7.83008 6.30997H8.94336C8.94336 4.77531 7.69481 3.52676 6.16016 3.52676C4.6255 3.52676 3.37695 4.77531 3.37695 6.30997V9.64981C3.37695 11.1845 4.6255 12.433 6.16016 12.433Z" />
    <path d="M12.8398 12.433C14.3745 12.433 15.623 11.1845 15.623 9.64981H14.5098C14.5098 10.5706 13.7606 11.3197 12.8398 11.3197C11.919 11.3197 11.1699 10.5706 11.1699 9.64981V6.30997C11.1699 5.38917 11.919 4.64005 12.8398 4.64005C13.7606 4.64005 14.5098 5.38917 14.5098 6.30997H15.623C15.623 4.77531 14.3745 3.52676 12.8398 3.52676C11.3052 3.52676 10.0566 4.77531 10.0566 6.30997V9.64981C10.0566 11.1845 11.3052 12.433 12.8398 12.433Z" />
    <path d="M0 1.3002V14.6596H3.37695V17.6998L6.39209 14.6596H19V1.3002H0ZM17.8867 13.5463H5.92822L4.49023 14.9963V13.5463H1.11328V2.41348H17.8867V13.5463Z" />
  </svg>
);

export const SignOut = () => (
  <svg className="signoutIcon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.50215 11.5C9.05562 11.5 9.5043 11.9487 9.5043 12.5022C9.5043 13.0556 9.05562 13.5043 8.50215 13.5043C7.94868 13.5043 7.5 13.0556 7.5 12.5022C7.5 11.9487 7.94868 11.5 8.50215 11.5Z" />
    <path d="M12 4.35418V10.5L12.0005 11.005L19.442 11.004L17.7196 9.28026C17.4534 9.01395 17.4292 8.59728 17.6471 8.3037L17.7198 8.2196C17.9861 7.95338 18.4027 7.92924 18.6963 8.14715L18.7804 8.21978L21.777 11.2174C22.043 11.4835 22.0674 11.8997 21.85 12.1933L21.7775 12.2774L18.7809 15.2808C18.4884 15.5741 18.0135 15.5746 17.7203 15.282C17.4537 15.0161 17.429 14.5994 17.6465 14.3056L17.7191 14.2214L19.432 12.504L12.0005 12.505L12 19.25C12 19.7164 11.5788 20.0697 11.1196 19.9886L2.61955 18.4873C2.26121 18.424 2 18.1126 2 17.7487V5.75002C2 5.38271 2.26601 5.06945 2.62847 5.00993L11.1285 3.6141C11.5851 3.53911 12 3.89145 12 4.35418ZM10.5 5.23739L3.5 6.3869V17.1196L10.5 18.3559V5.23739Z" />
    <path d="M13 18.5013L13.7652 18.5015L13.867 18.4946C14.2335 18.4448 14.5158 18.1304 14.5152 17.7502L14.508 13.5H13V18.5013Z" />
    <path d="M13.002 9.99999L13 8.72535V5L13.7453 5.00001C14.1245 5.00001 14.4381 5.28153 14.4883 5.64712L14.4953 5.74877L14.502 9.99999H13.002Z" />
  </svg>
);

export const ContactUs = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.83725 1.5625C3.78245 1.5625 1.82427 2.97092 1.89132 5.20233C1.94773 7.07963 2.39501 9.59133 3.92398 12.2396C5.44117 14.8674 7.3665 16.5932 8.94392 17.6725C10.816 18.9535 13.1134 18.0634 14.2121 16.2655C14.6915 15.4811 14.6198 14.4788 14.0336 13.7706L12.9341 12.442C12.405 11.8028 11.5599 11.5179 10.7518 11.7064L9.32613 12.0391C9.00613 12.1138 8.73638 12.0428 8.57675 11.882C7.87067 11.1704 7.06263 9.93342 6.71942 8.97229C6.63834 8.74529 6.72288 8.44367 7.01892 8.19696L7.98717 7.39013C8.68813 6.806 8.95288 5.84817 8.65146 4.98696L7.96554 3.02733C7.65846 2.14997 6.83042 1.5625 5.90088 1.5625H5.83725ZM2.93252 5.17104C2.88822 3.69676 4.18471 2.60416 5.83725 2.60417H5.90088C6.38779 2.60417 6.8215 2.91189 6.98238 3.37146L7.66825 5.33108C7.82617 5.78217 7.68746 6.28392 7.32029 6.58988L6.35209 7.39671C5.83804 7.82508 5.46483 8.55654 5.73842 9.32258C6.13725 10.4396 7.03284 11.8049 7.83729 12.6157C8.31925 13.1014 8.99579 13.1858 9.56284 13.0535L10.9885 12.7208C11.4118 12.6221 11.8545 12.7713 12.1316 13.1061L13.2312 14.4347C13.5336 14.8003 13.5706 15.3175 13.3233 15.7223C12.4481 17.1543 10.784 17.6694 9.53217 16.8129C8.05488 15.802 6.24971 14.1845 4.82608 11.7188C3.39877 9.24654 2.98478 6.91013 2.93252 5.17104Z" />
    <path d="M10.9858 8.27749C10.7824 8.48091 10.7824 8.8107 10.9858 9.01407C11.1892 9.21749 11.519 9.21749 11.7224 9.01407L17.2916 3.44486V7.81245C17.2916 8.10011 17.5248 8.33328 17.8124 8.33328C18.1001 8.33328 18.3333 8.10011 18.3333 7.81245V2.18746C18.3333 1.89981 18.1001 1.66663 17.8124 1.66663H12.1874C11.8998 1.66663 11.6666 1.89981 11.6666 2.18746C11.6666 2.47511 11.8998 2.70829 12.1874 2.70829H16.555L10.9858 8.27749Z" />
  </svg>

);

export const PlusIcon = () => (
  <svg className="plusIcon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10ZM10 6.25C9.65481 6.25 9.375 6.52981 9.375 6.875V9.375H6.875C6.52981 9.375 6.25 9.65481 6.25 10C6.25 10.3452 6.52981 10.625 6.875 10.625H9.375V13.125C9.375 13.4702 9.65481 13.75 10 13.75C10.3452 13.75 10.625 13.4702 10.625 13.125V10.625H13.125C13.4702 10.625 13.75 10.3452 13.75 10C13.75 9.65481 13.4702 9.375 13.125 9.375H10.625V6.875C10.625 6.52981 10.3452 6.25 10 6.25Z" fill="white" />
  </svg>
);

export const SearchIcon = () => (
  <svg className="SearchIcon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.57139 1.14286C9.56951 1.14286 12 3.57331 12 6.57143C12 7.76108 11.6173 8.86137 10.9683 9.75594L14.6061 13.3939C14.9408 13.7286 14.9408 14.2714 14.6061 14.6061C14.2923 14.9199 13.7956 14.9395 13.459 14.6649L13.3939 14.6061L9.75591 10.9683C8.86134 11.6173 7.76105 12 6.57139 12C3.57327 12 1.14282 9.56954 1.14282 6.57143C1.14282 3.57331 3.57327 1.14286 6.57139 1.14286ZM6.57139 2.85714C4.52005 2.85714 2.85711 4.52009 2.85711 6.57143C2.85711 8.6228 4.52005 10.2857 6.57139 10.2857C8.62277 10.2857 10.2857 8.6228 10.2857 6.57143C10.2857 4.52009 8.62277 2.85714 6.57139 2.85714Z" />
  </svg>
);

export const DownloadArrow = () => (
  <svg className="downloadArrowIcon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.08333 12.8333H11.9167C12.2388 12.8333 12.5 13.0945 12.5 13.4167C12.5 13.7227 12.2643 13.9737 11.9645 13.9981L11.9167 14H4.08333C3.76117 14 3.5 13.7388 3.5 13.4167C3.5 13.1106 3.7357 12.8596 4.0355 12.8353L4.08333 12.8333H11.9167H4.08333ZM7.86883 2.08526L7.91667 2.08333C8.22273 2.08333 8.47373 2.31903 8.49807 2.61882L8.5 2.66666V9.77766L10.0977 8.18023C10.3255 7.95243 10.6949 7.95243 10.9227 8.18023C11.1505 8.40806 11.1505 8.7774 10.9227 9.0052L8.32993 11.5979C8.10213 11.8257 7.7328 11.8257 7.505 11.5979L4.91227 9.0052C4.68447 8.7774 4.68447 8.40806 4.91227 8.18023C5.14007 7.95243 5.5094 7.95243 5.73723 8.18023L7.33333 9.77633V2.66666C7.33333 2.3606 7.56903 2.1096 7.86883 2.08526L7.91667 2.08333L7.86883 2.08526Z" />
  </svg>
);

export const Cross = () => (
  <svg className="crossIcon" width="83" height="83" viewBox="0 0 83 83" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M58.0842 66.0684L66.0776 58.075L25.2952 17.2925L17.3018 25.2859L58.0842 66.0684Z" />
    <path d="M25.2962 66.0786L66.0786 25.2962L58.0852 17.3028L17.3028 58.0853L25.2962 66.0786Z" />
  </svg>
);

export const Pencil = () => (
  <svg className="pencilIcon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.6569 2.34311C14.4379 3.12416 14.4379 4.39049 13.6569 5.17155L6.27045 12.558C5.95005 12.8784 5.5486 13.1057 5.109 13.2156L2.81801 13.7884C2.45182 13.8799 2.12012 13.5482 2.21167 13.182L2.78442 10.891C2.89432 10.4514 3.12161 10.05 3.44201 9.72955L10.8284 2.34311C11.6095 1.56206 12.8758 1.56206 13.6569 2.34311ZM10.1213 4.46433L4.14912 10.4367C3.95688 10.6289 3.8205 10.8698 3.75456 11.1336L3.38393 12.6161L4.86647 12.2455C5.1302 12.1795 5.3711 12.0432 5.56335 11.8509L11.5353 5.8783L10.1213 4.46433ZM11.5356 3.05022L10.8283 3.75733L12.2423 5.1713L12.9498 4.46443C13.3403 4.07391 13.3403 3.44074 12.9498 3.05022C12.5592 2.6597 11.9261 2.6597 11.5356 3.05022Z" />
  </svg>
);

export const SortIconUP = () => (
  <svg className="sortIconUp" width="7" height="9" viewBox="0 0 7 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.07284 8.99784L3.02997 9C2.81283 9 2.63425 8.83498 2.61277 8.62351L2.61059 8.58063V1.43136L0.716132 3.32548C0.552375 3.48927 0.286827 3.48927 0.12307 3.32548C-0.0407205 3.16173 -0.0407205 2.89618 0.12307 2.73239L2.73261 0.122843C2.89641 -0.0409479 3.16192 -0.0409479 3.32571 0.122843L5.93529 2.73239C6.09904 2.89618 6.09904 3.16173 5.93529 3.32548C5.7715 3.48927 5.50598 3.48927 5.34219 3.32548L3.44934 1.4327V8.58063C3.44934 8.79776 3.28431 8.97636 3.07284 8.99784Z" fill="white" />
  </svg>

);

export const SortIcon = () => (

  <svg className="sortIcon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.47993 6.14473L4.47644 3.14806C4.65381 2.97066 4.93134 2.9544 5.12708 3.09933L5.18317 3.14767L8.18643 6.14433C8.3819 6.3394 8.38223 6.656 8.18716 6.85147C8.00983 7.02913 7.7321 7.0456 7.53623 6.9006L7.4801 6.8522L5.3315 4.70767L5.33194 12.5049C5.33194 12.758 5.14384 12.9672 4.89978 13.0003L4.83194 13.0049C4.57881 13.0049 4.36961 12.8168 4.3365 12.5727L4.33194 12.5049L4.3315 4.70633L2.18706 6.8518C2.00956 7.02933 1.73178 7.04547 1.53604 6.90027L1.47996 6.85186C1.30244 6.67433 1.28629 6.3966 1.43152 6.2008L1.47993 6.14473L4.47644 3.14806L1.47993 6.14473ZM11.0996 2.99953L11.1674 2.995C11.4206 2.995 11.6298 3.18306 11.6628 3.42713L11.6674 3.495L11.6668 11.2923L13.8131 9.14487C13.9906 8.96727 14.2684 8.95107 14.4642 9.0962L14.5202 9.1446C14.6978 9.322 14.7141 9.59979 14.5689 9.79559L14.5206 9.85169L11.523 12.8517C11.3456 13.0293 11.0678 13.0455 10.872 12.9004L10.816 12.852L7.81343 9.85199C7.6181 9.65681 7.61796 9.34023 7.8131 9.14487C7.99056 8.96727 8.26836 8.95107 8.46416 9.0962L8.52023 9.1446L10.6668 11.2897L10.6674 3.495C10.6674 3.24187 10.8555 3.03267 11.0996 2.99953L11.1674 2.995L11.0996 2.99953Z" fill="white" />
  </svg>
);

export const Spinner = () => (

  <svg className="spinnerIcon" width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path d="M30 11.129C28.3548 11.129 27.0968 9.87097 27.0968 8.22581V2.90323C27.0968 1.25806 28.3548 0 30 0C31.6452 0 32.9032 1.25806 32.9032 2.90323V8.22581C32.9032 9.77419 31.6452 11.129 30 11.129Z" fill="#76FFE5" />
      <path d="M30 60C28.3548 60 27.0968 58.7419 27.0968 57.0968V51.7742C27.0968 50.129 28.3548 48.871 30 48.871C31.6452 48.871 32.9032 50.129 32.9032 51.7742V57.0968C32.9032 58.7419 31.6452 60 30 60Z" fill="#0DBFBA" />
      <path d="M19.0645 14.0323C18.0968 14.0323 17.129 13.5484 16.5484 12.5807L13.9355 8.03228C13.1613 6.67744 13.6452 4.83873 15 4.06454C16.3548 3.29035 18.1936 3.77422 18.9678 5.12906L21.5807 9.67744C22.3548 11.0323 21.871 12.871 20.5161 13.6452C20.129 13.8387 19.5484 14.0323 19.0645 14.0323Z" fill="#BBFFF2" />
      <path d="M43.5484 56.3226C42.5806 56.3226 41.6129 55.8387 41.0322 54.871L38.4193 50.3226C37.6452 48.9678 38.129 47.129 39.4839 46.3549C40.8387 45.5807 42.6774 46.0645 43.4516 47.4194L46.0645 51.9678C46.8387 53.3226 46.3548 55.1613 45 55.9355C44.5161 56.2258 44.0322 56.3226 43.5484 56.3226Z" fill="#1BCEB8" />
      <path d="M11.129 21.9677C10.6451 21.9677 10.1612 21.871 9.67736 21.5806L5.12898 18.9677C3.77414 18.1935 3.29027 16.3548 4.06446 15C4.83865 13.6452 6.67736 13.1613 8.0322 13.9355L12.5806 16.5484C13.9354 17.3226 14.4193 19.1613 13.6451 20.5161C13.0645 21.4839 12.0967 21.9677 11.129 21.9677Z" fill="#E1FFF9" />
      <path d="M53.5161 46.4515C53.0322 46.4515 52.5484 46.3548 52.0645 46.0644L47.4194 43.4516C46.0645 42.6774 45.5807 40.8387 46.3549 39.4839C47.1291 38.129 48.9678 37.6452 50.3226 38.4193L54.8709 41.0322C56.2258 41.8064 56.7096 43.6451 55.9355 44.9999C55.4516 45.9677 54.4838 46.4515 53.5161 46.4515Z" fill="#26DBC0" />
      <path d="M8.22581 32.9032H2.90323C1.25806 32.9032 0 31.6452 0 30C0 28.3548 1.25806 27.0968 2.90323 27.0968H8.22581C9.87097 27.0968 11.129 28.3548 11.129 30C11.129 31.6452 9.77419 32.9032 8.22581 32.9032Z" fill="#F3FFFD" />
      <path d="M57.0967 32.9032H51.7742C50.129 32.9032 48.8709 31.6452 48.8709 30C48.8709 28.3548 50.129 27.0968 51.7742 27.0968H57.0967C58.7419 27.0968 60 28.3548 60 30C60 31.6452 58.7419 32.9032 57.0967 32.9032Z" fill="#2EE5C6" />
      <path d="M6.48391 46.4515C5.51617 46.4515 4.54843 45.9677 3.96778 44.9999C3.19359 43.6451 3.67746 41.8064 5.0323 41.0322L9.58068 38.4193C10.9355 37.6452 12.7742 38.129 13.5484 39.4839C14.3226 40.8387 13.8387 42.6774 12.4839 43.4516L7.93552 46.0644C7.54843 46.3548 7.06455 46.4515 6.48391 46.4515Z" fill="#11AEBA" />
      <path d="M48.871 21.9677C47.9032 21.9677 46.9355 21.4839 46.3549 20.5161C45.5807 19.1613 46.0645 17.3226 47.4194 16.5484L51.9677 13.9355C53.3226 13.1613 55.1613 13.6452 55.9355 15C56.7096 16.3548 56.2258 18.1935 54.8709 18.9677L50.3226 21.5806C49.8387 21.871 49.3548 21.9677 48.871 21.9677Z" fill="#3BEDCB" />
      <path d="M16.4516 56.3226C15.9678 56.3226 15.4839 56.2258 15 55.9355C13.6452 55.1613 13.1613 53.3226 13.9355 51.9678L16.5484 47.4194C17.3226 46.0645 19.1613 45.5807 20.5161 46.3549C21.871 47.129 22.3548 48.9678 21.5807 50.3226L18.9678 54.871C18.3871 55.8387 17.4194 56.3226 16.4516 56.3226Z" fill="#0FB8BC" />
      <path d="M40.9355 14.0323C40.4516 14.0323 39.9677 13.9355 39.4839 13.6452C38.129 12.871 37.6452 11.0323 38.4193 9.67744L41.0322 5.12906C41.8064 3.77422 43.6451 3.29035 45 4.06454C46.3548 4.83873 46.8387 6.67744 46.0645 8.03228L43.4516 12.5807C42.8709 13.4516 41.9032 14.0323 40.9355 14.0323Z" fill="#57F7D8" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="60" height="60" fill="white" />
      </clipPath>
    </defs>
  </svg>


);

export const CircleArrowRight = () => (
  <svg className="circleArrowRightIcon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.00063 1.33398C11.6826 1.33398 14.6673 4.31875 14.6673 8.00066C14.6673 11.6825 11.6826 14.6673 8.00063 14.6673C4.31875 14.6673 1.33398 11.6825 1.33398 8.00066C1.33398 4.31875 4.31875 1.33398 8.00063 1.33398ZM8.00063 2.33398C4.87104 2.33398 2.33398 4.87104 2.33398 8.00066C2.33398 11.1303 4.87104 13.6673 8.00063 13.6673C11.1303 13.6673 13.6673 11.1303 13.6673 8.00066C13.6673 4.87104 11.1303 2.33398 8.00063 2.33398ZM7.76536 5.03604L7.81376 4.97996C7.9913 4.80245 8.26903 4.78631 8.46476 4.93154L8.5209 4.97996L11.1881 7.6472C11.3656 7.82473 11.3818 8.10253 11.2364 8.29826L11.188 8.35433L8.52036 11.021C8.32503 11.2163 8.00843 11.2162 7.81323 11.0209C7.63576 10.8433 7.6197 10.5655 7.76496 10.3699L7.81336 10.3138L9.62796 8.5L5.16685 8.5002C4.91372 8.5002 4.70452 8.31206 4.67142 8.068L4.66685 8.0002C4.66685 7.74706 4.85495 7.53786 5.099 7.50473L5.16685 7.5002L9.62663 7.5L7.81376 5.68706C7.63623 5.50956 7.6201 5.23178 7.76536 5.03604L7.81376 4.97996L7.76536 5.03604Z" fill="#34C6C3" />
  </svg>
);

export const RadioBtn = () => (
  <svg className="radioIcon" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="11" cy="11" r="10.5" fill="white" stroke="#D2D7E0" />
  </svg>
);

export const RadioSelected = () => (
  <svg className="radioSelectedIcon" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="11" cy="11" r="10.5" fill="white" stroke="#D2D7E0" />
    <circle cx="11" cy="11" r="7" fill="#34C6C3" />
  </svg>
);

export const DeleteBtn = () => (
  <svg className="deleteBtn" xmlns="http://www.w3.org/2000/svg" fill="#fa314a" viewBox="0 0 24 24" width="16px" height="16px">
    <path d="M 10.806641 2 C 10.289641 2 9.7956875 2.2043125 9.4296875 2.5703125 L 9 3 L 4 3 A 1.0001 1.0001 0 1 0 4 5 L 20 5 A 1.0001 1.0001 0 1 0 20 3 L 15 3 L 14.570312 2.5703125 C 14.205312 2.2043125 13.710359 2 13.193359 2 L 10.806641 2 z M 4.3652344 7 L 5.8925781 20.263672 C 6.0245781 21.253672 6.877 22 7.875 22 L 16.123047 22 C 17.121047 22 17.974422 21.254859 18.107422 20.255859 L 19.634766 7 L 4.3652344 7 z" />
  </svg>
)

export const Faqs = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" width="20px" height="20px">
    <path stroke-linecap="round" stroke-linejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z" />
  </svg>

)