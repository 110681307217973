import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import useResponsiveFontSize from "./useResponsiveFontSize";
import { paymentCheckout } from "../actions/paymentAction";
import { updateUserBillingAddr } from "../actions/userActions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import reqHeader from "../helper/reqHeader.js";

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  );

  return options;
};

toast.configure();

const CheckoutForm = ({
  address,
  currency,
  totalamount,
  type,
  prjt,
  fp,
  user,
  loginLocation,
  token,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const [clientSecret, setClientSecret] = useState("");
  const [processing, setProcessing] = useState(false);
  const userPaymentInfo = useSelector((state) => state.userPayment);

  const dispatch = useDispatch();
  const uid = uuidv4();

  useEffect(() => {
    const fetchData = async () => {
      if (userPaymentInfo.success) return;

      const headers = reqHeader(true, true, true);
      const config = { headers };
      console.log("currency", currency, "totalamount", totalamount);

      const url = `${process.env.REACT_APP_URL}/payment/create-payment-intent`;
      try {
        if (currency && totalamount > 0) {
          const body = { amount: totalamount, currency: currency };
          const { data } = await axios.post(url, body, config);
          console.log(data.clientSecret, "Client Secret");
          setClientSecret(data.clientSecret);
        }
      } catch (error) {
        console.error("Error fetching payment intent:", error);
        toast.error("Failed to create payment intent. Please try again.");
      }
    };
    fetchData();
  }, [currency, totalamount, userPaymentInfo.success]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) return;

    if (
      !address.firstName ||
      !address.lastName ||
      !address.email ||
      !address.address1 ||
      !address.city ||
      !address.state ||
      !address.zipcode
    ) {
      toast.error("All fields marked with an asterisk (*) are required");
      return;
    }

    setProcessing(true);
    try {
      const result = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: {
            type: "card",
            card: elements.getElement(CardNumberElement),
            billing_details: {
              name: `${address.firstName} ${address.lastName}`,
              address: {
                line1: address.address1,
                postal_code: address.zipcode,
                city: address.city,
                state: address.state,
                country: address.country,
              },
            },
          },
        },
        { idempotencyKey: uid }
      );

      if (result.error) {
        console.error("Payment error:", result.error);
        toast.error(result.error.message);
      } else {
        const { paymentIntent } = result;
        if (paymentIntent.status === "succeeded") {
          dispatch(updateUserBillingAddr(address, user, loginLocation, token));
          dispatch(paymentCheckout(paymentIntent.id, paymentIntent.amount, address, type, prjt, fp));
        }
      }
    } catch (error) {
      console.error("Payment processing error:", error);
      toast.error("Payment failed. Please try again.");
    } finally {
      setProcessing(false);
    }
  };

  return (
    <div>
      <div className="inputBlock">
        <label className="label">
          Card number <span className="requiredField">*</span>
        </label>
        <CardNumberElement options={options} />
      </div>
      <div className="d-flex expiryCVV">
        <div className="inputBlock expiry">
          <label className="label">
            Expiry <span className="requiredField">*</span>
          </label>
          <CardExpiryElement options={options} />
        </div>

        <div className="inputBlock">
          <label className="label">
            CVV/CVC <span className="requiredField">*</span>
          </label>
          <CardCvcElement options={options} />
        </div>
      </div>

      <button
        className="btn"
        type="submit"
        onClick={handleSubmit}
        disabled={!stripe || processing}
      >
        Pay
      </button>
    </div>
  );
};

export default CheckoutForm;
