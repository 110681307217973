import axios from "axios";
import {
  USER_PAYMENT_REQUEST,
  USER_PAYMENT_SUCCESS,
  USER_PAYMENT_FAIL,
  USER_PAYMENT_RESET,
} from "../constants/paymentConstants.js";
import reqHeader from "../helper/reqHeader";
import { UPLOAD_RESET } from "../constants/uploadConstants.js";

export const paymentCheckout =
  (stripeid, amount, address, type, prjt, fp) => async (dispatch) => {
    try {
      dispatch({ type: USER_PAYMENT_REQUEST });

      // let url = `https://api.exchangeratesapi.io/latest?base=${address.currency}`;
      let url = "";

      let exchRate = 1;


      const headers = reqHeader(true, true, true);

      if (type === "TS") {
        url = `${process.env.REACT_APP_URL}/payment/transcription/`;
      } else if (type === "TL") {
        url = `${process.env.REACT_APP_URL}/payment/translation/`;
      } else if (type === "ST") {
        url = `${process.env.REACT_APP_URL}/payment/subtitling/`;
      }

      //Transcription Invoice Payment
      let body = {
        stripeid,
        amount,
        address,
        exchRate,
        type,
        prjt,
        fp,
      };

      const config = {
        headers,
      };

      let { data } = await axios.post(url, body, config);

      const { orders } = data.data;

      url = `${process.env.REACT_APP_URL}/upload/moveFiles/`;

      body = {
        orders,
        type,
      };

      await axios.post(url, body, config);

      dispatch({
        type: USER_PAYMENT_SUCCESS,
        payload: data,
      });

      dispatch({ type: UPLOAD_RESET });
    } catch (error) {
      dispatch({
        type: USER_PAYMENT_FAIL,
        payload: error.response.data,
      });
    }
  };

export const resetPayment = () => async (dispatch) => {
  try {
    dispatch({ type: USER_PAYMENT_RESET });
  } catch (error) {
    dispatch({
      type: USER_PAYMENT_FAIL,
      payload: error,
    });
  }
};
