import React, { useState } from 'react'
import LeftNavigation from "./left-navigation";
import Header from "./Header";
import Accordion from './Accordion';
import { accordionData } from "../utils/content"

const Faqs = () => {


    return (
        <>
            <div className="container recordingPage d-flex">
                <LeftNavigation type="Faqs" />

                <div className="pageRight faqspage">
                    <Header title="FAQs" type="Faqs" />

                    <h1>Frequently  Asked Questions </h1>
                    <div className="accordion">
                        {accordionData.map(({ title, content }) => (
                            <Accordion title={title} content={content} />
                        ))}
                    </div>


                </div>
            </div>
        </>
    )
}

export default Faqs
