export const accordionData = [
  {
    title: 'How to contact Megdap for issues related to payment or project submission ',
    content: `To report issues or provide feedback, please use the "Contact Us" feature available in the portal to send us an email. `
  },
  {
    title: 'What types of files are supported for Transcription and Translation',
    content: `For information on supported file formats for transcription or translation, please refer to the Supported file formats and languages  link provided on the file upload screen. `
  },
  {
    title: 'What Languages are supported',
    content: `For information on supported languages for transcription or translation, please refer to the link provided on the file upload screen.`
  },
  {
    title: 'What happens if the Languages selected while creating the project are not matching with the languages of the files uploaded',
    content: `If the languages selected when creating the project do not match the languages of the uploaded files, the system may produce inaccurate results, encounter processing errors, or experience delays. Please ensure that the language settings align with the file languages to avoid these issues.`
  },
  {
    title: 'What to do if you have specific requirements that are not covered by the available options on screen, such as needing additional tags on transcriptions, please:',
    content: `Use the "Contact Us" feature in the portal to provide detailed information about your needs. `
  }
];