import axios from "axios";
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_LOGOUT,
  CHANGEPASSWORD_REQUEST,
  CHANGEPASSWORD_SUCCESS,
  CHANGEPASSWORD_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  FORGOTPASSWORD_EMAIL_SUCCESS,
  FORGOTPASSWORD_EMAIL_FAIL,
  RESETPASSWORD_EMAIL_SUCCESS,
  RESETPASSWORD_EMAIL_FAIL,
  USER_MSG_UPDATE_REQUEST,
  USER_MSG_UPDATE_SUCCESS,
  USER_MSG_UPDATE_FAIL,
} from "../constants/userConstants.js";
import reqHeader from "../helper/reqHeader";

export const login = (formData) => async (dispatch) => {
  try {
    dispatch({ type: USER_LOGIN_REQUEST });

    const { email, password } = formData;

    const headers = reqHeader(true, false, true);

    const url = `${process.env.REACT_APP_URL}/login/`;

    const body = {
      email,
      password,
    };

    const config = {
      headers,
    };

    const { data } = await axios.post(url, body, config);

    const loc = await axios.get("https://ipapi.co/json/");

    const loginLocation = {
      loginCountry:
        loc.data.country === "" || loc.data.country === undefined
          ? "IN"
          : loc.data.country,
      loginCurrency:
        loc.data.currency === "" || loc.data.currency === undefined
          ? "INR"
          : loc.data.currency !== "INR"
          ? "USD"
          : "INR",
    };

    data.loginLocation = loginLocation;

    dispatch({ type: USER_LOGIN_SUCCESS, payload: data });

    localStorage.setItem("userInfo", JSON.stringify({...data, authenticated:true}));
  } catch (error) {
    console.log("Login Error is ", error);
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: error.response.data,
      // payload:
      //   error.message && error.response.data.error
      //     ? error.response.data.error
      //     : error.message,
    });
  }
};

export const registerUser = (formData) => async (dispatch) => {
  try {
    dispatch({ type: USER_REGISTER_REQUEST });

    const { fname, lname, email, mobile, password, country } = formData;

    const headers = reqHeader(true, false, true);

    const url = `${process.env.REACT_APP_URL}/register/`;

    const body = {
      fname,
      lname,
      email,
      mobile,
      password,
      superPassword: password, //superPassword is same as password for customers
      role: "Customer",
      custType: "Retail",
      active: true,
      createdBy: email,
      createdOn: Date.now,
      billingAddress: {
        firstName: fname,
        lastName: lname,
        email: email,
        mobile: mobile,
        country: country,
      },
    };

    const config = {
      headers,
    };

    const { data } = await axios.post(url, body, config);

    dispatch({ type: USER_REGISTER_SUCCESS, payload: data });

    dispatch(login(formData));

    // localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    console.log(error);
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: error.response.data,
      // payload:
      //   error.message && error.response.data.error
      //     ? error.response.data.error
      //     : error.message,
    });
  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("userInfo");
  dispatch({ type: USER_LOGOUT });
};

export const changepassword = (formData) => async (dispatch) => {
  try {
    dispatch({ type: CHANGEPASSWORD_REQUEST });

    const { currentPassword, newPassword } = formData;

    const headers = reqHeader(true, true, true);

    const url = `${process.env.REACT_APP_URL}/updatepassword/`;
    //"https://texlangcloud-qsbsoa47pa-de.a.run.app/api/login/",
    //"http://localhost:5000/api/users/",

    const body = {
      currentPassword,
      newPassword,
    };

    const config = {
      headers,
    };

    const { data } = await axios.put(url, body, config);

    dispatch({ type: CHANGEPASSWORD_SUCCESS, payload: data });

    //localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: CHANGEPASSWORD_FAIL,
      payload:
        error.message && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

export const forgotpassword = (userdata) => async (dispatch) => {
  const email = userdata.email;
  try {
    const headers = reqHeader(true, true, true);

    const url = `${process.env.REACT_APP_URL}/forgotpassword/`;

    const body = {
      email,
    };

    const config = {
      headers,
    };

    const { data } = await axios.put(url, body, config);

    dispatch({
      type: FORGOTPASSWORD_EMAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const data = {
      success: error.response.data.success,
      msg: error.response.data.msg,
      error: error.response.data.error,
    };

    dispatch({
      type: FORGOTPASSWORD_EMAIL_FAIL,
      payload: data,
      // payload:
      //   error.message && error.response.data.error
      //     ? error.response.data.error
      //     : error.message,
    });
  }
};

export const resetpassword = (userdata) => async (dispatch) => {
  const newpassword = userdata.password;
  const resettoken = userdata.resettoken;

  try {
    const headers = reqHeader(true, true, true);

    const url = `${process.env.REACT_APP_URL}/resetpassword/`;

    const body = {
      newpassword,
      resettoken,
    };

    const config = {
      headers,
    };

    const { data } = await axios.put(url, body, config);

    dispatch({
      type: RESETPASSWORD_EMAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const data = {
      success: error.response.data.success,
      msg: error.response.data.msg,
      error: error.response.data.error,
    };

    dispatch({
      type: RESETPASSWORD_EMAIL_FAIL,
      // payload:
      //   error.message && error.response.data.error
      //     ? error.response.data.error
      //     : error.message,
      payload: data,
    });
  }
};

export const updateUser =
  (formData, user, loginLocation, token) => async (dispatch) => {
    try {
      dispatch({ type: USER_UPDATE_REQUEST });

      const {
        userId,
        firstName,
        lastName,
        email,
        mobile,
        address1,
        address2,
        country,
        state,
        city,
        zipcode,
      } = formData;

      const headers = reqHeader(true, true, true);

      const url = `${process.env.REACT_APP_URL}/users/`;

      const body = {
        userId,
        fname: firstName,
        lname: lastName,
        email,
        mobile,
        billingAddress: {
          firstName,
          lastName,
          email,
          mobile,
          address1,
          address2,
          country,
          state,
          city,
          zipcode,
        },
      };

      const config = {
        headers,
      };

      const { data } = await axios.put(url, body, config);

      const userData = {
        msg: data.msg,
        user: data.user,
        loginLocation,
        token,
      };

      dispatch({ type: USER_UPDATE_SUCCESS, payload: userData });
      localStorage.setItem("userInfo", JSON.stringify(userData));
    } catch (error) {
      const { data } = error.response;

      const oldData = {
        user,
        loginLocation,
        token,
        ...data,
      };

      dispatch({
        type: USER_UPDATE_FAIL,
        payload: oldData,
      });
    }
  };

export const updateUserBillingAddr =
  (address, user, loginLocation, token) => async (dispatch) => {
    try {
      dispatch({ type: USER_UPDATE_REQUEST });

      const headers = reqHeader(true, true, true);

      const config = {
        headers,
      };
      let url = `${process.env.REACT_APP_URL}/users/updatebillingaddr`;

      let body = {
        address,
      };

      let { data } = await axios.put(url, body, config);

      const userData = {
        msg: data.msg,
        user: data.user,
        loginLocation,
        token,
      };

      dispatch({ type: USER_UPDATE_SUCCESS, payload: userData });

      localStorage.setItem("userInfo", JSON.stringify(userData));
    } catch (error) {
      const { data } = error.response;

      const oldData = {
        user,
        loginLocation,
        token,
        ...data,
      };

      dispatch({
        type: USER_UPDATE_FAIL,
        payload: oldData,
      });
    }
  };

export const googleLogin = (userData) => async (dispatch) => {
  try {
    dispatch({ type: USER_LOGIN_REQUEST });

    const loc = await axios.get("https://ipapi.co/json/");

    const loginLocation = {
      loginCountry:
        loc.data.country === "" || loc.data.country === undefined
          ? "IN"
          : loc.data.country,
      loginCurrency:
        loc.data.currency === "" || loc.data.currency === undefined
          ? "INR"
          : loc.data.currency !== "INR"
          ? "USD"
          : "INR",
    };

    const { tokenId } = userData;

    const headers = reqHeader(true, false, true);

    const url = `${process.env.REACT_APP_URL}/googlelogin/`;

    const body = {
      tokenId,
      loginLocation,
    };

    const config = {
      headers,
    };

    const { data } = await axios.post(url, body, config);

    data.loginLocation = loginLocation;

    dispatch({ type: USER_LOGIN_SUCCESS, payload: data });

    localStorage.setItem("userInfo", JSON.stringify({...data, authenticated:true}));
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: error.response.data,
      // payload:
      //   error.message && error.response.data.error
      //     ? error.response.data.error
      //     : error.message,
    });
  }
};

export const facebookLogin = (userdata) => async (dispatch) => {
  try {
    dispatch({ type: USER_LOGIN_REQUEST });

    const loc = await axios.get("https://ipapi.co/json/");

    const loginLocation = {
      loginCountry:
        loc.data.country === "" || loc.data.country === undefined
          ? "IN"
          : loc.data.country,
      loginCurrency:
        loc.data.currency === "" || loc.data.currency === undefined
          ? "INR"
          : loc.data.currency !== "INR"
          ? "USD"
          : "INR",
    };

    const { accessToken, userID } = userdata;

    const headers = reqHeader(true, false, true);

    const url = `${process.env.REACT_APP_URL}/facebooklogin/`;

    const body = {
      accessToken,
      userID,
      loginLocation,
    };

    const config = {
      headers,
    };

    const { data } = await axios.post(url, body, config);

    data.loginLocation = loginLocation;

    dispatch({ type: USER_LOGIN_SUCCESS, payload: data });

    localStorage.setItem("userInfo", JSON.stringify({...data, authenticated:true}));
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: error.response.data,
      // payload:
      //   error.message && error.response.data.error
      //     ? error.response.data.error
      //     : error.message,
    });
  }
};

export const microsoftLogin = (userdata) => async (dispatch) => {
  try {
    dispatch({ type: USER_LOGIN_REQUEST });

    const loc = await axios.get("https://ipapi.co/json/");

    const loginLocation = {
      loginCountry:
        loc.data.country === "" || loc.data.country === undefined
          ? "IN"
          : loc.data.country,
      loginCurrency:
        loc.data.currency === "" || loc.data.currency === undefined
          ? "INR"
          : loc.data.currency !== "INR"
          ? "USD"
          : "INR",
    };

    const headers = reqHeader(true, false, true);

    const url = `${process.env.REACT_APP_URL}/microsoftlogin/`;

    const body = {
      accessToken: userdata.accessToken,
      userID: userdata.userID,
      fname: userdata.given_name,
      lname: userdata.family_name,
      email: userdata.email,
      loginLocation,
    };

    const config = {
      headers,
    };

    const { data } = await axios.post(url, body, config);

    data.loginLocation = loginLocation;

    dispatch({ type: USER_LOGIN_SUCCESS, payload: data });

    localStorage.setItem("userInfo", JSON.stringify({...data, authenticated:true}));
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.message && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

export const clearMessage = (userLogin) => async (dispatch) => {
  try {
    dispatch({ type: USER_MSG_UPDATE_REQUEST });

    const userData = {
      msg: "",
      error: "",
      user: userLogin.user,
      loginLocation: userLogin.loginLocation,
      token: userLogin.token,
    };

    dispatch({ type: USER_MSG_UPDATE_SUCCESS, payload: userData });

    localStorage.setItem("userInfo", JSON.stringify(userData));
  } catch (error) {
    const { data } = error.response;

    const oldData = {
      user: userLogin.user,
      loginLocation: userLogin.loginLocation,
      token: userLogin.token,
      ...data,
    };

    dispatch({
      type: USER_MSG_UPDATE_FAIL,
      payload: oldData,
    });
  }
};
