import React from 'react'

export const handleSideNav = (e) => {
    const leftSideBar = document.getElementsByClassName("leftSideBar")[0]
    if (e == "closed") {
        leftSideBar.style.transform = "translateX(0%)"
    } else if (e == "open") {
        leftSideBar.style.transform = "translateX(-100%)"
    } else {
        leftSideBar.style.transform = "translateX(0%)"
    }
}

const NavBtn = () => {
    return (
        <div className='menuBtnIcon'
            onClick={() => handleSideNav("closed")}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6" width="20px">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>

        </div>
    )
}

export default NavBtn
