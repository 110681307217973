import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import LeftNavigation from "./left-navigation";
import reqHeader from "../helper/reqHeader.js";
import axios from "axios";
import TableList from "./Table/TableList";
import Select from "react-select";
import { format, set } from "date-fns";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PopupQuatation from "./Table/PopupQuotation";
import { CustomDialog } from "react-st-modal";
import Header from "./Header.jsx";

const schema = yup.object().shape({
  projname: yup
    .string()
    .matches(
      /^[aA-zZ0-9-_\s]+$/,
      "Only alphabets, numbers, underscore and hyphen are allowed!"
    )
    .required("Project Name is required!"),
});

toast.configure();
const priorities = [
  { value: "Normal" },
  { value: "High" },
  { value: "Urgent" },
]
const TranslationOrder = () => {
  const location = useLocation();
  const history = useHistory();
  const headers = reqHeader(true, true, true);

  const userLogin = useSelector((state) => state.userLogin);
  const { id } = userLogin.user;
  const { loginCurrency } = userLogin.loginLocation;

  const folderPath = location.state !== undefined ? location.state.fp : "";

  const methods = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const { register, handleSubmit, errors, setValue } = methods;

  const [srcLanguage, setSrcLanguage] = useState([]);
  const [tgtLanguage, setTgtLanguage] = useState([]);
  const [updatedTgtLanguage, setUpdatedTgtLanguage] = useState([]);


  const [totalwordcount, setTotalWordCount] = useState(0);
  const [ETA, setETA] = useState("48");
  const [totalrate, setTotalRate] = useState(0);
  const [totalamount, setTotalAmount] = useState(0);
  const [calculate, setCalculate] = useState(true);
  const [currencyval, setCurrrencyVal] = useState(
    loginCurrency === "" || loginCurrency === undefined ? "INR" : loginCurrency
  );
  const [rateMatrix, setRateMatrix] = useState([]);
  const [srcLang, setSrcLang] = useState(null);
  const [tgtLang, setTgtLang] = useState(null);
  const [priority, setPriority] = useState(priorities[0]);
  const [toasterror, setToastError] = useState(
    "Project Name already exists. Please choose a different name."
  );

  const type = location.state !== undefined ? location.state.type : "";

  const sourceLanguage =
    location.state !== undefined ? location.state.srcLang : "";

  useEffect(() => {
    if (sourceLanguage !== "") {
      setValue("srcLang", sourceLanguage.value);
      setSrcLang(sourceLanguage);
    }
  }, [sourceLanguage, setValue, setSrcLang]);

  const OnChangeSourceLanguage = (item) => {
    setValue("srcLang", item.value);
    setSrcLang(item);
    setTgtLang("");
    setValue("tgtLang", "");
  };

  const OnChangeTargetLanguage = (item) => {
    const target = item.map((vals) => vals.value).toString();
    setValue("tgtLang", target);
    setTgtLang(item);
  };

  const OnChangePriority = (item) => {
    setValue("priority", item.value);
    setPriority(item);
  };

  useEffect(() => {
    async function getlanguages() {
      const url = `${process.env.REACT_APP_URL}/language/activeTranslationLanguages`;
      const config = { headers };
      await axios
        .get(url, config)
        .then((res) => {
          setSrcLanguage(
            res.data.data.map(({ name }) => ({ label: name, value: name }))
          );

          setTgtLanguage(
            res.data.data.map(({ name }) => ({ label: name, value: name }))
          );
        })
        .catch((err) => { });
    }
    getlanguages();

    setTotalWordCount(location.state.wordCount);
    setCalculate(false);

    setValue("priority", priorities[0].value);
    setPriority(priorities[0]);
    setValue("currency", loginCurrency);
  }, []);

  useEffect(() => {
    async function getDeadline() {
      const url = `${process.env.REACT_APP_URL}/deadline/getspecificdeadline/`;

      const params = {
        type: "Translation",
        srcLang: srcLang.value,
        priority: priority.value,
        quality: "3",
      };

      const config = { headers, params };

      if (srcLang && priority) {
        await axios
          .get(url, config)
          .then((res) => {
            setETA(res.data.data.ETAHour);
          })
          .catch((err) => {

          });
      }
    }
    if (srcLang && priority) {
      getDeadline();
    }
  }, [srcLang, priority]);

  useEffect(() => {
    console.log("Entered calling activeTranslationLanguages");

    async function getlanguages() {
      const url = `${process.env.REACT_APP_URL}/language/activeTranslationLanguages`;

      if (srcLang) {
        let params = srcLang;
        const config = { headers, params };

        await axios
          .get(url, config)
          .then((res) => {
            setTgtLanguage(
              res.data.data.map(({ name }) => ({ label: name, value: name }))
            );
          })
          .catch((err) => { });
      }
    }
    getlanguages();
  }, []);

  useEffect(() => {
    async function getRates() {
      let totals = 0;
      let rates = 0;

      let para = [];

      for (let i = 0; i < tgtLang.length; i++) {
        const element = {
          type: "Translation",
          srcLang: srcLang.value,
          tgtLang: tgtLang[i].value,
          wordCount: totalwordcount,
          priority: priority.value,
          quality: "3",
          folderPath: folderPath,
        };

        para = [...para, element];
      }

      let rateList = [];

      for (let i = 0; i < para.length; i++) {
        const url = `${process.env.REACT_APP_URL}/rates/OC/translation`;
        const headers = reqHeader(true, true, true);
        let params = para[i];
        const config = { headers, params };

        await axios
          .get(url, config)
          // eslint-disable-next-line no-loop-func
          .then((res) => {
            let data = res.data.data;

            // console.log("Data is ", data);

            data.forEach((value, index) => {
              let rate =
                (currencyval === "INR" ? value.INRRate : value.USDRate) *
                value.priorityFactor *
                value.tgtCategory *
                value.discFactor +
                (currencyval === "INR"
                  ? type === "text"
                    ? value.INRRate * value.textFactor
                    : value.INRRate * value.imageFactor
                  : type === "text"
                    ? value.USDRate * value.textFactor
                    : value.USDRate * value.imageFactor);

              // console.log("rate is ", rate);

              const amount = rate * value.wordCount;

              const rated = {
                id: index + 1 + "_" + (i + 1),
                srcLang: params.srcLang,
                tgtLang: params.tgtLang,
                rate: rate,
                currency: currencyval,
              };

              rateList = [...rateList, rated];

              rates = rates + rate;
              totals = totals + amount;
            });
          })
          .catch((err) => { });
      }

      setRateMatrix(rateList);
      setTotalRate(rates.toFixed(2));
      setTotalAmount(totals.toFixed(2));
    }

    if (srcLang && tgtLang && priority) {
      getRates();
    }
  }, [srcLang, tgtLang, priority, currencyval, totalwordcount, type]);

  useEffect(() => {
    if (location.state.srcLang !== undefined) {
      setValue("srcLang", location.state.srcLang);
      setSrcLang(location.state.srcLang);
    }
    if (location.state.priority !== undefined) {
      //console.log("priority", location.state.priority)
      setValue("priority", location.state.priority);
      setPriority(location.state.priority);
    }
    if (location.state.tgtLang !== undefined && location.state.tgtLang) {
      const target = location.state.tgtLang
        .map((vals) => vals.value)
        .toString();
      setValue("tgtLang", target);
      setTgtLang(location.state.tgtLang);
    }
    if (location.state.currencyval !== undefined) {
      setCurrrencyVal(location.state.currencyval);
    }
  }, [location.state.currencyval, location.state.priority, location.state.srcLang, location.state.tgtLang, setValue]);


  useEffect(() => {
    // remove srcLang from tgtLangauge array
    if (srcLang) {
      let tgt = tgtLanguage.filter((item) => item.value !== srcLang.value);
      setUpdatedTgtLanguage(tgt);
    }
  }, [srcLang, tgtLanguage])

  const submitHandler = async (data, e) => {
    e.preventDefault();
    e.stopPropagation();
    e.persist();


    if (
      (currencyval === "INR" ? totalamount >= 50 : totalamount >= 1) &&
      data.projname !== "" &&
      data.projname !== undefined &&
      data.projname != null &&
      srcLang !== "" &&
      srcLang !== undefined &&
      srcLang != null &&
      tgtLang !== "" &&
      tgtLang !== undefined &&
      tgtLang != null &&
      priority !== "" &&
      priority !== undefined &&
      priority != null
    ) {
      const url = `${process.env.REACT_APP_URL}/translation/updatetempprojfiles/`;
      const headers = reqHeader(true, true, true);
      const body = {
        folderPath,
        customerID: id,
        projectName: data.projname,
        srcLang: srcLang.value,
        tgtLang: tgtLang.map((vals) => vals.value).toString(),
        priority: priority.value,
        ETA: ETA,
        currency: currencyval,
        rates: rateMatrix,
        totalRate: totalrate,
        totalAmount: totalamount,
        instructions: data.instructions,
      };

      const config = { headers };

      axios
        .put(url, body, config)
        .then((res) => {
          //console.log("response is", res.data.msg);
          if (res.data.success === false && res.data.msg) {
            toast.error(toasterror);
          } else {
            history.push({
              pathname: "./payment",
              search: `?currency=${currencyval}&type=TL&projectName=${data.projname}&fp=${folderPath}`,

            });
            setToastError("");
          }
        })

    } else {
      if (
        data.projname === "" ||
        data.projname === undefined ||
        data.projname == null
      ) {
        toast.error("Project Name is required !!!");
      } else if (
        srcLang === "" ||
        srcLang === undefined ||
        srcLang == null
      ) {
        toast.error("Source Language is required !!!");
      } else if (
        tgtLang === "" ||
        tgtLang === undefined ||
        tgtLang == null
      ) {
        toast.error("Target Language is required !!!");
      } else if (
        priority === "" ||
        priority === undefined ||
        priority == null
      ) {
        toast.error("Priority is required !!!");
      } else if (
        currencyval === "INR" ? totalamount < 50 : totalamount < 1
      ) {
        const amt = currencyval === "INR" ? 50 : 1;
        toast.error(
          `Minimum Payable Amount should be ${currencyval} ${amt} !!!`
        );
      } else if (totalamount === 0) {
        const amt = currencyval === "INR" ? 100 : 1;
        toast.error(
          `Payable Amount should not be ${currencyval} ${amt} !!!`
        );
      }
    }
    // }
    // })
    // .catch((err) => {
    // });
  };


  let minutesToAdd = ETA * 60;
  let currentDate = new Date();

  let futureDate = new Date(currentDate.getTime() + minutesToAdd * 60000);
  let isostr = futureDate.toISOString().split("T")[0];
  futureDate = format(new Date(isostr), "dd/MM/yyyy");

  return (
    <>
      <div className="container dashboard d-flex">
        <LeftNavigation />

        <div className="pageRight">
          <Header title="Translation Order" type="Translation" />

          <form className="form d-flex flex-col" onSubmit={handleSubmit(submitHandler)}>
            <div className="contentLeft">
              <div className="block">
                <h2>Summary</h2>
                <div className="addProjectDetails">
                  <div className="d-flex createOption">
                    <div className="inputWrap">
                      <label className="label">
                        Project Name <span className="requiredField">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Project Name"
                        name="projname"
                        ref={register}
                        maxLength="30"
                      />
                      {errors.projname && (
                        <span className="inputErrorMsg">
                          {errors.projname?.message}
                        </span>
                      )}
                    </div>

                    <div className="inputWrap">
                      <label className="label">Priority</label>

                      <Controller
                        control={methods.control}
                        name="priority"
                        defaultValue={{}}
                        render={({ onChange, value, name, ref }) => (
                          <Select
                            inputRef={ref}
                            name={name}
                            value={priority}
                            onChange={OnChangePriority}
                            placeholder={"Select Priority"}
                            options={priorities}
                            getOptionValue={(option) => option.value}
                            getOptionLabel={(option) => option.value}
                          />
                        )}
                      />
                    </div>

                    <div className="inputWrap">
                      <label className="label">Source Language</label>
                      {type === "image" ? (
                        <Controller
                          control={methods.control}
                          name="srcLang"
                          defaultValue={{}}
                          render={({ onChange, value, name, ref }) => (
                            <Select
                              inputRef={ref}
                              name={name}
                              value={srcLang}
                              onChange={OnChangeSourceLanguage}
                              options={srcLanguage}
                              placeholder={"Select Source Language"}
                              getOptionValue={(option) => option.value}
                              getOptionLabel={(option) => option.value}
                              isOptionDisabled={(option) => option.value}
                            />
                          )}
                        />
                      ) : (
                        <Controller
                          control={methods.control}
                          name="srcLang"
                          defaultValue={{}}
                          render={({ onChange, value, name, ref }) => (
                            <Select
                              inputRef={ref}
                              name={name}
                              value={srcLang}
                              onChange={OnChangeSourceLanguage}
                              options={srcLanguage}
                              placeholder={"Select Source Language"}
                              getOptionValue={(option) => option.value}
                              getOptionLabel={(option) => option.value}
                            />
                          )}
                        />
                      )}
                    </div>
                  </div>

                  <div className="d-flex createOption">
                    <div className="inputWrap targetLangSelect">
                      <label className="label">Target Languages</label>
                      <Controller
                        control={methods.control}
                        name="tgtLang"
                        defaultValue={{}}
                        render={({ onChange, value, name, ref }) => (
                          <Select
                            inputRef={ref}
                            name={name}
                            value={tgtLang}
                            onChange={OnChangeTargetLanguage}
                            options={updatedTgtLanguage}
                            isMulti={true}
                            isSearchable={true}
                            placeholder={"Select Target Languages"}
                            getOptionValue={(option) => option.value}
                            getOptionLabel={(option) => option.value}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="d-flex createOption">
                    <div className="inputWrap instructionarea">
                      <label className="label">Instructions</label>
                      <textarea
                        placeholder="Instructions"
                        name="instructions"
                        ref={register}
                        maxLength="1000"></textarea>
                      {errors.comments && (
                        <span className="inputErrorMsg">
                          {errors.comments?.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div>

                <TableList
                  src="translationTempFiles"
                  pagination={true}
                  srcLang={srcLang}
                  tgtLang={tgtLang}
                  priority={priority}
                  currencyval={currencyval}
                  type={type}
                />
              </div>
            </div>

            <div className="contentRight">
              <div className="block orderPlace">
                <h2>Place Order</h2>
                <ul>
                  <div className="orderRow">
                    <span className="label">Total Word Count</span>
                    <span className="value">
                      {calculate ? "calculating" : totalwordcount}
                    </span>
                  </div>
                </ul>
                <div className="currencyRow">
                  <div className="label">Currency</div>
                  <select
                    name="currency"
                    ref={register}
                    onChange={(e) => setCurrrencyVal(e.target.value)}>
                    <option defaultValue value="INR">
                      INR
                    </option>
                    <option value="USD">USD</option>
                  </select>
                </div>

                {totalamount > 0 && <div className="payableRow">
                  <span className="label">Payable Amount</span>
                  <span className="PayableValue">
                    {currencyval} {totalamount}
                  </span>
                </div>}

                <button
                  className="quoteBtn"
                  type="button"
                  onClick={async (e) => {
                    if (totalamount === 0) {
                      toast.error("Please select the language pair to get the quote");
                      return;
                    }
                    e.preventDefault();
                    await CustomDialog(
                      <PopupQuatation
                        src="translationTempFiles"
                        ratelist={rateMatrix}
                        totalrate={totalrate}
                        totalamount={totalamount}
                        currency={currencyval}
                      />,
                      {
                        title: "Quote Detail",
                        showCloseIcon: true,
                      }
                    );
                  }}>
                  Quote Detail
                </button>

                <div className="rightAlignRow border-btm">
                  <span className="value">
                    <strong>{ETA} Hours Turnaround</strong>
                  </span>
                </div>

                <div className="rightAlignRow">
                  <span className="label ETALabel">ETA : </span>
                  <span className="value">
                    <strong>{futureDate}</strong>
                  </span>
                </div>
                <button className="btn">Confirm</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default TranslationOrder;
