import React, { useEffect, useState } from "react";
import { useDialog } from "react-st-modal";
import { Cross } from "../icons.component";
import axios from "axios";
import reqHeader from "../../helper/reqHeader.js";
//import TableList from "./TableList";
import "../../assets/style/_table.sass";

const PopupQuatation = (props) => {
  // console.log("Popup props is ", props)
  //console.log("props", props)
  const {
    src,
    ratelist,
    totalrate,
    totalamount,
    currency
  } = props;

  // use this hook to control the dialog
  const dialog = useDialog();

  const [datalist, setDataList] = useState([]);
  const [dataCount, setDataCount] = useState(0);

  useEffect(() => {
    async function getData() {
      let url = "";
      const headers = reqHeader(true, true, true);
      const config = { headers };

      if (src == "translationTempFiles") {
        url = `${process.env.REACT_APP_URL}/translation/gettempfiles/`;

        await axios
          .get(url, config)
          .then((res) => {
            setDataList(res.data.data.files);
            setDataCount(res.data.data.files.length);
          })
          .catch((err) => {
            setDataList([]);
            setDataCount(0);
          });
      } else if (src == "subtitleTempFiles") {
        url = `${process.env.REACT_APP_URL}/subtitling/gettempfiles/`;

        await axios
          .get(url, config)
          .then((res) => {
            // console.log("gettempfiles", res.data);
            setDataList(res.data.data.files);
            setDataCount(res.data.data.files.length);
          })
          .catch((err) => {
            setDataList([]);
            setDataCount(0);
          });
      } else {
      }
    }
    getData();
  }, []);

  // console.log("src", src);
  const renderData = datalist.map((data) => {
    let amount = 0;
    if (src == "subtitleTempFiles") {
      const times = data.fileLength.split(":");
      const hhRate = Number(times[0]) * 60;
      const mmRate = Number(times[1]);
      const ssRate = (Number(times[2]) / 60);
      amount = (hhRate + mmRate + ssRate);
    }

    return (
      <tr>
        <td>{data.fileName}</td>
        <td>
          {src == "translationTempFiles" ? data.fileWordCount : data.fileLength}
        </td>
        <td>
          {ratelist.map((src) => (
            <tr key={src.id}>
              <td>{src.srcLang}</td>
            </tr>
          ))}
        </td>
        <td>
          {ratelist.map((tgt) => (
            <tr key={tgt.id}>
              <td>{tgt.tgtLang}</td>
            </tr>
          ))}
        </td>
        <td>
          {ratelist.map((rte) => (
            <tr key={rte.id}>
              <td>
                {rte.currency} {rte.rate}
              </td>
            </tr>
          ))}
        </td>
        <td>
          {ratelist.map((rte) => (
            <tr key={rte.id}>
              <td>
                {rte.currency}{" "}
                {src == "translationTempFiles"
                  ? (rte.rate * data.fileWordCount).toFixed(2)
                  : (rte.rate * amount).toFixed(2)}
              </td>
            </tr>
          ))}
        </td>
        <td>
          {currency}{" "}
          {src == "translationTempFiles"
            ? (data.fileWordCount * totalrate).toFixed(2)
            : (amount * totalrate).toFixed(2)}
        </td>
      </tr>
    );
  });

  return (
    <table className="quotationTable">
      <thead>
        <tr>
          <th>File name</th>
          <th>Duration/Word Count</th>
          <th>Source Language</th>
          <th>Target Language</th>
          <th>Rate</th>
          <th>Sub Total</th>
          <th>Total Amount</th>
        </tr>
      </thead>
      <tbody>
        {renderData}
        <tr className="totleRow">
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <span className="quateLabel">Total</span>
          </td>
          <td className="quateValue">
            {currency} {totalamount}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default PopupQuatation;
