import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import successDesign from "./../assets/images/successDesign.svg";
import successIcon from "./../assets/images/SuccessTick.svg";
import { resetPayment } from "../actions/paymentAction.js";


const PopupSuccess = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userPaymentInfo = useSelector((state) => state.userPayment);

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetPayment());
      history.push({
        pathname: "./dashboard",
        search: "?query=true",
        userPaymentInfo: userPaymentInfo,
      });
    }, 3000); //miliseconds
  }, [dispatch, history, userPaymentInfo]);

  const { amount, ETA, orders, invoices, currency } =
    userPaymentInfo.data;

  let orderDoc = "";

  orders.forEach((value) => {
    orderDoc = orderDoc + value.orderNo + ",";
  });

  let invDoc = "";
  let invID = "";

  invoices.forEach((value) => {
    invDoc = invDoc + value.invDocNo + ",";
    invID = invID + value.invID + ",";
  });

  return (
    <>
      <div className="fullPagePopup success">
        <div className="overlay"></div>
        <div className="popupInner">
          <div className="popupContent">
            <div className="popupMessageWrap">
              <div className="messageIconWrap">
                <img src={successDesign} alt="" />
                <img src={successIcon} alt="" />
              </div>
              <h2 className="popupMessage success">Payment Success</h2>
            </div>

            <div className="messageblock">
              {" "}
              {currency} {amount}
            </div>

            <div className="orderDetails d-flex">
              <div className="orderNo">
                <span>Order No:</span>
                <strong>{orderDoc.substring(0, orderDoc.length - 1)}</strong>
              </div>
              <div className="invoiceNo">
                <span>Invoice No:</span>
                <strong>{invDoc.substring(0, invDoc.length - 1)}</strong>
              </div>
              <div className="deliveryDate">
                <span>Delivery Estimate:</span>
                <strong>{ETA}</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopupSuccess;
