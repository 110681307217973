export default function authHeader(type, auth, encoded) {
  const user = JSON.parse(localStorage.getItem("userInfo"));

  //console.log("User is", user);

  if (type && auth) {
    if (user && user.token) {
      return {
        "Content-Type": encoded ? "application/json" : "multipart/form-data",
        Authorization: "Bearer " + user.token,
      };
    }
  } else if (type) {
    return {
      "Content-Type": encoded ? "application/json" : "multipart/form-data",
    };
  } else {
    return {};
  }
}
