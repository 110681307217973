import {
  USER_PAYMENT_REQUEST,
  USER_PAYMENT_SUCCESS,
  USER_PAYMENT_FAIL,
  USER_PAYMENT_RESET,
} from "../constants/paymentConstants.js";

export const userPaymentReducer = (state = {}, action) => {
  const { type, payload } = action;

  // console.log("User Reducer Type is ", type);

  switch (type) {
    case USER_PAYMENT_REQUEST:
      return { loading: true };
    case USER_PAYMENT_SUCCESS:
      return { ...payload, loading: false, success: true };
    case USER_PAYMENT_FAIL:
      return { ...payload, loading: false, success: false };
    case USER_PAYMENT_RESET:
      return {};
    default:
      return state;
  }
};
