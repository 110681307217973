import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../actions/userActions.js";
import LeftNavigation from "./left-navigation";
import Header from "./Header.jsx";
import reqHeader from "../helper/reqHeader.js";
import axios from "axios";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const schema = yup.object().shape({
  firstName: yup
    .string()
    .matches(/^[A-Za-z\s]+$/, "Only alphabets are allowed!")
    .required("First Name is required!"),
  lastName: yup
    .string()
    .matches(/^[A-Za-z\s]+$/, "Only alphabets are allowed!")
    .required("Last Name is required!"),
  email: yup
    .string()
    .required("Email ID is required!")
    .email("Enter a valid Email ID!"),
  address1: yup
    .string()
    .required("Address is required!")
    .matches(
      /^[aA-zZ0-9-_,#/\s]+$/,
      "Only alphabets, numbers, underscore, hyphen, comma, hash and slash are allowed!"
    ),
  country: yup
    .string()
    .required("Country is required!"),
  state: yup
    .string()
    .max(30, "State should not more than 30 character.")
    .required("State is required!")
    .matches(/^[A-Za-z\s]+$/, "Only alphabets are allowed!"),
  city: yup
    .string()
    .max(30, "City should not more than 30 character.")
    .required("City is required!")
    .matches(/^[A-Za-z\s]+$/, "Only alphabets are allowed!"),
  zipcode: yup
    .string()
    .max(10, "Postal Code should not more than 10 character.")
    .required("Postal Code is required!")
    .matches(/[A-za-z0-9\s]+$/, "Only Alphnumeric values allowed!"),
});

const ChangeProfile = () => {
  const [custMobile, setCustMobile] = useState("");
  const [countries, setCountries] = useState([]);

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);

  // console.log("User Login State is ", userLogin);

  const { user, loginLocation, token, msg, error, errorList } = userLogin;

  let firstName = "",
    lastName = "",
    email = "",
    mobile = "",
    address1 = "",
    address2 = "",
    country = "IN",
    state = "",
    city = "",
    zipcode = "";

  if (user.billingAddress !== undefined) {
    firstName = user.billingAddress.firstName;
    lastName = user.billingAddress.lastName;
    email = user.billingAddress.email;
    mobile = user.billingAddress.mobile;
    address1 = user.billingAddress.address1;
    address2 = user.billingAddress.address2;
    country = user.billingAddress.country;
    state = user.billingAddress.state;
    city = user.billingAddress.city;
    zipcode = user.billingAddress.zipcode;
  }

  // console.log("User Billing Address is ", user.billingAddress);

  const { register, handleSubmit, reset, errors } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const fetchData = async () => {
      const url = `${process.env.REACT_APP_URL}/country/activeCountries`;
      const headers = reqHeader(true, true, true);
      const config = { headers };
      await axios
        .get(url, config)
        .then((res) => {
          // console.log("response data is", res.data.data);
          setCountries(
            res.data.data.map(({ code, name, _id: id }) => ({
              id: id,
              label: name,
              value: code,
            }))
          );
          // setCountries(res.data.data);
        })
        .catch((err) => {
          // console.log("Error from useEffect");
        });

      reset({
        firstName,
        lastName,
        email:
          user.email === "" || user.email === "undefined" ? email : user.email,
        mobile:
          user.mobile === "" || user.mobile === "undefined"
            ? mobile
            : user.mobile,
        address1,
        address2,
        country,
        state,
        city,
        zipcode,
      });

      setCustMobile(
        user.mobile === "" || user.mobile === "undefined" ? mobile : user.mobile
      );
    };

    fetchData();
  }, [reset]);



  const submitHandler = (data) => {
    data.mobile = custMobile;
    data.userId = user.id;
    dispatch(updateUser(data, user, loginLocation, token));

    if (errorList && errorList.email && error) {
      toast.error(`Email ${error}`);
    }

    if (errorList && errorList.mobile && error) {
      toast.error(`Mobile number ${error}`);
    }

    if (msg && msg === "Update user") {
      toast.success(`User details updated Successfully!`);
    }
  };



  return (
    <>
      <ToastContainer />
      <div className="container updateUserDetails d-flex">
        <LeftNavigation />

        <div className="pageRight">
          <Header title="My Profile" type="MyProfile" />

          <div className="block ">
            <form onSubmit={handleSubmit(submitHandler)}>
              <header>
                <h2>Edit Details</h2>
              </header>

              <div className="blockContent d-flex">
                <div className="inputBlock">
                  <label htmlFor="" className="label">
                    First Name <span className="requiredField">*</span>
                  </label>
                  <div
                    className={`inputWrap ${errors.firstName ? "error" : ""}`}>
                    <input
                      type="text"
                      placeholder="First Name"
                      name="firstName"
                      ref={register}
                    />
                    {errors.firstName && (
                      <span className="inputErrorMsg">
                        {errors.firstName?.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="inputBlock">
                  <label htmlFor="" className="label">
                    Last Name <span className="requiredField">*</span>
                  </label>
                  <div
                    className={`inputWrap ${errors.lastName ? "error" : ""}`}>
                    <input
                      type="text"
                      placeholder="Last Name"
                      name="lastName"
                      ref={register}
                    />
                    {errors.lastName && (
                      <span className="inputErrorMsg">
                        {errors.lastName?.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="inputBlock">
                  <label htmlFor="" className="label">
                    Email <span className="requiredField">*</span>
                  </label>
                  <div className={`inputWrap ${errors.email ? "error" : ""}`}>
                    <input
                      type="text"
                      placeholder="Email"
                      name="email"
                      ref={register}
                      readOnly={true}
                    />
                    {errors.email && (
                      <span className="inputErrorMsg">
                        {errors.email?.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="inputBlock">
                  <label htmlFor="" className="label">
                    Mobile No. <span className="requiredField">*</span>
                  </label>

                  <div
                    className={`inputWrap ${formatPhoneNumberIntl(custMobile) &&
                      !isValidPhoneNumber(custMobile)
                      ? "error"
                      : ""
                      }`}>
                    <PhoneInput
                      international
                      defaultCountry="IN"
                      placeholder="Enter Mobile Number"
                      value={formatPhoneNumberIntl(custMobile)}
                      onChange={(phone) => setCustMobile(phone)}
                    />
                    {formatPhoneNumberIntl(custMobile) &&
                      !isValidPhoneNumber(custMobile) && (
                        <span className="inputErrorMsg">
                          Invalid Mobile Number !!!
                        </span>
                      )}
                  </div>
                </div>

                <div className="inputBlock fullWidth">
                  <label htmlFor="" className="label">
                    Address 1 <span className="requiredField">*</span>
                  </label>
                  <div
                    className={`inputWrap ${errors.address1 ? "error" : ""}`}>
                    <input
                      type="text"
                      placeholder="Address 1"
                      name="address1"
                      ref={register}
                    />
                    {errors.address1 && (
                      <span className="inputErrorMsg">
                        {errors.address1?.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="inputBlock fullWidth">
                  <label htmlFor="" className="label">
                    Address 2
                  </label>
                  <div
                    className={`inputWrap ${errors.address2 ? "error" : ""}`}>
                    <input
                      type="text"
                      placeholder="Address 2"
                      name="address2"
                      ref={register}
                    />
                    {errors.address2 && (
                      <span className="inputErrorMsg">
                        {errors.address2?.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="inputBlock">
                  <label className="label">
                    Country <span className="requiredField">*</span>
                  </label>
                  <div className="selectWrap">
                    <select name="country" ref={register}>
                      <option key="" value="">
                        Select Country
                      </option>
                      {countries.map((op) => (
                        <option key={op.id} value={op.value}>
                          {op.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  {errors.country && (
                    <span className="inputErrorMsg">
                      {errors.country?.message}
                    </span>
                  )}
                </div>

                <div className="inputBlock">
                  <label className="label">
                    State <span className="requiredField">*</span>
                  </label>

                  <div className="inputWrap">
                    <input
                      type="text"
                      placeholder="State"
                      name="state"
                      ref={register}
                    />

                    {errors.state && (
                      <span className="inputErrorMsg">
                        {errors.state?.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="inputBlock">
                  <label className="label">
                    City <span className="requiredField">*</span>
                  </label>

                  <div className="inputWrap">
                    <input
                      type="text"
                      placeholder="City"
                      name="city"
                      ref={register}
                    />

                    {errors.city && (
                      <span className="inputErrorMsg">
                        {errors.city?.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="inputBlock">
                  <label className="label">
                    Postal Code <span className="requiredField">*</span>
                  </label>

                  <div className="inputWrap">
                    <input
                      type="text"
                      placeholder="Postal Code"
                      name="zipcode"
                      ref={register}
                    />

                    {errors.zipcode && (
                      <span className="inputErrorMsg">
                        {errors.zipcode?.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <footer className="blockFooter">
                <button className="btn">update</button>
              </footer>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangeProfile;
