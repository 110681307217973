import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../actions/userActions.js";
import Logo from "../assets/images/Logo.svg";
import { PasswordHide, PasswordShow } from "./icons.component";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";

const schema = yup.object().shape({
  fname: yup
    .string()
    .matches(/^([^0-9]*)$/, "First Name should not contain numbers!")
    .required("First name is required!"),
  lname: yup
    .string()
    .matches(/^([^0-9]*)$/, "Last Name should not contain numbers!")
    .required("Last name is required!"),
  email: yup
    .string()
    .required("Email ID is required!")
    .email("Enter a valid Email ID!"),
  password: yup
    .string()
    .required("Password is required!")
    .min(6, "Your password must be a minimum of 6 characters long!"),
  cpassword: yup
    .string()
    .required("Confirm Password is required!")
    .min(6, "Confirm password must be a minimum of 6 characters long!")
    .oneOf([yup.ref("password"), null], "Passwords does not match"),
});



const CustomerRegister = () => {
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });
  const [custMobile, setCustMobile] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [cpasswordShown, setCPasswordShown] = useState(false);

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  let { authenticated, error, errorList, loginLocation, loading } = userLogin;

  useEffect(() => {
    if (errorList && errorList.email && error) {
      toast.error(`Email ${error}`);
    }

    if (errorList && errorList.mobile && error) {
      toast.error(`Mobile number ${error}`);
    }

  }, [error, errorList]);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const toggleCPasswordVisiblity = () => {
    setCPasswordShown(cpasswordShown ? false : true);
  };

  const submitHandler = (data) => {
    //console.log("data", data)
    data.country =
      loginLocation === "" || loginLocation === undefined || loginLocation == null
        ? "IN"
        : loginLocation.loginCountry;
    data.mobile = custMobile;
    dispatch(registerUser(data));
    //toast.error(error);
  };

  if (authenticated) {
    return <Redirect to="/dashboard" />;
  }


  return (
    <>
      <div className="container RegisterWrap d-flex">
        <ToastContainer />
        <div className="registerLHS">
          <Link to="/" className="logo">
            <img src={Logo} alt="Logo" className="logo" />
          </Link>
          <div className="description">
            Copyright &copy; Megdap Innovation Labs |{" "}
            <Link
              to={{ pathname: "https://www.megdap.com/contact/" }}
              target="_blank">
              Contact Us
            </Link>
          </div>
        </div>
        <div className="registerRHS">
          <div className="registrationForm registerForm">
            <p className="welcometxt">
              <span>Welcome,</span> Register for an account
            </p>
            <div className="registerFormWrap">
              <form className="form" onSubmit={handleSubmit(submitHandler)}>
                <div className="firstLastField d-flex">
                  <div className={`inputWrap ${errors.fname ? "error" : ""}`}>
                    <input
                      type="text"
                      placeholder="First Name"
                      name="fname"
                      ref={register}
                    />
                    {errors.fname && (
                      <span className="inputErrorMsg">
                        {errors.fname?.message}
                      </span>
                    )}
                  </div>
                  <div className={`inputWrap ${errors.lname ? "error" : ""}`}>
                    <input
                      type="text"
                      placeholder="Last Name"
                      name="lname"
                      ref={register}
                    />
                    {errors.lname && (
                      <span className="inputErrorMsg">
                        {errors.lname?.message}
                      </span>
                    )}
                  </div>
                </div>

                <div
                  className={`inputWrap emailField ${errors.email ? "error" : ""
                    }`}>
                  <input
                    type="text"
                    placeholder="Email ID"
                    name="email"
                    ref={register}
                  />
                  {errors.email && (
                    <span className="inputErrorMsg">
                      {errors.email?.message}
                    </span>
                  )}
                </div>

                <div
                  className={`inputWrap ${formatPhoneNumberIntl(custMobile) &&
                    !isValidPhoneNumber(custMobile)
                    ? "error"
                    : ""
                    }`}>
                  <PhoneInput
                    name="custMobile"
                    ref={register}
                    international
                    defaultCountry="IN"
                    placeholder="Enter Mobile Number"
                    value={formatPhoneNumberIntl(custMobile)}
                    onChange={(phone) => setCustMobile(phone)}
                  //error={custMobile ? (isValidPhoneNumber(custMobile) ? undefined : 'Invalid phone number') : 'Phone number required'}
                  />
                  {/* {formatPhoneNumberIntl(custMobile) ? (isValidPhoneNumber(custMobile) ? undefined : <span className="inputErrorMsg"> Invalid phone number </span>): <span className="inputErrorMsg"> Phone number required </span> } */}
                  {formatPhoneNumberIntl(custMobile) && !isValidPhoneNumber(custMobile) ? <span className="inputErrorMsg"> Invalid phone number </span> : ""}
                  {/* {errors.custMobile && (
                    <span className="inputErrorMsg">
                      {errors.custMobile?.message}
                    </span>
                  )} */}
                </div>

                <div
                  className={`inputWrap passwordField ${errors.password ? "error" : ""
                    }`}>
                  <input
                    type={passwordShown ? "text" : "password"}
                    placeholder="Password"
                    name="password"
                    ref={register}
                  />
                  <div className="passwordEye">
                    <i onClick={togglePasswordVisiblity}>
                      {passwordShown ? <PasswordShow /> : <PasswordHide />}
                    </i>{" "}
                  </div>
                  {errors.password && (
                    <span className="inputErrorMsg">
                      {errors.password?.message}
                    </span>
                  )}
                </div>

                <div
                  className={`inputWrap passwordField ${errors.cpassword ? "error" : ""
                    }`}>
                  <input
                    type={cpasswordShown ? "text" : "password"}
                    placeholder="Confirm Password"
                    name="cpassword"
                    ref={register}
                  />
                  <div className="passwordEye">
                    <i onClick={toggleCPasswordVisiblity}>
                      {cpasswordShown ? <PasswordShow /> : <PasswordHide />}
                    </i>{" "}
                  </div>
                  {errors.cpassword && (
                    <span className="inputErrorMsg">
                      {errors.cpassword?.message}
                    </span>
                  )}
                </div>

                <div className="formFooter">
                  <button disabled={loading}>
                    {loading ? "Registering..." : "Register"}
                  </button>
                  <div className="loginLink">
                    Already a Member? <Link to="/">Log In</Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerRegister;
