import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ReactGA from "react-ga";
import "./assets/style/App.sass";
import PrivateRoutes from "./helper/PrivateRoutes.js";
import CustomerLogin from "./components/login";
import CustomerRegister from "./components/Register";
import ChangePassword from "./components/ChangePassword.jsx";
import Forgotpassword from "./components/forgotpassword.jsx";
import Resetpassword from "./components/resetpassword.jsx";
import ChangeProfile from "./components/ChangeProfile.jsx";
import Dashboard from "./components/Dashboard.jsx";
import Recording from "./components/Recording.jsx";
import Transcription from "./components/Transcription.jsx";
import TranscriptionProject from "./components/TranscriptionProject.jsx";
import TranscriptionOrder from "./components/TranscriptionOrder.jsx";
import Payment from "./components/Payment.jsx";
import Translation from "./components/Translation.jsx";
import Subtitling from "./components/Subtitling.jsx";
import PopupFullPage from "./components/popup.jsx";
import SubtitlingProject from "./components/SubtitlingProject.jsx";
import SubtitlingOrder from "./components/SubtitlingOrder.jsx";
import TranslationProject from "./components/TranslationProject.jsx";
import TranslationOrder from "./components/TranslationOrder.jsx";
import Contactus from "./components/Contactus.jsx"
import Faqs from "./components/Faqs.jsx";

import { logout } from "./actions/userActions";
import jwt_decode from "jwt-decode";

function App() {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);


  useEffect(() => {
    ReactGA.initialize("UA-202049759-1");

    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    if (userLogin.token) {
      const decoded = jwt_decode(userLogin.token);

      //check for expire token
      const currentTime = Date.now() / 1000; //to get milliseconds
      if (decoded.exp < currentTime) {
        //Logout user
        dispatch(logout());
      }
    }
  }, []);


  return (
    <Router>
      <div className="App">
        <Switch>
          {/* <Route exact path="/" component={RouteChangeTracker} /> */}
          <Route exact path="/" component={CustomerLogin} />
          <Route exact path="/register" component={CustomerRegister} />
          <PrivateRoutes exact path="/dashboard" component={Dashboard} />
          <PrivateRoutes
            exact
            path="/changepassword"
            component={ChangePassword}
          />
          <Route exact path="/forgotpassword" component={Forgotpassword} />
          <Route
            exact
            path="/resetpassword/:resettoken"
            component={Resetpassword}
          />
          <PrivateRoutes
            exact
            path="/changeprofile"
            component={ChangeProfile}
          />
          <PrivateRoutes
            exact
            path="/addtranscription"
            component={TranscriptionProject}
          />
          <PrivateRoutes
            exact
            path="/transcriptionorder"
            component={TranscriptionOrder}
          />
          <PrivateRoutes exact path="/payment" component={Payment} />
          <PrivateRoutes exact path="/recordings" component={Recording} />
          <PrivateRoutes
            exact
            path="/transcriptions"
            component={Transcription}
          />
          <PrivateRoutes
            exact
            path="/contactus"
            component={Contactus}
          />
          <PrivateRoutes
            exact
            path="/faqs"
            component={Faqs}
          />
          <PrivateRoutes exact path="/translations" component={Translation} />
          <PrivateRoutes exact path="/subtitlings" component={Subtitling} />
          {/* <PrivateRoutes
            exact
            path="/addsubtitling"
            component={SubtitlingProject}
          />
          <PrivateRoutes
            exact
            path="/subtitlingorder"
            component={SubtitlingOrder}
          /> */}
          <PrivateRoutes exact path="/popup" component={PopupFullPage} />
          <PrivateRoutes
            exact
            path="/addtranslation"
            component={TranslationProject}
          />
          <PrivateRoutes
            exact
            path="/translationorder"
            component={TranslationOrder}
          />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
