import React from "react";
import { useDialog } from "react-st-modal";
import { Cross } from "../icons.component";
import TableList from "./TableList";

const PopupFileDetails = (props) => {
  // console.log("Popup props is ", props);
  const { original } = props;

  // use this hook to control the dialog
  const dialog = useDialog();

  let source =
    original.type == "Translation"
      ? "translationFileList"
      : original.type == "Subtitling"
      ? "subtitlingFileList"
      : "transcriptionFileList";

  return (
    <>
      <TableList
        title="child"
        src={source}
        pagination={true}
        hideTargetDownload={false}
        hideInvoiceDownload={true}
        hideReceiptDownload={true}
        parent={original}
      />
    </>
  );
};

export default PopupFileDetails;
